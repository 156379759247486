//Include Both Helper File with needed methods

import { getFirebaseBackend } from "../../helpers/firebase_helper";
import {
  apiLoading,
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
  postLoginInterfaceSelection,
} from "./reducer";
import { postFakeLogin, postJwtLogin } from "../../helpers/fakebackend_helper";
import { setAuthorization } from "../../helpers/api_helper";
// import { setAuthorization as setAuthorization2 } from "../../helpers/api_helper_secondary";
// import { toast } from "react-toastify";

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
  try {
    let response: any;
    dispatch(apiLoading());

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      let fireBaseBackend = getFirebaseBackend();
      response = fireBaseBackend.loginUser(user.email, user.password);
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = postJwtLogin({
        email: user.email,
        password: user.password,
      });
    } else if (process.env.REACT_APP_DEFAULTAUTH) {
      response = postFakeLogin({
        email: user.email,
        password: user.password,
      });
    }

    var data = await response;
    console.log("loginUser data", data);

    if (data) {
      if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
        var finallogin: any = JSON.stringify(data);
        sessionStorage.setItem("authUser", finallogin);
        finallogin = JSON.parse(finallogin);
        data = finallogin.data;
        if (
          finallogin.status === "success" ||
          (finallogin.username && finallogin.password)
        ) {
          dispatch(loginSuccess(data));
          console.log(data)
          history("/");
          // history("/dashboard");
          // window.location.reload();
          // window.location.replace("/dashboard")
        } else {
          history("/login");
          dispatch(apiError(finallogin));
        }
      } else {
        if (
          (!data.statusCode || data.statusCode === 200 || data.statusCode === 201 ) &&
          data.message === "Authenticated successfully"
        ) {
          // const { tokens, data }
          setAuthorization(data?.tokens?.accessToken)
          // setAuthorization2(data?.tokens?.accessToken)
          let finallogin: any = JSON.stringify(data);
          sessionStorage.setItem("authUser", finallogin);
          finallogin = JSON.parse(finallogin);
          data = finallogin.data;
          console.log("finallogin data", data);
          console.log("finallogin  2", data);
          dispatch(loginSuccess(finallogin));
          // history("/dashboard");
          history("/");
          // window.location.reload();
          // window.location.replace("/dashboard")
        } else {
          // console.log('data?.message', data?.message)
          // toast.error(data?.message || data, { autoClose: 2000 })
          dispatch(apiError(data));
        }
      }
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};


export const logoutUser = () => async (dispatch: any) => {
  try {
    localStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      let fireBaseBackend = getFirebaseBackend();
      const response = fireBaseBackend.logout;
      dispatch(logoutUserSuccess(response));
    } else {
      dispatch(logoutUserSuccess(true));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const selectOperationalModule = (shortId: any) => async (dispatch: any) => {
  dispatch(postLoginInterfaceSelection(shortId));
};

export const socialLogin =
  (type: any, history: any) => async (dispatch: any) => {
    try {
      let response;

      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const fireBaseBackend = getFirebaseBackend();
        response = fireBaseBackend.socialLoginUser(type);
      }
      //  else {
      //   response = postSocialLogin(data);
      // }

      const socialdata = await response;
      if (socialdata) {
        localStorage.setItem("authUser", JSON.stringify(socialdata));
        dispatch(loginSuccess(socialdata));
        history("/dashboard");
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
