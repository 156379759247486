import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCertApplication as addCertApplicationApi,
  getCertApplications as getCertApplicationsApi,
  approveCertApplication as approveCertApplicationApi,
  rejectCertApplication as rejectCertApplicationApi,
  getCertificates as getCertificatesApi
} from "../../helpers/fakebackend_helper";
import { toast } from "react-toastify";

// Get Certificate Applications
export const getCertApplications = createAsyncThunk(
  "certApplications/getAll",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getCertApplicationsApi(params);
      return response;
    } catch (error: any) {
      toast.error("Failed to fetch applications", { autoClose: 2000 });
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

// Add Certificate Application
export const addCertApplication = createAsyncThunk(
  "certApplications/add",
  async (application: any, { rejectWithValue }) => {
    try {
      const response = await addCertApplicationApi(application);
      toast.success("Application added successfully", { autoClose: 2000 });
      return response;
    } catch (error: any) {
      toast.error("Failed to add application", { autoClose: 2000 });
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

// Approve Certificate Application
export const approveCertApplication = createAsyncThunk(
  "certApplications/approve",
  async (applicationId: string, { rejectWithValue }) => {
    try {
      const response = await approveCertApplicationApi(applicationId);
      toast.success("Application approved successfully", { autoClose: 2000 });
      return response;
    } catch (error: any) {
      toast.error("Failed to approve application", { autoClose: 2000 });
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

// Reject Certificate Application
export const rejectCertApplication = createAsyncThunk(
  "certApplications/reject",
  async (
    { applicationId, reason }: { applicationId: string; reason: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await rejectCertApplicationApi(applicationId);
      toast.success("Application rejected successfully", { autoClose: 2000 });
      return response;
    } catch (error: any) {
      toast.error("Failed to reject application", { autoClose: 2000 });
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const getCertificates = createAsyncThunk(
  "certificates/getAll",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getCertificatesApi(params);
      return response;
    } catch (error: any) {
      toast.error("Failed to fetch applications", { autoClose: 2000 });
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);