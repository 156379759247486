import React, { Fragment, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { GET_ATTACHMENT } from "../helpers/url_helper";
import axios from "axios";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AttachmentView from "./AttachmentView";

interface appChatProps {
  attachments?: string[];
}

type ISetLoadingState = (value: React.SetStateAction<boolean>) => void;

const AttachmentLists: React.FC<appChatProps> = ({ attachments }) => {
  const [isLoadingDisabledAttachments, setIsLoadingDisabledAttachments] =
    useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewType, setPreviewType] = useState("");

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { tokens } = useSelector(selectAccountAndLogin);

  const handleViewAttachment = async (
    attachmentId: string,
    setLoadingState: ISetLoadingState
  ) => {
    try {
      setLoadingState(true);
      const response = await axios.get(
        ` ${process.env.REACT_APP_API_SECONDARY_URL}${GET_ATTACHMENT}/download/${attachmentId}`,
        {
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
          responseType: "blob",
        }
      );

      setShowPreview(true);
      setPreviewUrl(window.URL.createObjectURL(response as any));
      setPreviewType((response as any).type);
      setLoadingState(false);
    } catch (error: any) {
      console.error("Error fetching attachment:", error);
      toast.error("Failed to view attachment", { autoClose: 2000 });
    }
  };

  const closePreview = () => {
    if (previewUrl) {
      window.URL.revokeObjectURL(previewUrl);
      console.log("Object URL revoked:", previewUrl);
      setPreviewUrl("");
      setPreviewType("");
    }
    setShowPreview(false);
  };

  const handleDownloadAttachment = async (
    attachmentId: string,
    setLoadingState: ISetLoadingState
  ) => {
    try {
      setIsLoadingDisabledAttachments(true);
      setLoadingState(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_SECONDARY_URL}/attachments/download/${attachmentId}`,
        {
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
          responseType: "blob",
        }
      );

      console.log("response", response);

      const filename = `attachment-${attachmentId}-${new Date().valueOf()}`;

      // Create download link
      const url = window.URL.createObjectURL(response as any);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setLoadingState(false);
      setIsLoadingDisabledAttachments(false);
    } catch (error) {
      toast.error("Failed to download attachment", { autoClose: 2000 });
    }
  };

  return (
    <Fragment>
      {attachments && attachments.length > 0 && (
        <div className="mt-2">
          <p className="mb-1 text-muted fs-sm">Attachments:</p>
          <div className="d-flex flex-wrap gap-2">
            {attachments.map((attachmentId: string, index: number) => (
              <AttachmentView
                key={attachmentId}
                attachmentId={attachmentId}
                index={index}
                isLoadingOtherAttachments={isLoadingDisabledAttachments}
                handleDownloadAttachment={(setLoadingState) =>
                  handleDownloadAttachment(attachmentId, setLoadingState)
                }
                handleViewAttachment={(setLoadingState) =>
                  handleViewAttachment(attachmentId, setLoadingState)
                }
              />
            ))}
          </div>
        </div>
      )}
      <Modal
        // fullscreen
        size="xl"
        centered
        show={showPreview}
        onHide={closePreview}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <Modal.Header className="p-4 pb-0">
          <Button
            type="button"
            className="btn-close"
            onClick={closePreview}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          {previewUrl && (
            <div style={{ marginTop: "20px" }}>
              {previewType.startsWith("image/") ? (
                <img
                  src={previewUrl}
                  alt="Attachment Preview"
                  className="img-fluid border rounded"
                  style={{ maxHeight: "600px", objectFit: "contain" }}
                />
              ) : (
                <iframe
                  src={previewUrl}
                  title="Attachment Preview"
                  width="100%"
                  height="600px"
                  style={{ border: "1px solid #ccc", borderRadius: "4px" }}
                ></iframe>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default AttachmentLists;
