import React, { useEffect, useState } from "react";

//import images
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const SelectedModuleTitle = () => {
  const [state, setState] = useState("");
  const [shortId, setShortId] = useState("");

  const selectAccount = createSelector(
    (state: any) => state.Login,
    (account: any) => {
      // console.log("account", account);
      return {
        user: account.user,
        selected_module: account.selected_module,
      };
    }
  );
  const { selected_module } = useSelector(selectAccount);

  useEffect(() => {
    switch (selected_module) {
      case "awms":
        setShortId("AWMS");
        setState("AUTOMATED WASTE MANAGEMENTS"); // Display Dashboard component
        break;
      case "ermcs":
        setShortId("ERMCS");
        setState("ELECTRONIC REGISTRATION AND CERTIFICATION MANAGEMENTS");
        break;
      case "cds":
        setShortId("CDS");
        setState("CARGO DECLARATION");
        break;
      default:
        setShortId("SELECT");
        setState("SELECT MODULE");
    }
  }, [selected_module]);

  return selected_module !== null ? (
    <React.Fragment>
      <div className="d-none justify-content-center align-items-center d-md-flex">
        {" "}
        <small
          className={`badge bg-${
            selected_module ? "info" : "warning"
          }-subtle text-${selected_module ? "success" : "info"} p-2`}
        >
          {state}
          <span className={`text-${selected_module ? "info" : "info"}`}>
            {` (${selected_module ? "•" : "?"}) `}
          </span>
        </small>
      </div>

      <div className="d-flex justify-content-center align-items-center d-md-none">
        {" "}
        <small
          className={`badge bg-${
            selected_module ? "info" : "warning"
          }-subtle text-${selected_module ? "success" : "info"} p-2`}
        >
          {shortId}
          <span className={`text-${selected_module ? "success" : "info"}`}>
            {` (${selected_module ? "•" : "?"}) `}
          </span>
        </small>
      </div>
    </React.Fragment>
  ) : null;
};

export default SelectedModuleTitle;
