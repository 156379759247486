import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Badge, Container, Form } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { approveCertApplication as onApproveCertApplication, rejectCertApplication as onRejectCertApplication } from '../../slices/certificates/thunk';
import LicenseChat from '../../Common/LicenseChat';

// Define the type for application details
interface ApplicationDetails {
  id: string;
  applicationTitle: string;
  description: string;
  status: string;
  target_name: string;
  companyName: string;
  type: string;
  applicantId: string
}

const PendingLcsApplicationDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [applicationDetails, setApplicationDetails] = useState<ApplicationDetails | null>(null);
  const dispatch = useDispatch();

  const [isRevoking, setIsRevoking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [revocationReason, setRevocationReason] = useState('');

  const handleApprove = (id: any) => {
    dispatch(onApproveCertApplication(id))
    navigate("/license-management/granted-licenses");
  };

  const handleRevoke = (id: any) => {
    dispatch(onRejectCertApplication(id))
  };

  useEffect(() => {
    // First, try to get details from navigation state
    const stateDetails = location.state?.applicationDetails;

    if (stateDetails) {
      setApplicationDetails(stateDetails);
    } else {
      console.log('No application details found for ID:', id);
    }
  }, [location.state, id]);

  // Helper function to render status badge
  const renderStatusBadge = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return <Badge bg="success">{status}</Badge>;
      case 'pending':
        return <Badge bg="warning">{status}</Badge>;
      case 'disabled':
        return <Badge bg="danger">{status}</Badge>;
      default:
        return <Badge bg="secondary">{status}</Badge>;
    }
  };

  if (!applicationDetails) {
    return (
      <div className="container-fluid">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>Loading application details...</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  const appId = applicationDetails.id

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header>
                  <h4 className="card-title mb-0">License Application Details</h4>
                </Card.Header>
                <Card.Body>
                  <Row className="align-items-start mb-3">
                    <Col md={3} className="text-muted">
                      <h5 className="mb-0">Application Id:</h5>
                    </Col>
                    <Col md={9}>
                      <p className="mb-0">{appId}</p>
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col md={3} className="text-muted">
                      <h5 className="mb-0">Application Title:</h5>
                    </Col>
                    <Col md={9}>
                      <p className="mb-0">{applicationDetails.applicationTitle}</p>
                    </Col>
                  </Row>

                  <Row className="align-items-center mb-3">
                    <Col md={3} className="text-muted">
                      <h5 className="mb-0">Status:</h5>
                    </Col>
                    <Col md={9}>
                      {renderStatusBadge(applicationDetails.status)}
                    </Col>
                  </Row>

                  <Row className="align-items-center mb-3">
                    <Col md={3} className="text-muted">
                      <h5 className="mb-0">Facility Name:</h5>
                    </Col>
                    <Col md={9}>
                      <p className="mb-0">{applicationDetails.target_name}</p>
                    </Col>
                  </Row>

                  <Row className="align-items-start mb-3">
                    <Col md={3} className="text-muted">
                      <h5 className="mb-0">Description:</h5>
                    </Col>
                    <Col md={9}>
                      <p className="mb-0">{applicationDetails.description}</p>
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-3">
                    <Col md={3} className="text-muted">
                      <h5 className="mb-0">Company Name:</h5>
                    </Col>
                    <Col md={9}>
                      <p className="mb-0">{applicationDetails.companyName}</p>
                    </Col>
                  </Row>
                  <Row className="align-items-start mb-3">
                    <Col md={3} className="text-muted">
                      <h5 className="mb-0">Application type:</h5>
                    </Col>
                    <Col md={9}>
                      <p className="mb-0">{applicationDetails.type}</p>
                    </Col>
                  </Row>
                  <div className="action-section mt-4">
                    <h5>Application Actions</h5>
                    <div className="d-flex flex-column">
                      <div className="form-check mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="approveCheckbox"
                          checked={isApproving}
                          onChange={(e) => {
                            setIsApproving(e.target.checked);
                            if (e.target.checked) {
                              setIsRevoking(false);
                            }
                          }}
                        />
                        <label className="form-check-label" htmlFor="approveCheckbox">
                          Approve Application
                        </label>
                      </div>

                      {isApproving && (
                        <div className="alert alert-warning mb-3">
                          Are you sure you want to approve this application?
                          <div className="mt-2">
                            <button
                              type="button"
                              className="btn btn-success me-2"
                              onClick={() => handleApprove(applicationDetails.id)}
                            >
                              Confirm Approval
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => setIsApproving(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="form-check mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="revokeCheckbox"
                          checked={isRevoking}
                          onChange={(e) => {
                            setIsRevoking(e.target.checked);
                            if (e.target.checked) {
                              setIsApproving(false);
                            }
                          }}
                        />
                        <label className="form-check-label" htmlFor="revokeCheckbox">
                          Reject Application
                        </label>
                      </div>

                      {isRevoking && (
                        <Form.Group className="mb-3">
                          <Form.Label>Reason for Rejection</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter reason for revoking the application"
                            value={revocationReason}
                            onChange={(e) => setRevocationReason(e.target.value)}
                          />
                          {revocationReason.trim() && (
                            <div className="alert alert-warning mt-2">
                              Are you sure you want to reject this application?
                              <div className="mt-2">
                                <button
                                  type="button"
                                  className="btn btn-danger me-2"
                                  onClick={() => handleRevoke(applicationDetails.id)}
                                >
                                  Confirm Rejection
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => setIsRevoking(false)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          )}
                        </Form.Group>
                      )}
                    </div>
                  </div>

                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-3">
            <LicenseChat appId={appId} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PendingLcsApplicationDetails;