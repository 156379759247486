import React, { useState, useEffect } from "react";
import { Card, Col, Image, Spinner } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import avatar2 from "../assets/images/users/avatar-2.jpg";
import { GET_CERT_APPLICATION_NOTIFICATIONS } from "../helpers/url_helper";
import axios from "axios";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AttachmentLists from "./AttachmentLists";
import ApplicationChatBox from "./ApplicationChatBox";

interface appChatProps {
  appId?: string;
}

const ApplicationChat: React.FC<appChatProps> = ({ appId }) => {
  let navigate = useNavigate();
  const [state, setState] = useState<any>();
  const [loading, setLoading] = useState(false);

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { tokens } = useSelector(selectAccountAndLogin);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SECONDARY_URL}${GET_CERT_APPLICATION_NOTIFICATIONS}`,
        {
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
          params: {
            pageNo: 0,
            pageSize: 30,
            topicId: appId,
            search: "",
            status: "DELIVERED",
          },
        }
      );
      setState(JSON.parse(JSON.stringify(response)));
    } catch (error: any) {
      console.log("typeof error", typeof error);
      if (typeof error === "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });
        navigate(process.env.PUBLIC_URL + "/logout");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, tokens]);

  return (
    <Col>
      <Card>
        <Card.Header>
          <h4 className="card-title mb-0">Application Correspondence</h4>
        </Card.Header>

        <Card.Body>
          <div className="mx-n3">
            {loading ? (
              <div className="text-center my-4">
                {/* <Spinner animation="border" variant="primary" /> */}
                <p>No Messages</p>
              </div>
            ) : (
              <SimpleBar
                autoHide={false}
                data-simplebar-track="primary"
                style={{ maxHeight: "275px" }}
              >
                {state?.data?.chats?.length > 0 ? (
                  state.data.chats.map((ms: any) => (
                    <Link
                      to="#!"
                      className="text-reset notification-item d-block dropdown-item active"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs me-3">
                          {ms?.user?.photo ? (
                            <Image
                              src={ms?.user?.photo?.url || avatar2}
                              className="rounded-circle img-fluid"
                              alt="user-pic"
                            />
                          ) : (
                            <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-lg">
                              <i className="ri-message-2-fill"></i>
                            </span>
                          )}
                        </div>
                        <div className="flex-grow-1 text-muted">
                          <h6 className="mb-1 fs-md">
                            {ms.createdBy}{" "}
                            <span className="text-secondary">
                              ({ms?.companyType})
                            </span>
                          </h6>
                          <p className="mb-0 text-capitalize">{ms.content}</p>
                          <AttachmentLists attachments={ms.attachments} />
                        </div>

                        <div className="flex-shrink-0 ms-2">
                          <div className="fs-xs text-muted">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            {moment(ms?.createDateTime).fromNow()}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div>No chats available</div>
                )}
              </SimpleBar>
            )}
          </div>
          <ApplicationChatBox appId={appId} getData={getData} />
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ApplicationChat;
