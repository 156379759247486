import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { addCertApplication as onAddCertApplication } from "../../slices/certificates/thunk";
import FacilitySelectDropdown from "../../pages/CertManagement/components/FacilitySelctor";
import { useState } from "react";

// Define maritime certification titles
const MARITIME_CERTIFICATION_TITLES = [
  "Advanced Navigation Certification",
  "Maritime Safety Management",
  "Marine Engineering Proficiency",
  "Nautical Operations Specialist",
  "Offshore Safety and Survival",
  "Maritime Environmental Compliance",
  "Ship Security Officer Certification",
  "Advanced Maritime Electrical Systems",
  "Marine Cargo Handling Certification",
  "Vessel Management and Operations"
];

interface UserProps {
  isShow: boolean;
  handleClose: () => void;
}

const CertificateApplicationModal = ({ isShow, handleClose }: UserProps) => {
  const dispatch = useDispatch();
  
  const [id, setId] = useState("");
  const [targetType, setTargetType] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      type: "CERTIFICATION",
      target_id: id, 
      target_type: targetType
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .oneOf(MARITIME_CERTIFICATION_TITLES, "Please select a valid certification title")

    }),
    onSubmit: async (values: any) => {
      setIsSubmitting(true);
      const newApplication = {
        title: values.title,
        description: values.description,
        type: "CERTIFICATION",
        target_id: id,
        target_type: targetType
      };

      try {
        await dispatch(onAddCertApplication(newApplication));
        formik.resetForm();
        handleClose();
        window.location.reload();
      } catch (error) {
        console.error("Failed to add certification application:", error);
      } finally {
        setIsSubmitting(false); // End loading state
      }
    },
  });

  return (
    <Modal centered show={isShow} onHide={handleClose} tabIndex={-1}>
      <div className="modal-content border-0">
        <Modal.Header className="p-4 pb-0">
          <Modal.Title as="h5">Certificate Application</Modal.Title>
          <button type="button" className="btn-close" onClick={handleClose}></button>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="title">Certification Title</Form.Label>
                  <Form.Select
                    id="title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.title && formik.touched.title}
                  >
                    <option value="">Select Certification Title</option>
                    {MARITIME_CERTIFICATION_TITLES.map((title) => (
                      <option key={title} value={title}>
                        {title}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.title}
                  </Form.Control.Feedback>
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="description">Application Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    id="description"
                    name="description"
                    placeholder="Enter Application Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.description && formik.touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <div style={{ marginBottom: 20 }}>
                <FacilitySelectDropdown
                  onFacilitySelect={(facility) => console.log('Selected Facility:', facility)}
                  formik={formik}
                  setId={setId}
                  setTargetType={setTargetType}
                  targetType={targetType}
                />
              </div>
            </Row>

            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" className="btn btn-success" disabled={isSubmitting}>
                {isSubmitting ? "Applying..." : "Apply now"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default CertificateApplicationModal;