import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

const API_WMP_URL = "https://api.mwp.ng";

type FacilityType = 'platform' | 'ship' | 'wmp';

interface Facility {
  id: string | number;
  name: string;
  type: FacilityType;
  [key: string]: any;
}

const FACILITY_ENDPOINTS: Record<FacilityType, string> = {
  platform: `${API_WMP_URL}/api/v1/platform`,
  ship: `${API_WMP_URL}/api/v1/ship`,
  wmp: `${API_WMP_URL}/api/v1/wmp`,
};

const TARGET_TYPE_MAPPING: Record<FacilityType, string> = {
  platform: 'OIL_PLATFORM',
  ship: 'SHIP',
  wmp: 'WASTE_FACILITY',
};

interface FacilitySelectDropdownProps {
  onFacilitySelect: (facility: Facility) => void;
  defaultValue?: Facility | null;
  formik: any;
  setId: any;
  setTargetType: any;
  targetType: any;
}

const FacilitySelectDropdown: React.FC<FacilitySelectDropdownProps> = ({
  onFacilitySelect,
  formik,
  setId,
  setTargetType,
  targetType
}) => {
  const [selectedFacilityType, setSelectedFacilityType] = useState<FacilityType | ''>('');
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // Change error state to string for custom error messages


  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );

  const { tokens } = useSelector(selectAccountAndLogin);
  console.log(targetType)

  

  useEffect(() => {
    if (selectedFacilityType) {
      const fetchFacilities = async () => {
        setLoading(true);
        try {
          const response: any = await axios.get<any[]>(FACILITY_ENDPOINTS[selectedFacilityType], {
            headers: { Authorization: `Bearer ${tokens?.accessToken}` },
          });
          setFacilities(
            response.map((facility: any) => ({
              ...facility,
              type: selectedFacilityType,
            }))
          );
          setLoading(false);
          setError(null); // Reset error if fetch is successful
        } catch (err) {
          setLoading(false);
          setError('Facility type not found for this user');
          setFacilities([]); // Clear facilities list on error
        }
      };

      fetchFacilities();
    } else {
      setFacilities([]); // Reset facilities if no facility type is selected
    }

    // Set target type based on the selected facility type
    setTargetType(selectedFacilityType ? TARGET_TYPE_MAPPING[selectedFacilityType] : null);
    formik.setFieldValue('targetType', targetType); // Update formik field for target type
  }, [selectedFacilityType, tokens]);

  const handleFacilityTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value as FacilityType;
    setSelectedFacilityType(selectedType);
    formik.setFieldValue('facilityType', selectedType);
    formik.setFieldValue('facilityName', ''); // Reset facility name when facility type changes
    setError(null); // Reset error when the facility type changes
  };

  if (loading) {
    return <div>Loading facilities...</div>;
  }

  return (
    <div className="mb-3">
      {/* Facility Type Select */}
      <Form.Label htmlFor="facilityType">Facility Type</Form.Label>
      <Form.Select
        id="facilityType"
        name="facilityType"
        value={selectedFacilityType}
        onChange={handleFacilityTypeChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.errors.facilityType && formik.touched.facilityType}
      >
        <option value="">Select Facility Type</option>
        <option value="platform">Platform</option>
        <option value="ship">Ship</option>
        <option value="wmp">WMP</option>
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {formik.errors.facilityType}
      </Form.Control.Feedback>

      {/* Display target type for the selected facility type */}
      {targetType && (
        <div className="mt-2">
          <strong>Target Type:</strong> {targetType}
        </div>
      )}

      {/* Error message for invalid facility type */}
      {error && <div className="text-danger mt-2">{error}</div>}

      {/* Facility Name Select (based on selected facility type) */}
      {selectedFacilityType && (
        <div style={{ marginTop: 10 }}>
          <Form.Label htmlFor="facilityName">Facility Name</Form.Label>
          <Form.Select
            id="facilityName"
            name="facilityName"
            value={formik.values.facilityName}
            onChange={(e) => {
              formik.handleChange(e);
              const selectedId = e.target.value;
              const selectedFacility = facilities.find(
                (facility) => facility.id.toString() === selectedId
              );
              if (selectedFacility) {
                onFacilitySelect(selectedFacility);
                setId(selectedFacility.id); // Set the selected facility's ID
              }
            }}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.errors.facilityName && formik.touched.facilityName}
          >
            <option value="">Select Facility</option>
            {facilities.map((facility) => (
              <option key={facility.id} value={facility.id}>
                {facility.name} ({facility.type})
              </option>
            ))}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            {formik.errors.facilityName}
          </Form.Control.Feedback>
        </div>
      )}
    </div>
  );
};

export default FacilitySelectDropdown;
