import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { handleSearchData } from "../../../Common/Tabledata/SorttingData";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import ViewCertDetails from "../../../Common/CrudModal/ViewCertDertail";
import { getCertificates as onGetCertificates } from "../../../slices/certificates/thunk";
import { useDispatch, useSelector } from "react-redux";

interface userProps {
  isShow: any;
  hideUserModal: any;
  isNimasa?: boolean;
}

const ViewCertificateTable = ({
  isShow,
  hideUserModal,
  isNimasa,
}: userProps) => {
  const dispatch = useDispatch();
  // const state = useSelector((state: any) => state); // Logs the entire state

  // console.log("Redux State:", state.Certification);
  const [certificateList, setCertificateList] = useState([]);

  const selectCertificateList = createSelector(
    (state: any) => state.Certification,
    (certifications: any) => certifications.cetificateList
  );

  const certificateListFromRedux = useSelector(selectCertificateList);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: 10,
      status: "PENDING",
    };
    dispatch(onGetCertificates(params));
  }, [dispatch]);

  console.log(certificateListFromRedux);

  useEffect(() => {
    if (
      certificateListFromRedux &&
      certificateListFromRedux.data &&
      certificateListFromRedux.data.certifications
    ) {
      const mappedCertificates =
        certificateListFromRedux?.data.certifications?.map((cert: any) => ({
          certification_number: cert.certification_number,
          companyName: cert.companyName,
          issuingAuthority: cert.issuing_authority,
          expiresAt: cert.expires_at,
          target_name: cert.target_name,
          issued_at: cert.issued_at,
          title:cert.title,
          createdBy:cert.createdBy,
          status:cert.status
        })) || [];
      setCertificateList(mappedCertificates);
    }
  }, [certificateListFromRedux]);

  const [viewCertDetails, setViewCertDetails] = useState<boolean>(false);
  const [selectedCertificate, setSelectedCertificate] = useState<any>(null);

  const handleViewCertDetails = (certificate: any) => {
    setSelectedCertificate(certificate);
    setViewCertDetails(true);
  };

  const handleCloseViewCertDetails = () => {
    setViewCertDetails(false);
    setSelectedCertificate(null);
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(() => {
    const baseColumns: columnsType[] = [
      {
        Header: "Certificate No",
        accessor: "certification_number",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Facility Name",
        accessor: "target_name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Expiry Date",
        accessor: "expiresAt",
        Filter: false,
        isSortable: true,
      },
    ];

    if (isNimasa) {
      baseColumns.splice(1, 0, {
        Header: "Company Name",
        accessor: "companyName",
        Filter: false,
        isSortable: true,
      });
    }

    if (!isNimasa) {
      baseColumns.splice(1, 0, {
        Header: "Certification Date",
        accessor: "issued_at",
        Filter: false,
        isSortable: true,
      });
    }

    // Add action column
    baseColumns.push({
      Header: "ACTION",
      accessor: "action",
      Filter: false,

      isSortable: false,
      Cell: (cell: any) => (
        <ul className="list-inline hstack gap-2 mb-0">
          <li
            className="list-inline-item"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-placement="top"
            aria-label="View"
            data-bs-original-title="View"
          >
            <Link
              to="#"
              className="btn btn-soft-info btn-sm d-inline-block"
              onClick={() => handleViewCertDetails(cell.row.original)}
            >
              <i className="las la-eye fs-17 align-middle"></i>
            </Link>
          </li>
        </ul>
      ),
    });

    return baseColumns;
  }, [isNimasa]);

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                id="searchMemberList"
                placeholder="Search for Result"
                // onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                variant="info"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>All</Dropdown.Item>
                <Dropdown.Item>Last Week</Dropdown.Item>
                <Dropdown.Item>Last Month</Dropdown.Item>
                <Dropdown.Item>Last Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {certificateList && certificateList.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={certificateList || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  PaginationClass="align-items-center mt-4 gy-3"
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ViewCertDetails
        isShow={viewCertDetails}
        handleClose={handleCloseViewCertDetails}
        certDetails={selectedCertificate}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default ViewCertificateTable;
