import React, { useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Container } from "react-bootstrap";
import ShipTable from "./ShipTable";

const ShipFacility = () => {
  document.title = "Ships | Maritime Waste Management Admin & Dashboard Platform";

  const [isShow, setIsShow] = useState(false);

  const hideShipModal = () => {
    setIsShow(!isShow);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Ships" title="Facilities" />
          <ShipTable isShow={isShow} hideShipModal={hideShipModal} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShipFacility;
