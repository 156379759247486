import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import InvoiceWidgets from "../../assets/images/invoice-widget.png";
import ThisWeekOverview from "./ThisWeekOverview";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { CompanyTypeEnum } from "../../helpers/enums/company.enum";

const ProffesionalWidgets = () => {
  const selectClientWasteNotificationList = createSelector(
    (state: any) => state.Login,
    (login: any) => ({
      loggedInUser: login.user,
      company: login.user.company,
    })
  );

  const { company } = useSelector(selectClientWasteNotificationList);

  return (
    <React.Fragment>
      <Row>
        <Col xl={5}>
          <Card>
            <Card.Body>
              <Row className="align-items-center gy-4">
                <Col sm={8}>
                  <div className="box">
                    {/* <h5 className="fs-20 text-truncate">
                      Welcome Akolade!
                    </h5> */}
                    <p className="text-muted fs-15">
                      <strong>
                        Welcome to Electronic Registration, Management and
                        Certification System Dashboard
                      </strong>{" "}
                      – facilitates the electronic registration and management
                      of maritime operators, vessels, and related entities.
                    </p>

                    {/* {company?.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
                    company?.companyType === CompanyTypeEnum.SHIPMENT ? (
                      <Link
                        to="/waste-notification-add"
                        className="btn btn-primary"
                      >
                        Complete waste notification
                      </Link>
                    ) : null} */}

                    {/* <Link to="#" className="btn btn-primary">
                      Request p
                    </Link> */}
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="text-center px-2">
                    <img
                      src={InvoiceWidgets}
                      className="img-fluid"
                      style={{ height: "141px" }}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <ThisWeekOverview />
      </Row>
    </React.Fragment>
  );
};

export default ProffesionalWidgets;
