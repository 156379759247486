import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
// import Structure from "./Structure";
import { CompanyTypeEnum } from "../../helpers/enums/company.enum";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import StackedColumnCert from "../Components/Apexcharts/StackedColumnCert";
import StackedColumnLicense from "../Components/Apexcharts/StackedColumnLicense";
import StackedColumnPermit from "../Components/Apexcharts/StackedColumnPermit";
import ActivitiesChart from "./ActivitiesChart";

const PaymentActivity = () => {
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { company } = useSelector(selectAccountAndLogin);
  console.log(company);

  const [paper, setPaper] = useState("certification");

  return (
    <React.Fragment>
      <Row>
        {/* <Col xl={8}> */}
        <Col xl={12}>
          <Card>
            <Card.Header className="border-0 align-items-center d-flex">
              {company?.companyType ===
                CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
              company?.companyType ===
                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ? (
                <h4 className="card-title mb-0 flex-grow-1">All Activities</h4>
              ) : (
                <h4 className="card-title mb-0 flex-grow-1">
                  Total Activities
                </h4>
              )}
              <div className="d-flex gap-1">
                <button type="button" className="btn btn-soft-info btn-sm">
                  ALL
                </button>
                <button type="button" className="btn btn-soft-info btn-sm">
                  1M
                </button>
                <button type="button" className="btn btn-soft-info btn-sm">
                  6M
                </button>
                <button type="button" className="btn btn-info btn-sm">
                  1Y
                </button>
              </div>
            </Card.Header>
            <Card.Body className="py-1">
              <Row className="gy-2">
                <Col md={4}>
                  {company.companyType ===
                    CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY && (
                    <div className="d-flex gap-1">
                      <button
                        type="button"
                        className={
                          paper === "certification"
                            ? "btn btn-info btn-sm"
                            : "btn btn-soft-info btn-sm"
                        }
                        onClick={() => setPaper("certification")}
                      >
                        CERTIFICATION
                      </button>
                      <button
                        type="button"
                        className={
                          paper === "license"
                            ? "btn btn-info btn-sm"
                            : "btn btn-soft-info btn-sm"
                        }
                        onClick={() => setPaper("license")}
                      >
                        LICENSE
                      </button>
                      <button
                        type="button"
                        className={
                          paper === "permit"
                            ? "btn btn-info btn-sm"
                            : "btn btn-soft-info btn-sm"
                        }
                        onClick={() => setPaper("permit")}
                      >
                        PERMIT
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
              {company.companyType ===
                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY &&
              paper === "certification" ? (
                <StackedColumnCert dataColors='["--in-primary", "--in-success", "--in-warning", "--in-info"]' />
              ) : paper === "permit" ? (
                <StackedColumnPermit dataColors='["--in-primary", "--in-success", "--in-warning", "--in-info"]' />
              ) : paper === "license" ? (
                <StackedColumnLicense dataColors='["--in-primary", "--in-success", "--in-warning", "--in-info"]' />
              ) : null}
              <ActivitiesChart dataColors='["--in-danger", "--in-primary", "--in-success", "--in-warning", "--in-info"]' />
            </Card.Body>
          </Card>
        </Col>

        {/* <Structure /> */}
      </Row>
    </React.Fragment>
  );
};

export default PaymentActivity;
