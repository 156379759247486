import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Badge, Container } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import ApplicationChat from '../../Common/ApplicationChat';
import LicenseChat from '../../Common/LicenseChat';

// Define the type for application details
interface ApplicationDetails {
  id: string;
  applicationTitle: string;
  description: string;
  status: string;
  target_name: string;
  companyName:string;
  type:string;
  applicantId:string
}

const LCSApplicationDetailsPage: React.FC = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [applicationDetails, setApplicationDetails] = useState<ApplicationDetails | null>(null);

  useEffect(() => {
    // First, try to get details from navigation state
    const stateDetails = location.state?.applicationDetails;
    
    if (stateDetails) {
      setApplicationDetails(stateDetails);
    } else {
      console.log('No application details found for ID:', id);
    }
  }, [location.state, id]);

  // Helper function to render status badge
  const renderStatusBadge = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return <Badge bg="success">{status}</Badge>;
      case 'pending':
        return <Badge bg="warning">{status}</Badge>;
      case 'disabled':
        return <Badge bg="danger">{status}</Badge>;
      default:
        return <Badge bg="secondary">{status}</Badge>;
    }
  };

  if (!applicationDetails) {
    return (
      <div className="container-fluid">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>Loading application details...</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  const appId = applicationDetails.id;

  return (
    <React.Fragment>
    <div className="page-content">
    <Container fluid>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <h4 className="card-title mb-0">License Application Details</h4>
            </Card.Header>
            <Card.Body>
            <Row className="align-items-start mb-3">
                <Col md={3} className="text-muted">
                  <h5 className="mb-0">Application Id:</h5>
                </Col>
                <Col md={9}>
                  <p className="mb-0">{appId}</p>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col md={3} className="text-muted">
                  <h5 className="mb-0">Application Title:</h5>
                </Col>
                <Col md={9}>
                  <p className="mb-0">{applicationDetails.applicationTitle}</p>
                </Col>
              </Row>

              <Row className="align-items-center mb-3">
                <Col md={3} className="text-muted">
                  <h5 className="mb-0">Status:</h5>
                </Col>
                <Col md={9}>
                  {renderStatusBadge(applicationDetails.status)}
                </Col>
              </Row>

              <Row className="align-items-center mb-3">
                <Col md={3} className="text-muted">
                  <h5 className="mb-0">Facility Name:</h5>
                </Col>
                <Col md={9}>
                  <p className="mb-0">{applicationDetails.target_name}</p>
                </Col>
              </Row>

              <Row className="align-items-start mb-3">
                <Col md={3} className="text-muted">
                  <h5 className="mb-0">Description:</h5>
                </Col>
                <Col md={9}>
                  <p className="mb-0">{applicationDetails.description}</p>
                </Col>
              </Row>
              <Row className="align-items-start mb-3">
                <Col md={3} className="text-muted">
                  <h5 className="mb-0">Company Name:</h5>
                </Col>
                <Col md={9}>
                  <p className="mb-0">{applicationDetails.companyName}</p>
                </Col>
              </Row>
              <Row className="align-items-start mb-3">
                <Col md={3} className="text-muted">
                  <h5 className="mb-0">Application type:</h5>
                </Col>
                <Col md={9}>
                  <p className="mb-0">{applicationDetails.type}</p>
                </Col>
              </Row>
    
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
      <LicenseChat appId={appId}/>
      </Row>
      </Container>
    </div>
    </React.Fragment>
  );
};

export default LCSApplicationDetailsPage;