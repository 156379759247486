import { createSlice } from "@reduxjs/toolkit";

import { getUsers, addUsers, editUsers, deleteUsers } from "./thunk";

export const initialState = {
  usersList: [],
  error: {},
};

const UserSlice: any = createSlice({
  name: "User",
  initialState,
  reducers: {},

  extraReducers: (builder: any) => {
    //User Transaction
    builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
      console.log('action.payload', action.payload);
      const users = action.payload?.data?.usersFromDb;
      state.usersList = users
    });

    // add
    builder.addCase(addUsers.fulfilled, (state: any, action: any) => {
      console.log(action, 'action')
      state.usersList.push(action.payload);
    });
    builder.addCase(addUsers.rejected, (state: any, action: any) => {
      console.log(action, 'action')
      state.error = action.payload.error || null;
    });

    builder.addCase(getUsers.rejected, (state: any, action: any) => {
        console.log('action.payload', action.payload)
      state.error = action.payload?.error || null;
    });

    builder.addCase(editUsers.fulfilled, (state: any, action: any) => {
      state.usersList = state.usersList.map((user: any) =>
        user.id === action.payload.id ? { ...user, ...action.payload } : user
      );
    });
    builder.addCase(editUsers.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteUsers.fulfilled, (state: any, action: any) => {
      state.usersList = (state.usersList || []).filter(
        (users: any) => users.id !== action.payload.id
      );
    });
    builder.addCase(deleteUsers.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default UserSlice.reducer;
