import { createSlice } from "@reduxjs/toolkit";
import { setAuthorization } from "../../helpers/api_helper";

export const initialState = {
  user: {},
  tokens: {} as { refreshToken: string; accessToken:string},
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  isModuleSelected: false,
  selected_module: '',
  reloaded: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    postLoginInterfaceSelection(state, action) {
      console.log( 'state, action', state.user, action)
      state.isModuleSelected = action.payload ? true : false
      state.selected_module = action.payload || ''; // || 'wmp'
      state.loading = false;
      state.errorMsg = false;
    },
    apiLoading(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      console.log('action.payload.data', action.payload)
      state.error = action.payload;
      state.loading = false;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      console.log( 'state, action', state.user, action)
      state.user = action.payload?.data
      state.tokens = action.payload?.tokens
      state.loading = false;
      state.errorMsg = false;
      setAuthorization(action.payload?.tokens?.accessToken);
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true
      state.loading = false
      state.isModuleSelected = false
      state.selected_module = ''
    },
    reset_login_flag(state) {
      state.error = ""
      state.loading = false;
      state.errorMsg = false;
      state.reloaded = false;
    }
  },
});

export const {
  apiLoading,
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  postLoginInterfaceSelection
} = loginSlice.actions

export default loginSlice.reducer;