import React, { useState } from "react";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import LiscenceListTable from "./components/LiscenceListTable";

const LisenceList = () => {

    document.title = "User |  Electronic Registration, Management and Certification System";

    const [isShow, setIsShow] = useState(false);

    const hideLiscenceModal = () => {
      setIsShow(!isShow);
    };

    const isNimasa = true;
    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb pageTitle="License Lists" title="License Lists" />
            <LiscenceListTable isShow={isShow} hideUserModal={hideLiscenceModal} isNimasa={isNimasa}/>
          </Container>
        </div>
      </React.Fragment>
    )
}


export default LisenceList;