import React from 'react';
import { Modal } from 'react-bootstrap';

interface ViewLicenseDetailsProps {
  isShow: boolean;
  handleClose: () => void;
  licenseDetails?: {
    license_number: string;
    title: string;
    target_name: string;
    issueDate: string;
    expires_at: string;
    status:string;
    createdBy:string;
    
  };
}

const ViewLicenseDetails: React.FC<ViewLicenseDetailsProps> = ({ 
  isShow, 
  handleClose, 
  licenseDetails 
}) => {
  return (
    <Modal show={isShow} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>License Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {licenseDetails ? (
          <div className="license-details" style={{marginTop:20}}>
            <div className="" style={{width:"100%",display:"flex", flexDirection:"row", marginBottom:10}}>
              <strong style={{width:125}} >License Number:</strong> <div>{licenseDetails.license_number}</div>
            </div>
            <div className="" style={{width:"100%", display:"flex", flexDirection:"row" , marginBottom:10}}>
              <strong style={{width:125}} >License Title:</strong> <div>{licenseDetails.title}</div>
            </div>
            <div className="" style={{width:"100%", display:"flex", flexDirection:"row" , marginBottom:10}}>
              <strong style={{width:125}} >Status</strong> <div className='badge bg-warning-subtle text-warning p-2'>{licenseDetails.status}</div>
            </div>
            <div className="" style={{width:"100%",display:"flex", flexDirection:"row" , marginBottom:10}}>
              <strong style={{width:125}} >Facility Name:</strong> <div>{licenseDetails.target_name}</div>
            </div>
            <div className="" style={{width:"100%", display:"flex", flexDirection:"row" , marginBottom:10}}>
              <strong style={{width:125}} >Expiry Date:</strong> <div>{licenseDetails.expires_at}</div>
            </div>
            <div className="" style={{width:"100%", display:"flex", flexDirection:"row" , marginBottom:10}}>
              <strong style={{width:125}} >Issued By:</strong> <div>{licenseDetails.createdBy}</div>
            </div>
            <div style={{marginTop:40}}>
              <strong>Note this license will remain inactive until payment has been processed and completed</strong>
            </div>
            
          </div>
        ) : (
          <p>No license details available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-success">
           Pay Now
        </button>
        <button 
          type="button" 
          className="btn btn-light" 
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewLicenseDetails;