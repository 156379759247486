import React, { useState } from "react";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import ApplicationTable from "./components/ApplicationTable";


const LisenceApplication = () => {
    document.title = "User |  Electronic Registration, Management and Certification System";

    const [isShow, setIsShow] = useState(false);

    const hideUserModal = () => {
      setIsShow(!isShow);
    };
    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb pageTitle="Apply for License" title="Apply for License" />
               <ApplicationTable isShow={isShow} hideUserModal={hideUserModal}/>
            
          </Container>
        </div>
      </React.Fragment>
    )
}


export default LisenceApplication;