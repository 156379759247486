import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import ViewLicenseDetails from "../../../Common/CrudModal/ViewLicenseDetail";
import { getLicenses as onGetLicenses } from "../../../slices/license/thunk";
import { useDispatch } from "react-redux";


interface userProps {
  isShow: any;
  hideUserModal: any;
  isNimasa?: boolean;
}

const LiscenceListTable = ({ isShow, hideUserModal, isNimasa }: userProps) => {
  const dispatch = useDispatch();


  const [licenseList, setLicenseList] = useState([]);

  const selectLicenseList = createSelector(
    (state: any) => state.License,
    (licenses: any) => licenses.licenseList
  );

  const licenseListFromRedux = useSelector(selectLicenseList);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: 10,
      status: "PENDING",
    };
    dispatch(onGetLicenses(params));
  }, [dispatch]);

  console.log(licenseListFromRedux);

  useEffect(() => {
    if (
      licenseListFromRedux &&
      licenseListFromRedux.data &&
      licenseListFromRedux.data.licenses
    ) {
      const mappedLicenses =
        licenseListFromRedux?.data.licenses?.map((lcs: any) => ({ 
          license_number: lcs.license_number,
          companyName: lcs.companyName,
          issuingAuthority: lcs.issuing_authority,
          expires_at: lcs.expires_at,
          targetId: lcs.target_id,
          issued_at: lcs.issued_at,
          target_name: lcs.target_name,
          title:lcs.title,
          status:lcs.status,
          createdBy:lcs.createdBy,
         
        })) || [];
      setLicenseList(mappedLicenses);
    }
  }, [licenseListFromRedux]);

  const [viewCertDetails, setViewCertDetails] = useState<boolean>(false);
  const [selectedCertificate, setSelectedCertificate] = useState<any>(null);

  const handleViewCertDetails = (certificate: any) => {
    setSelectedCertificate(certificate);
    setViewCertDetails(true);
  };

  const handleCloseViewCertDetails = () => {
    setViewCertDetails(false);
    setSelectedCertificate(null);
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(() => {
    const baseColumns: columnsType[] = [
      {
        Header: "Liscence No",
        accessor: "license_number",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Facility Name",
        accessor: "target_name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Expiry Date",
        accessor: "expires_at",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Liscence Type",
        accessor: "title",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Company Name",
        accessor: "companyName",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Liscencing Date",
        accessor: "issued_at",
        Filter: false,
        isSortable: true,
      }
    ];


    baseColumns.push({
      Header: "ACTION",
      accessor: "action",
      Filter: false,

      isSortable: false,
      Cell: (cell: any) => (
        <ul className="list-inline hstack gap-2 mb-0">
          <li
            className="list-inline-item"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-placement="top"
            aria-label="View"
            data-bs-original-title="View"
          >
            <Link
              to="#"
              className="btn btn-soft-info btn-sm d-inline-block"
              onClick={() => handleViewCertDetails(cell.row.original)}
            >
              <i className="las la-eye fs-17 align-middle"></i>
            </Link>
          </li>
        </ul>
      ),
    });

    return baseColumns;
  }, [isNimasa]);

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                id="searchMemberList"
                placeholder="Search for Result"
                // onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                variant="info"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>All</Dropdown.Item>
                <Dropdown.Item>Last Week</Dropdown.Item>
                <Dropdown.Item>Last Month</Dropdown.Item>
                <Dropdown.Item>Last Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>
      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {licenseList && licenseList.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={licenseList || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  PaginationClass="align-items-center mt-4 gy-3"
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ViewLicenseDetails
        isShow={viewCertDetails}
        handleClose={handleCloseViewCertDetails}
        licenseDetails={selectedCertificate}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default LiscenceListTable;
