import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { StopApplication } from "../../../Common/CrudModal/StopApplicationModal";
import LiscenceAppplicationModal from "../../../Common/CrudModal/LiscenceApplication";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import EditLiscenceApplication from "../../../Common/CrudModal/EditLiscenceApplication";
import { getLicenseApplications as onGetLicenseApplications } from "../../../slices/license/thunk";

interface userProps {
  isShow: any;
  hideUserModal: any;
}

interface ApplicationData {
  id: string;
  title: string;
  description: string;
  companyName: string;
  status: string;
}

const ApplicationTable = ({ isShow, hideUserModal }: userProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [applicationList, setApplicationList] = useState<any[]>([]);

  const state = useSelector((state: any) => state); // Logs the entire state

  console.log("Redux State:", state);

  const selectApplicationList = createSelector(
    (state: any) => state.License, // Access the Certification slice
    (license: any) => license.liscenseApplicationList // Directly return applicationList from the state
  );

  const applicationListFromRedux = useSelector(selectApplicationList);
  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: 10,
      status: "PENDING",
      type: "LICENSE", // Empty string or null
    };
    dispatch(onGetLicenseApplications(params));
  }, [dispatch]);


  

  console.log("This is the application list:", applicationListFromRedux);

  useEffect(() => {
    if (
      applicationListFromRedux &&
      applicationListFromRedux.data &&
      applicationListFromRedux.data.applications
    ) {
      const mappedApplications =
        applicationListFromRedux?.data?.applications?.map((app: any) => ({
          id: app.id,
          applicationTitle: app.title,
          description: app.description,
          companyName: app.companyName,
          status: app.status.toLowerCase(),
          target_name: app.target_name,
          type:app.type,
        })) || [];

      setApplicationList(mappedApplications);
    }
  }, [applicationListFromRedux]);

  const [stopApplication, setStopApplication] = useState<boolean>(false);
  const [stopId, setStopId] = useState<any>();

  const handleStopModal = useCallback(
    (id: any) => {
      setStopApplication(!stopApplication);
      setStopId(id);
    },
    [stopApplication]
  );
   
  const handleRowClick = (row: any) => {

    const application = row.row.original;
    // Create a plain object with only the necessary, serializable data
    const serializableApplicationDetails = {
      id: application.id,
      applicationTitle: application.applicationTitle,
      description: application.description,
      status: application.status,
      target_name: application.target_name,
      companyName: application.companyName,
      type:application.type,
      applicantId:application.applicantId

    };
  
    navigate(`/lcs-applications/${application.id}`, {
      state: { applicationDetails: serializableApplicationDetails }
    });
  };

  const [editLiscence, setEditLiscence] = useState<boolean>(false);
  const [edit, setEdit] = useState<any>();

  const handleCloseEdit = () => setEditLiscence(false);
  const handleEditUser = (item: any) => {
    setEditLiscence(true);
    setEdit({});
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "Application Title",
        accessor: "applicationTitle",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Facility Name",
        accessor: "target_name",
        Filter: false,
        isSortable: true,
      },

      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case "active":
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
              case "pending":
                return (
                  <span className="badge bg-warning-subtle text-warning p-2">
                    {cell.row.original.status}
                  </span>
                );
            case "disabled":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },
      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        style: { width: "12%" },

        isSortable: false,
        Cell: (cell: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            <li
              className="list-inline-item edit"
              onClick={() => {
                const item = cell.row.original;
                handleEditUser(item);
              }}
            >
              <Link to="#" className="btn btn-soft-info btn-sm d-inline-block">
                <i className="las la-pen fs-17 align-middle"></i>
              </Link>
            </li>
            <li
              className="list-inline-item"
              onClick={() => {
                const item = cell.row.original;
                handleStopModal(item);
              }}
            >
              <Link
                to="#"
                className="btn btn-soft-danger btn-sm d-inline-block"
              >
                <i className="las la-file-download fs-17 align-middle"></i>
              </Link>
            </li>
          </ul>
        ),
      },
    ],
    [handleStopModal]
  );

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <Col sm={4}>
          <button
            className="btn btn-primary addPayment-modal"
            onClick={hideUserModal}
          >
            <i className="las la-plus me-1"></i> Apply for License
          </button>
        </Col>

        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                id="searchMemberList"
                placeholder="Search for Result"
                // onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                variant="info"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>All</Dropdown.Item>
                <Dropdown.Item>Last Week</Dropdown.Item>
                <Dropdown.Item>Last Month</Dropdown.Item>
                <Dropdown.Item>Last Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {applicationList && applicationList.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={applicationList || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  PaginationClass="align-items-center mt-4 gy-3"
                  onRowClick={handleRowClick}
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <LiscenceAppplicationModal
        isShow={isShow}
        handleClose={hideUserModal}
        // handleShow={isShow}
      />

      <EditLiscenceApplication
        isShow={editLiscence}
        handleClose={handleCloseEdit}
        edit={edit}
      />

      <StopApplication show={stopApplication} handleClose={handleStopModal} />
      <ToastContainer />
    </React.Fragment>
  );
};

export default ApplicationTable;
