import React, { useEffect } from "react";
import {  matchRoutes, Navigate, Route, useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Common/Hooks/UserHooks";

import { logoutUser } from "../slices/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const AuthProtected = (props: any) => {
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/" }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const { userProfile, loading, token } = useProfile();


  const selectAccount = createSelector(
    (state: any) => state.Login,
    (account: any) => {
      // console.log("account", account);
      return {
        user: account.user,
        selected_module: account.selected_module,
      };
    }
  );

  const { selected_module } = useSelector(selectAccount);

  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      dispatch(logoutUser());
      sessionStorage.removeItem('authUser');
    }
  }, [token, userProfile, loading, dispatch]);

  console.log('matches', matches)
  console.log('selected_module', selected_module)

  /*
    Navigate is un-auth access protected routes via url
    */

  if (!userProfile && loading && !token) {
    return (
      <Navigate to={{ pathname: "/login" }} />
    );
  }

  else if (!matches && !selected_module) {
    return (<Navigate to={{ pathname: "/" }} />)
  }

  return <>{props.children}</>;
};


const AccessRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };