import { Container } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import React,{ useState } from "react";
import PendingApplicationTable from "../CertManagement/components/PendingApplicationTable";
import PendingLicensesTable from "./components/PendingLicensesTable";


const PendingLiscencesApplications = () => {
    document.title = "User |  Electronic Registration, Management and Certification System";

    const [isShow, setIsShow] = useState(false);

    const hideUserModal = () => {
      setIsShow(!isShow);
    };
    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb pageTitle="Pending Applications" title="Pending Applications" />
            <PendingLicensesTable isShow={isShow} hideUserModal={hideUserModal}/>
          </Container>
        </div>
      </React.Fragment>
    )
}

export default PendingLiscencesApplications;