import { createSlice } from "@reduxjs/toolkit";

import { getPortReceptions, addPortReceptions, editPortReceptions, deletePortReceptions,
  getWasteManagements, addWasteManagements, editWasteManagements, deleteWasteManagements,
  getPortOfOperations, addPortOfOperations, editPortOfOperations, deletePortOfOperations, deActivateWasteManagements, activateWasteManagements } from "./thunk";

export const initialState = {
  portReceptionsList: [],
  portOfOperationsList: [],
  wastesList: [],
  error: {},
};

const FacilitySlice: any = createSlice({
  name: "Facility",
  initialState,
  reducers: {},

  extraReducers: (builder: any) => {
    //PortReception Transaction
    builder.addCase(getPortReceptions.fulfilled, (state: any, action: any) => {
      state.portReceptionsList = action.payload;
    });

    // add
    builder.addCase(addPortReceptions.fulfilled, (state: any, action: any) => {
      state.portReceptionsList.push(action.payload?.data);
    });
    builder.addCase(addPortReceptions.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getPortReceptions.rejected, (state: any, action: any) => {
        console.log('action.payload', action.payload)
      state.error = action.payload?.error || null;
    });

    builder.addCase(editPortReceptions.fulfilled, (state: any, action: any) => {
      state.portReceptionsList = state.portReceptionsList.map((user: any) =>
        user.id === action.payload.id ? { ...user, ...action.payload } : user
      );
    });
    builder.addCase(editPortReceptions.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletePortReceptions.fulfilled, (state: any, action: any) => {
      state.portReceptionsList = (state.portReceptionsList || []).filter(
        (users: any) => users.id !== action.payload.id
      );
    });
    builder.addCase(deletePortReceptions.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    //PortOfOperation Transaction
    builder.addCase(getPortOfOperations.fulfilled, (state: any, action: any) => {
      state.portOfOperationsList = action.payload;
    });

    // add
    builder.addCase(addPortOfOperations.fulfilled, (state: any, action: any) => {
      state.portOfOperationsList.push(action.payload?.data);
    });
    builder.addCase(addPortOfOperations.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getPortOfOperations.rejected, (state: any, action: any) => {
        console.log('action.payload', action.payload)
      state.error = action.payload?.error || null;
    });

    builder.addCase(editPortOfOperations.fulfilled, (state: any, action: any) => {
      state.portOfOperationsList = state.portOfOperationsList.map((user: any) =>
        user.id === action.payload.id ? { ...user, ...action.payload } : user
      );
    });
    builder.addCase(editPortOfOperations.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deletePortOfOperations.fulfilled, (state: any, action: any) => {
      state.portOfOperationsList = (state.wastesList || []).filter(
        (portOfOperations: any) => portOfOperations.id !== action.payload.id
      );
    });
    builder.addCase(deletePortOfOperations.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    
    //WasteManagement Transaction
    builder.addCase(getWasteManagements.fulfilled, (state: any, action: any) => {
      state.wastesList = action.payload;
    });

    // add
    builder.addCase(addWasteManagements.fulfilled, (state: any, action: any) => {
      console.log('action.payload', action.payload)
      state.wastesList.push(action.payload.data);
    });
    builder.addCase(addWasteManagements.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getWasteManagements.rejected, (state: any, action: any) => {
        console.log('action.payload', action.payload)
      state.error = action.payload?.error || null;
    });

    builder.addCase(editWasteManagements.fulfilled, (state: any, action: any) => {
      state.wastesList = state.wastesList.map((user: any) =>
        user.id === action.payload.id ? { ...user, ...action.payload } : user
      );
    });
    builder.addCase(editWasteManagements.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteWasteManagements.fulfilled, (state: any, action: any) => {
      state.wastesList = (state.wastesList || []).filter(
        (users: any) => users.id !== action.payload.id
      );
    });
    builder.addCase(deleteWasteManagements.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    
    builder.addCase(activateWasteManagements.fulfilled, (state: any, action: any) => {
      state.wastesList = state.wastesList.map((waste: any) =>
        waste.id === action.payload.id ? { ...waste, status: 'active' } : waste
      );
    });
    builder.addCase(activateWasteManagements.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    
    builder.addCase(deActivateWasteManagements.fulfilled, (state: any, action: any) => {
      state.wastesList = state.wastesList.map((waste: any) =>
        waste.id === action.payload.id ? { ...waste, status: 'deactive' } : waste
      );
    });
    builder.addCase(deActivateWasteManagements.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default FacilitySlice.reducer;
