import { Navigate } from "react-router-dom";

// Dashboard

import Signin from "../pages/AuthenticationInner/Signin";
import Signup from "../pages/AuthenticationInner/Signup";
import PasswordReset from "../pages/AuthenticationInner/PasswordReset";
import Lockscreen from "../pages/AuthenticationInner/Lockscreen";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Register from "../pages/Authentication/Register";
import UserProfile from "../pages/Authentication/UserProfile";
import Users from "../pages/Users";
import Ships from "../pages/Ships";
import Companies from "../pages/Companies";
import OilPlatforms from "../pages/OilPlatformManagement/OilPlatform";
import PortReceptions from "../pages/Facilities/PortReception";
import WasteManagements from "../pages/Facilities/WasteManagement";
import PortOfOperations from "../pages/Facilities/PortOfOperation";
import Rigs from "../pages/OilPlatformManagement/Rigs";
import WasteNotification from "../pages/WasteNotificationManagement/WasteNotification";
import AddWasteNotification from "../pages/WasteNotificationManagement/AddWasteNotification";
import WasteNotificationDetails from "../pages/WasteNotificationManagement/WasteNotificationDetails";
import EditWasteNotification from "../pages/WasteNotificationManagement/EditWasteNotification";
import Bills from "../pages/BillManagement/Bills";
import BillDetails from "../pages/BillManagement/BillDetails";
import ComplianceInspectionReport from "../pages/WasteNotificationManagement/InspectionReport";
import Wallets from "../pages/Wallets";
import WasteComplianceCertificate from "../pages/WasteNotificationManagement/WasteComplianceCertificate";
import Home from "../pages/Home";
import Facility from "../pages/Facility/Facility";
import CertificateApplication from "../pages/CertManagement/CertificateApplication";
import ViewCertificates from "../pages/CertManagement/ViewCertificates";
import React from "react";
import PendingApplications from "../pages/CertManagement/PendingApplications";
import GrantedCertificates from "../pages/CertManagement/GrantedCertificates";
import LisenceApplication from "../pages/LicenseManagement/LicenseApplication";
import LisenceList from "../pages/LicenseManagement/LisenceList";
import GrantedLiscences from "../pages/LicenseManagement/GrantedLiscences";
import PendingLiscencesApplications from "../pages/LicenseManagement/PendingLiscencesApplications";
import FacilityDetails from "../pages/Facility/FacilityDetails";
import AddFacility from "../pages/Facility/AddFacility";
import EditFacility from "../pages/Facility/EditFacility";
import CompanyDetails from "../pages/CompanyDetails/CompanyDetails";
import EditCompanyDetails from "../pages/CompanyDetails/EditCompanyDetails";

import OilPlatformFacility from "../pages/Facility/OilPlatformManagement/OilPlatform";
import ShipFacility from "../pages/Facility/Ships";
import FacilityDetailsTest from "../pages/Facility/FacilityDetailsTest";
import PortReceptionFacility from "../pages/Facility/PortReception";
import PortOfOperationsFacility from "../pages/Facility/PortOfOperation";
import WasteManagementFacility from "../pages/Facility/WasteManagement";
import ApplicationDetails from "../pages/CertManagement/ApplicationDetails";
import LCSApplicationDetailsPage from "../pages/LicenseManagement/LcsApplicationDetails";
import PendingApplicationDetails from "../pages/CertManagement/PendingApplicationDetails";
import PendingLcsApplicationDetails from "../pages/LicenseManagement/PendingLcsApplicationDetails";
import DashboardSelector from "../Common/DashboardSelector";
import Profile from "../pages/Profile/Index";
import ApplicationCreationDetails from "../pages/LicenseManagement/ApplicationCreationDetails";

interface RouteObject {
  path: string;
  component: any;
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard

  { path: "/index", component: <DashboardSelector /> },
  { path: "/dashboard", component: <DashboardSelector /> },

  // { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/selector", exact: true, component: <Home /> },
  { path: "*", component: <Navigate to="/dashboard" /> },

  // { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/", exact: true, component: <Home /> },
  { path: "*", component: <Navigate to="/dashboard" /> },

  { path: "/bill", component: <Bills /> },
  { path: "/bill-details/:id", component: <BillDetails /> },
  { path: "/waste-notification", component: <WasteNotification /> },
  { path: "/waste-notification-add", component: <AddWasteNotification /> },
  {
    path: "/waste-notification-details/:id/edit",
    component: <EditWasteNotification />,
  },
  {
    path: "/waste-notification-details/:id",
    component: <WasteNotificationDetails />,
  },

  //User Management
  { path: "/user-management", component: <Users /> },

  { path: "/ships", component: <Ships /> },
  { path: "/companies", component: <Companies /> },
  { path: "/oil-platforms", component: <OilPlatforms /> },
  { path: "/rigs", component: <Rigs /> },
  { path: "/port-receptions", component: <PortReceptions /> },
  { path: "/port-of-operations", component: <PortOfOperations /> },
  { path: "/waste-managements", component: <WasteManagements /> },
  { path: "/wallets", component: <Wallets /> },

  {
    path: "/compliance-certificate/:id",
    component: <WasteComplianceCertificate />,
  },
  {
    path: "/compliance-inspection-report/:id",
    component: <ComplianceInspectionReport />,
  },

  //Facilities
  { path: "/facility-management/new", component: <AddFacility /> },
  { path: "/facility-management/:id", component: <FacilityDetails /> },
  { path: "/facility-management/:id/edit", component: <EditFacility /> },
  {
    path: "/facility-management/port-receptions",
    component: <PortReceptionFacility />,
  },
  {
    path: "/facility-management/port-of-operations",
    component: <PortOfOperationsFacility />,
  },
  {
    path: "/facility-management/waste-managements",
    component: <WasteManagementFacility />,
  },
  {
    path: "/facility-management/oil-platforms",
    component: <OilPlatformFacility />,
  },
  { path: "/facility-management/ships", component: <ShipFacility /> },
  {
    path: "/facility-management/ships/:id",
    component: <FacilityDetailsTest />,
  },
  {
    path: "/facility-management/oil-platforms/:id",
    component: <FacilityDetailsTest />,
  },
  {
    path: "/facility-management/port-of-operations/:id",
    component: <FacilityDetailsTest />,
  },
  {
    path: "/facility-management/port-receptions/:id",
    component: <FacilityDetailsTest />,
  },
  {
    path: "/facility-management/waste-managements/:id",
    component: <FacilityDetailsTest />,
  },

  { path: "/wallets", component: <Wallets /> },

  //Certifications
  {
    path: "/certification-management/apply",
    component: <CertificateApplication />,
  },
  {
      path:"/cert-applications/:id",
      component:<ApplicationDetails/>
  },
  {
    path:"/pending-applications/:id",
    component:<PendingApplicationDetails/>
  },
  {
    path: "/certification-management/view-certificates",
    component: <ViewCertificates />,
  },
  {
    path: "/certification-management/pending-applications",
    component: <PendingApplications />,
  },
  {
    path: "/certification-management/granted-certificates",
    component: <GrantedCertificates />,
  },
  { path: "/license-management/apply", component: <LisenceApplication /> },
  { path: "/license-management/view-licenses", component: <LisenceList /> },
  {
    path: "/license-management/pending-applications",
    component: <PendingLiscencesApplications />,
  },
  {
    path: "/license-management/apply/app-creation",
    component: <ApplicationCreationDetails />,
  },
  {
    path:"/lcs-applications/:id",
    component:<LCSApplicationDetailsPage/>
},
{
  path:"/pending-lcs-applications/:id",
  component:<PendingLcsApplicationDetails/>
},
  {
    path: "/license-management/granted-licenses",
    component: <GrantedLiscences />,
  },
  { path: "/company-details", component: <CompanyDetails /> },
  { path: "/edit-company-details/:id", component: <EditCompanyDetails /> },

  //  Profile
  { path: "/user-profile", component: <UserProfile /> },

  {
    path: "/certification-management/apply",
    component: <CertificateApplication />,
  },
  {
    path: "/certification-management/view-certificates",
    component: <ViewCertificates />,
  },
  {
    path: "/certification-management/pending-applications",
    component: <PendingApplications />,
  },
  {
    path: "/certification-management/granted-certificates",
    component: <GrantedCertificates />,
  },
  { path: "/license-management/apply", component: <LisenceApplication /> },
  { path: "/license-management/view-licenses", component: <LisenceList /> },
  {
    path: "/license-management/pending-applications",
    component: <PendingLiscencesApplications />,
  },
  {
    path: "/license-management/granted-licenses",
    component: <GrantedLiscences />,
  },

  //  Profile
  { path: "/user-profile", component: <UserProfile /> },
  { path: "/profile", component: <Profile /> },
];

const publicRoutes: Array<RouteObject> = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <Register /> },

  { path: "/auth-signin", component: <Signin /> },
  { path: "/auth-signup", component: <Signup /> },
  { path: "/auth-pass-reset", component: <PasswordReset /> },
  { path: "/auth-lockscreen", component: <Lockscreen /> },
];

export { authProtectedRoutes, publicRoutes };
