import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
// import { addWasteNotificationInspections as onScheduleInspection } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import { PatternFormat } from "react-number-format";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { getPortOfOperations as onGetPortOfOperations } from "../../slices/thunk";
import Dropzone from "react-dropzone";
import Select from "react-select";
import {
  GET_CLIENT_WASTE_NOTIFICATIONS,
  NPA_USERS,
} from "../../helpers/url_helper";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
  handleScheduledInspection: (data: any, resetFunc: any) => Promise<void>;
  data: any;
}

const customStyles = {
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      backgroundColor: "#3762ea",
    };
  },
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    backgroundColor: "#3762ea",
    color: "white",
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: "white",
    backgroundColor: "#3762ea",
    ":hover": {
      backgroundColor: "#3762ea",
      color: "white",
    },
  }),
};

const ScheduleInspection = ({
  isShow,
  handleClose,
  handleShow,
  handleScheduledInspection,
  data = { inspectionTeam: [] },
}: userProps) => {
  const [defaultOption, setDefaultOption] = useState<any[]>();
  const [state, setState] = useState<any[]>();
  const [options, setOptions] = useState<any[]>([
    { value: "Choice 1", label: "Choice 1" },
    { value: "Choice 2", label: "Choice 2" },
    { value: "Choice 3", label: "Choice 3" },
    { value: "Choice 4", label: "Choice 4" },
  ]);

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const selectPortOfOperationsList = createSelector(
    (state: any) => state.Facility,
    (state: any) => state.Login,
    (portOfOperations: any, login: any) => ({
      portOfOperationsList: portOfOperations.portOfOperationsList,
      tokens: login.tokens,
    })
  );

  const { tokens } = useSelector(selectPortOfOperationsList);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      // users: [{ value: ""}],
      users: defaultOption || '',
      inspectionDate: data?.inspectionScheduledDate || "",
    },
    validationSchema: Yup.object({
      users: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required("Label is required"),
          value: Yup.string().required("Value is required"),
        })
      ),
      inspectionDate: Yup.string().required(
        "Please Enter Your Inspection Date"
      ),
    }),

    onSubmit: (values: any) => {
      // let newid = Math.floor(Math.random() * (30 - 20)) + 20;
      const NewWasteNotificationInspection = {
        users: values["users"].map((u: any) => u?.value),
        inspectionDate: values["inspectionDate"],
      };
      handleScheduledInspection(NewWasteNotificationInspection, () =>
        formik.resetForm()
      );
    },
  });

  useEffect(() => {
    const getData = async () => {
      if (data?.inspectionTeam?.length) {
        const teamUsers = data?.inspectionTeam?.join(",");
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}${NPA_USERS}?userIds=${teamUsers}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        console.log("res", JSON.parse(JSON.stringify(res)));
        const team = JSON.parse(JSON.stringify(res?.data?.usersFromDb));
        setDefaultOption((prev) => {
          return team.map((datum: any) => {
            const name = `${datum.firstName} ${datum.lastName}`;
            return { value: datum.id, label: name };
          });
        });
      }
    };
    getData();
  }, [data?.inspectionTeam, tokens?.accessToken]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${NPA_USERS}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        const users = JSON.parse(JSON.stringify(response?.data?.usersFromDb));
        setState(users);
        setOptions((prev) =>
          users.map((datum: any) => {
            const name = `${datum.firstName} ${datum.lastName}`;
            return { value: datum.id, label: name };
          })
        );
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          // toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [dispatch, navigate, tokens]);

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Schedule Inspections</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="users">Team members</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  defaultValue={defaultOption}
                  isMulti
                  options={options}
                  styles={customStyles}
                  // value={formik.values.users?.split(',')}
                  onChange={(event: any) => {
                    console.log("event", event);
                    formik.handleChange({
                      target: { name: "users", value: event },
                    });
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.users}
                />
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="users">Inspection Date</Form.Label>
                <FlatPickr
                  name="inspectionDate"
                  id="date-field"
                  className="form-control bg-light border-0"
                  placeholder="Inspection Date"
                  readonly
                  options={{
                    altInput: true,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  // onChange={dateformate}
                  onChange={(e: any) =>
                    formik.handleChange({
                      target: {
                        value: e.toString(),
                        name: "inspectionDate",
                      },
                    })
                  }
                  value={formik.values.inspectionDate || ""}
                />
                {formik.errors.inspectionDate &&
                formik.touched.inspectionDate ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.inspectionDate}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-primary">
                  Schedule Inspection
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ScheduleInspection;
