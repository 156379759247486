import React, { useState, useEffect, Fragment } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";

import logoDark from "../../../assets/images/logo-dark.png";
import logoLight from "../../../assets/images/logo-light.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import Cleave from "cleave.js/react";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import {
  getShips as onGetShips,
  getOilPlatforms as onGetOilPlatforms,
  getWasteManagements as onGetWasteManagements,
  getPortReceptions as onGetPortReceptions,
  getPortOfOperations as onGetPortOfOperations,
  // addClientWasteNotifications as onAddClientWasteNotifications,
  editClientWasteNotifications as onEditClientWasteNotifications,
} from "../../../slices/thunk";
import { CompanyTypeEnum } from "../../../helpers/enums/company.enum";
import { FieldArray } from "formik";
import { FormikProvider } from "formik";
import axios from "axios";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../../helpers/url_helper";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import {
  WasteType,
  WasteTypeKey,
} from "../../../helpers/enums/visitRequisition.enum";

const ComplianceInspectionReport = () => {
  document.title =
    "Compliance Inspection Report | Maritime Waste Management Admin & Dashboard Platform";

  const [paymentStatus, setPaymentStatus] = useState<any>("Unpaid");
  const [timeFormat, setTimeFormat] = useState("");
  let navigate = useNavigate();
  const params = useParams();

  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (state: any) => state.Ship,
    (state: any) => state.OilPlatform,
    (state: any) => state.Facility,
    (account: any, login: any, ships: any, platform: any, facility: any) => ({
      user: account.user,
      tokens: login.tokens,
      loggedInUser: login.user,
      company: login.user?.company || {},
      shipList: ships.shipList,
      platformList: platform.oilPlatformList,
      portReceptionsList: facility.portReceptionsList,
      portOfOperationsList: facility.portOfOperationsList,
      wasteManagementFacilities: facility.wastesList,
    })
  );

  const {
    portOfOperationsList,
    portReceptionsList,
    wasteManagementFacilities,
    company,
    shipList,
    platformList,
    tokens,
  } = useSelector(selectAccountAndLogin);

  console.log("company", company);

  const [state, setState] = useState<any>({});

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) ? child : null;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setState(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          // toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [dispatch, navigate, params.id, tokens]);

  useEffect(() => {
    if (state?.company?.companyType === CompanyTypeEnum.OIL_AND_GAS_PLATFORM) {
      dispatch(onGetOilPlatforms());
      dispatch(onGetWasteManagements(`category='all'`));
    } else {
      dispatch(onGetShips());
      dispatch(onGetPortOfOperations());
      // dispatch(onGetPortReceptions());
    }
  }, [state?.company?.companyType, dispatch]);

  const initialWasteDetails = {
    id: "randomid",
    wasteType: "",
    name: "waste",
    rate: "",
    quantity: 0,
    tonnage: 100,
  };
  const [wasteTypes, setWasteTypes] = useState<{ [key: string]: any }>({});
  const [wasteDetails, setWasteDetails] = useState<{ [key: string]: any }>({});

  const [count, setCount] = useState<number>(0);
  const [rate, setRate] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [dis, setDis] = useState<number>(0);
  const [charge, setCharge] = useState<number>(0);

  const getSubtotal = (values: any) => {
    if (state?.company?.companyType !== CompanyTypeEnum.SHIPMENT) {
      return values.wasteDeclarations && values.wasteDeclarations[0]
        ? values.wasteDeclarations.reduce(
            (total: number, prev: any) => total + prev.rate * prev.quantity,
            0
          )
        : 0;
    } else {
      const shipValue = shipList.find((ship: any) => ship.id === values.shipId);
      console.log("getSubtotal", shipValue?.grossTonnage);
      return shipValue ? shipValue?.grossTonnage * 2400 : 0;
    }
  };

  const getTaxAmount = (values: any) => {
    const taxValue = 0.075;
    return taxValue * getSubtotal(values);
    // return values.wasteDeclarations && values.wasteDeclarations[0]
    //   ? values.wasteDeclarations.reduce(
    //       (total: number, prev: any) =>
    //         total + taxValue * prev.rate * prev.quantity,
    //       0
    //     )
    //   : 0;
  };

  const getDiscount = (values: any) => {
    const discountValue = 0;
    return discountValue * getSubtotal(values);
    // return discountValue * values.wasteDeclarations && values.wasteDeclarations[0]
    //   ? values.wasteDeclarations.reduce(
    //       (total: number, prev: any) =>
    //         total + discountValue * prev.rate * prev.quantity,
    //       0
    //     )
    //   : 0;
  };

  const getCharges = (values: any) => {
    return values.wasteDeclarations && values.wasteDeclarations[0] ? 65 : 0;
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
  //         { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
  //       );
  //       console.log("response", JSON.parse(JSON.stringify(response)));
  //       setState(JSON.parse(JSON.stringify(response)));
  //     } catch (error: any) {
  //       console.error("error", error);
  //       console.log("typeof error", typeof error);
  //       if (typeof error == "string" && error.includes("401")) {
  //         const message = "Invalid or expired token";
  //         console.log("message", message);
  //         toast.error(message, { autoClose: 2000 });

  //         navigate(process.env.PUBLIC_URL + "/logout");
  //       }
  //     }
  //   };

  //   getData();
  // }, [dispatch, navigate, params.id, tokens]);

  useEffect(() => {
    let tax = 0.075 * rate * count;
    let dis = 0 * rate * count;

    if (rate && count) {
      setCharge(65);
    } else {
      setCharge(0);
    }
    setTax(tax);
    setDis(dis);
  }, [rate, count]);

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      postalcode: "",
      companyAddress: "16 Amodu Ojikutu Street, Victoria Island, Lagos",
      email: "",
      wasteDeclarations: state?.wasteDeclaration || [
        {
          id: "randomid",
          wasteType: "",
          name: "waste",
          rate: "",
          tonnage: 100,
          quantity: 1,
        },
      ],
      website: "https://mwp.ng",
      // contact: "",
      // invoiceId: "",
      date: "",
      name: "",
      pooId: state?.portOfOperation?.id || "",
      prfId: state?.portReceptionFacility?.id || "",
      wmpId: state?.wasteManagementProcessingFacility?.id || "",
      shipId: state?.ship?.id || "",
      platformId: state?.oilPlatform?.id || "",
      // status: "",
      // country: "",
      // amount: "",
      billing_address: "",
      billing_phone: "",
      billing_taxno: "",
      shipping_name: "",
      shipping_address: "",
      shipping_phone: "",
      shipping_taxno: "",
      product_name: "",
      estimatedArrival: "",
      estimatedArrivalTime: "1232",
      ...state,
    },
    validationSchema: Yup.object({
      // wasteDeclarations: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       wasteType: Yup.string().required("enter field"),
      //       rate: Yup.string().required("enter field"),
      //       quantity: Yup.string().required("enter field"),
      //       // Rest of your amenities object properties
      //     })
      //   )
      //   .required("Must have waste Details") // these constraints are shown if and only if inner constraints are satisfied
      //   .min(1, "Minimum of 1 waste Details"),
      // companyAdrees: Yup.string().required("Please Enter a Company Address"),
      // email: Yup.string().required("Please Enter a Email"),
      // postalcode: Yup.string().required("Please Enter a Postal Code"),
      // website: Yup.string().required("Please Enter a website"),
      // contact: Yup.string().required("Please Enter a contact number"),
      // invoiceId: Yup.string().required("This field is required"),
      // name: Yup.string().required("Please Enter a Full name"),
      ...(state?.company?.companyType === CompanyTypeEnum.OIL_AND_GAS_PLATFORM
        ? {
            platformId: Yup.string().required("Please Enter a Platform"),
            wmpId: Yup.string().required("Please Enter a Waste facility"),
          }
        : {
            pooId: Yup.string().required("Please Enter a Port of Operation"),
            shipId: Yup.string().required("Please Enter a Ship"),
            prfId: Yup.string().required("Please Enter a Waste facility"),
          }),
    }),
    onSubmit: (values: any) => {
      console.log("onSubmit values", values);
      const {
        name,
        pooId,
        prfId,
        wmpId,
        shipId,
        platformId,
        wasteDetails,
        id,
      } = values;
      const { wasteDeclarations, estimatedArrivalTime, estimatedArrival } =
        values;
      dispatch(
        onEditClientWasteNotifications({
          id,
          name: name || `Waste notification on ${new Date()}`,
          wasteDetails:
            name || wasteDetails || `Waste notification on ${new Date()}`,
          pooId,
          prfId,
          wmpId,
          shipId,
          platformId,
          wasteDeclarations,
          estimatedArrival: estimatedArrival[0],
          estimatedArrivalTime,
          bill: {
            ...state?.bill,
            billNumber: state?.bill?.billNumber || "default-0",
            totalTransactionValue: getSubtotal(values),
            totalTax: getTaxAmount(values),
            amountDue: getSubtotal(values),
            discount: getDiscount(values),
          },
        })
      );
      // validation.resetForm();
    },
  });

  useEffect(() => {
    if (state?.company?.companyType === CompanyTypeEnum.OIL_AND_GAS_PLATFORM) {
    } else {
      const pooId = `pooId=${validation.values.pooId}`;
      dispatch(onGetPortReceptions(pooId));
    }
  }, [state?.company?.companyType, dispatch, validation.values.pooId]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/waste-type`
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        const responseBody = JSON.parse(JSON.stringify(response));
        const newResponse: { [key: string]: any } = {};
        const newResponseBody: { [key: string]: any } = {};

        if (responseBody) {
          Object.keys(responseBody).filter((res) => {
            const eachWasteType = WasteType[res as WasteTypeKey];
            if (
              eachWasteType &&
              eachWasteType.category.includes(company?.companyType)
            ) {
              newResponse[res] = responseBody[res].name;
            }
            return res;
          });
        }
        setWasteTypes(JSON.parse(JSON.stringify(newResponse)));
        setWasteDetails(JSON.parse(JSON.stringify(newResponseBody)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [navigate]);

  console.log("validation.errors", validation.errors);
  console.log("validation.values", validation.values);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            pageTitle="COMPLIANCE INSPECTION REPORT"
            title="COMPLIANCE INSPECTION REPORT"
          />
          <Row className="justify-content-center">
            <Col xxl={9}>
              <Card>
                <Form
                  className="needs-validation"
                  id="invoice_form"
                  onSubmit={validation.handleSubmit}
                >
                  <Card.Body className="border-bottom border-bottom-dashed p-4">
                    <Row>
                      <Col lg={6}>
                        <Row className="g-3">
                          {/* <Col lg={8} sm={6}>
                            <label htmlFor="invoicenoInput">
                              Waste Notification Ref No
                            </label>
                            <input
                              type="text"
                              className="form-control bg-light border-0"
                              id="invoicenoInput"
                              placeholder="WasteNotification No"
                              value="#VL25000355"
                              readOnly
                            />
                          </Col> */}
                          {state?.company?.companyType ===
                          CompanyTypeEnum.SHIPMENT ? (
                            <Fragment>
                              <Col lg={8} sm={6}>
                                <h6 className="mb-3">Vessel Details</h6>
                                <div className="mb-3">
                                  <label
                                    htmlFor="shipId"
                                    className="form-label"
                                  >
                                    Ship
                                  </label>

                                  {company?.companyType ===
                                  CompanyTypeEnum.SHIPMENT ? (
                                    <Form.Select
                                      id="paymentType"
                                      name="shipId"
                                      placeholder="Enter Ship"
                                      value={validation.values.shipId || ""}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      isInvalid={!!validation.errors.shipId}
                                    >
                                      {/* <option disabled>Select Ship</option> */}
                                      <option value="">Select Ship</option>
                                      {shipList &&
                                        shipList.map((el: any) => (
                                          <option key={el.id} value={el.id}>
                                            {el.name}
                                          </option>
                                        ))}
                                    </Form.Select>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="invoicenoInput"
                                      placeholder="WasteNotification No"
                                      value={state?.ship?.name}
                                      readOnly
                                    />
                                  )}
                                  {validation.errors.shipId &&
                                  validation.touched.shipId ? (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {validation.errors.shipId}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg={8} sm={6}>
                                <Row>
                                  <Col lg={6} sm={6}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="grossTonnage"
                                        className="form-label"
                                      >
                                        Gross Tonnage
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control bg-light border-0"
                                        id="grossTonnage"
                                        placeholder="WasteNotification No"
                                        value={state?.ship?.grossTonnage}
                                        readOnly
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6} sm={6}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="flag"
                                        className="form-label"
                                      >
                                        Flag
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control bg-light border-0"
                                        id="flag"
                                        placeholder="WasteNotification No"
                                        value={state?.ship?.flag}
                                        readOnly
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={8} sm={6}>
                                <div className="mb-3">
                                  <label htmlFor="pooId" className="form-label">
                                    Port
                                  </label>
                                  {company?.companyType ===
                                  CompanyTypeEnum.SHIPMENT ? (
                                    <Form.Select
                                      id="paymentType"
                                      name="pooId"
                                      placeholder="Enter Ship"
                                      value={validation.values.pooId || ""}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      isInvalid={!!validation.errors.pooId}
                                    >
                                      {/* <option disabled>Select Ship</option> */}
                                      <option value="">Select Port</option>
                                      {portOfOperationsList &&
                                        portOfOperationsList.map((el: any) => (
                                          <option key={el.id} value={el.id}>
                                            {el.name}
                                          </option>
                                        ))}
                                    </Form.Select>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="invoicenoInput"
                                      placeholder="WasteNotification No"
                                      value={state?.portOfOperation?.name}
                                      readOnly
                                    />
                                  )}
                                  {validation.errors.pooId &&
                                  validation.touched.pooId ? (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {validation.errors.pooId}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                              {validation.values.pooId !== "" ? (
                                <Col lg={8} sm={6}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="prfId"
                                      className="form-label"
                                    >
                                      Port Reception
                                    </label>

                                    {company?.companyType ===
                                    CompanyTypeEnum.SHIPMENT ? (
                                      <Form.Select
                                        id="paymentType"
                                        name="prfId"
                                        placeholder="Enter Port Reception Facility"
                                        value={validation.values.prfId || ""}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        isInvalid={!!validation.errors.prfId}
                                      >
                                        {/* <option disabled>Select Port Reception Facility</option> */}
                                        <option value="">
                                          Select Port Reception Facility
                                        </option>
                                        {portReceptionsList &&
                                          portReceptionsList.map((el: any) => (
                                            <option key={el.id} value={el.id}>
                                              {el.name}
                                            </option>
                                          ))}
                                      </Form.Select>
                                    ) : (
                                      <input
                                        type="text"
                                        className="form-control bg-light border-0"
                                        id="invoicenoInput"
                                        placeholder="WasteNotification No"
                                        value={
                                          state?.portReceptionFacility?.name
                                        }
                                        readOnly
                                      />
                                    )}
                                    {validation.errors.prfId &&
                                    validation.touched.prfId ? (
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        {validation.errors.prfId}
                                      </Form.Control.Feedback>
                                    ) : null}
                                  </div>
                                </Col>
                              ) : null}
                            </Fragment>
                          ) : null}
                          {state?.company?.companyType ===
                          CompanyTypeEnum.OIL_AND_GAS_PLATFORM ? (
                            <Fragment>
                              <Col lg={8} sm={6}>
                                <h6 className="mb-3">Platform Details</h6>
                                <div className="mb-3">
                                  <label
                                    htmlFor="platformId"
                                    className="form-label"
                                  >
                                    Platform
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="platformId"
                                    placeholder="Enter Platform"
                                    value={validation.values.platformId || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={!!validation.errors.platformId}
                                  >
                                    {/* <option disabled>Select Platform</option> */}
                                    <option value="">Select Platform</option>
                                    {platformList &&
                                      platformList.map((el: any) => (
                                        <option key={el.id} value={el.id}>
                                          {el.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {validation.errors.platformId &&
                                  validation.touched.platformId ? (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {validation.errors.platformId}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg={8} sm={6}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="platformId"
                                    className="form-label"
                                  >
                                    Waste Management Processing facility
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="wmpId"
                                    placeholder="Enter Platform"
                                    value={validation.values.wmpId || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={!!validation.errors.wmpId}
                                  >
                                    {/* <option disabled>Select Waste Facility</option> */}
                                    <option value="">Select Waste Facility</option>
                                    {wasteManagementFacilities &&
                                      wasteManagementFacilities.map(
                                        (el: any) => (
                                          <option key={el.id} value={el.id}>
                                            {el.name}
                                          </option>
                                        )
                                      )}
                                  </Form.Select>
                                  {validation.errors.wmpId &&
                                  validation.touched.wmpId ? (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {validation.errors.wmpId}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Fragment>
                          ) : null}

                          <Col lg={8} sm={6}>
                            <div>
                              <label htmlFor="date-field">
                                Proposed Collection Date
                              </label>
                              <Flatpickr
                                name="estimatedArrival"
                                id="date-field"
                                className="form-control bg-light border-0"
                                placeholder="Proposed Collection Date"
                                readonly
                                options={{
                                  altInput: true,
                                  altFormat: "d M, Y",
                                  dateFormat: "d M, Y",
                                }}
                                // onChange={dateformate}
                                onChange={(e: any) =>
                                  validation.handleChange({
                                    target: {
                                      value: e,
                                      name: "estimatedArrival",
                                    },
                                  })
                                }
                                value={validation.values.estimatedArrival || ""}
                              />
                              {validation.touched.estimatedArrival &&
                              validation.errors.estimatedArrival ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.estimatedArrival}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                          </Col>

                          {/* <Col lg={8} sm={6}>
                            <div>
                              <label
                                htmlFor="cleave-time-format"
                                className="form-label"
                              >
                                Estimated Time of Arrival
                              </label>
                              <Cleave
                                placeholder="hh:mm"
                                options={{
                                  time: true,
                                  timePattern: ["h", "m"],
                                }}
                                name="estimatedArrivalTime"
                                value={validation.values.estimatedArrivalTime}
                                // onChange={(e: any) => onTimeFormatChange(e)}
                                onChange={(e: any) =>
                                  validation.handleChange({
                                    target: {
                                      value: e.target.rawValue,
                                      name: "estimatedArrivalTime",
                                    },
                                  })
                                }
                                className="form-control bg-light border-0"
                              />
                            </div>
                          </Col> */}

                          <Col lg={12} sm={6}>
                            <h6 className="my-3">Marpol Annex 1</h6>
                            <div className="mb-3">
                              <h6 className="text-primary">
                                <u>
                                  INTERNATIONAL OIL POLLUTION PREVENTION
                                  CERTIFICATE
                                </u>
                              </h6>
                            </div>
                            <Row>
                              <Col lg={8} sm={6}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="grossTonnage"
                                    className="form-label"
                                  >
                                    Sighted on Board
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="sightedOnBoard"
                                    placeholder="Enter Ship"
                                    value={
                                      validation.values.sightedOnBoard || ""
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={
                                      !!validation.errors.sightedOnBoard
                                    }
                                  >
                                    <option value="">SIGHTED ON BOARD</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                    <option value="NOT APPLICABLE">
                                      NOT APPLICABLE
                                    </option>
                                  </Form.Select>
                                </div>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="grossTonnage"
                                        className="form-label"
                                      >
                                        Issued By
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control bg-light border-0"
                                        id="grossTonnage"
                                        placeholder="Issued By"
                                        value={
                                          state?.ship
                                            ?.pollutionPreventionCertificate
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="grossTonnage"
                                        className="form-label"
                                      >
                                        Last Inspected
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control bg-light border-0"
                                        id="grossTonnage"
                                        placeholder="Jimmy ALG"
                                        value={
                                          state?.ship
                                            ?.pollutionPreventionCertificate
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="mb-3">
                                  <label
                                    htmlFor="grossTonnage"
                                    className="form-label"
                                  >
                                    Emergency Plan Sighted on Board
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="sightedOnBoard"
                                    placeholder="Enter Ship"
                                    value={
                                      validation.values.sightedOnBoard || ""
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={
                                      !!validation.errors.sightedOnBoard
                                    }
                                  >
                                    <option value="">
                                      Emergency Plan Sighted on Board
                                    </option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                    <option value="NOT APPLICABLE">
                                      NOT APPLICABLE
                                    </option>
                                  </Form.Select>
                                </div>
                              </Col>
                            </Row>
                            <div className="mb-3">
                              <h6 className="text-primary">
                                <u>MARITIME AUTHORITY APPROVING PLAN</u>
                              </h6>
                            </div>
                            <Row>
                              <Col lg={8} sm={6}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="grossTonnage"
                                    className="form-label"
                                  >
                                    Oil record book part 1{" "}
                                    <small>(VESSELS)</small> and part 2{" "}
                                    <small>(TANKERS)</small> Sighted
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="oilRecordBookSightedOnBoard"
                                    placeholder="Enter Ship"
                                    value={
                                      validation.values
                                        .oilRecordBookSightedOnBoard || ""
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={
                                      !!validation.errors
                                        .oilRecordBookSightedOnBoard
                                    }
                                  >
                                    <option value="">SIGHTED ON BOARD</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                    <option value="NOT APPLICABLE">
                                      NOT APPLICABLE
                                    </option>
                                  </Form.Select>
                                </div>
                                <Row>
                                  <Col>
                                    <div>
                                      <label htmlFor="date-field">
                                        Date of last entry
                                      </label>
                                      <Flatpickr
                                        name="estimatedArrival"
                                        id="date-field"
                                        className="form-control bg-light border-0"
                                        placeholder="Proposed Collection Date"
                                        readonly
                                        options={{
                                          altInput: true,
                                          altFormat: "d M, Y",
                                          dateFormat: "d M, Y",
                                        }}
                                        // onChange={dateformate}
                                        onChange={(e: any) =>
                                          validation.handleChange({
                                            target: {
                                              value: e,
                                              name: "estimatedArrival",
                                            },
                                          })
                                        }
                                        value={
                                          validation.values.estimatedArrival ||
                                          ""
                                        }
                                      />
                                      {validation.touched.estimatedArrival &&
                                      validation.errors.estimatedArrival ? (
                                        <Form.Control.Feedback type="invalid">
                                          {validation.errors.estimatedArrival}
                                        </Form.Control.Feedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="grossTonnage"
                                        className="form-label"
                                      >
                                        Details
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control bg-light border-0"
                                        id="grossTonnage"
                                        placeholder="Jimmy ALG"
                                        value={
                                          state?.ship
                                            ?.pollutionPreventionCertificate
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          {/* <div>
                              <label htmlFor="date-field">
                                Estimated Time of Arrival
                              </label>
                              <Flatpickr
                                name="date"
                                id="date-field"
                                className="form-control bg-light border-0"
                                placeholder="Proposed Collection Date"
                                options={{
                                  altInput: true,
                                  altFormat: "d M, Y",
                                  dateFormat: "d M, Y",
                                }}
                                onChange={dateformate}
                                value={validation.values.date || ""}
                              />
                              {validation.touched.date &&
                              validation.errors.date ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.date}
                                </Form.Control.Feedback>
                              ) : null}
                            </div> */}

                          {/* <Col lg={8} sm={6}>
                            <label htmlFor="choices-payment-status">
                              Location of Waste Collection
                            </label>
                            <div className="input-light">
                              <Select
                                className="bg-light border-0"
                                value={paymentStatus}
                                onChange={handlePaymentStatus}
                                options={paymentStatusOptions}
                                name="choices-single-default"
                                id="idStatus"
                                placeholder="Location of Waste"
                              />
                            </div>
                          </Col> */}

                          {/* <Col lg={8} sm={6}>
                            <div className="mb-3">
                              <label htmlFor="date-field">
                                Convenient Date
                              </label>
                              <Flatpickr
                                name="date"
                                id="date-field"
                                className="form-control bg-light border-0"
                                placeholder="Proposed Collection Date"
                                options={{
                                  altInput: true,
                                  altFormat: "d M, Y",
                                  dateFormat: "d M, Y",
                                }}
                                onChange={dateformate}
                                value={validation.values.date || ""}
                              />
                              {validation.touched.date &&
                              validation.errors.date ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.date}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>

                            <div>
                              <label
                                htmlFor="cleave-time-format"
                                className="form-label"
                              >
                                Convenient Time
                              </label>
                              <Cleave
                                placeholder="hh:mm"
                                options={{
                                  time: true,
                                  timePattern: ["h", "m"],
                                }}
                                value={timeFormat}
                                onChange={(e: any) => onTimeFormatChange(e)}
                                className="form-control bg-light border-0"
                              />
                            </div>
                          </Col> */}

                          <Col lg={8} sm={6}>
                            <label htmlFor="choices-payment-status">
                              Payment Status
                            </label>
                            <div className="input-light">
                              <input
                                className="form-control bg-light border-0"
                                type="text"
                                id="paymentStatus"
                                placeholder="Payment Status"
                                value={state.paymentStatus}
                                readOnly
                              />
                              {/* <Select
                                className="bg-light border-0"
                                value={paymentStatus}
                                onChange={handlePaymentStatus}
                                options={paymentStatusOptions}
                                name="choices-single-default"
                                id="idStatus"
                                placeholder="Payment Method"
                                disabled
                                readOnly
                              /> */}
                            </div>
                          </Col>

                          {/* <Col lg={8} sm={6}>
                            <div>
                              <label htmlFor="totalamountInput">
                                Total Amount
                              </label>
                              <Form.Control
                                className="bg-light border-0"
                                type="text"
                                id="totalamountInput"
                                placeholder="₦0.00"
                                readOnly
                                value={
                                  "₦" +
                                  Math.round(
                                    getSubtotal(validation.values) +
                                      getTaxAmount(validation.values) +
                                      getCharges(validation.values) -
                                      getDiscount(validation.values)
                                  )
                                }
                              />
                            </div>
                          </Col> */}
                        </Row>
                      </Col>

                      <Col lg={4} className="ms-auto">
                        <div className="profile-user mx-auto  mb-3">
                          <input
                            id="profile-img-file-input"
                            type="file"
                            className="profile-img-file-input"
                          />
                          <label
                            htmlFor="profile-img-file-input"
                            className="d-block"
                            tabIndex={0}
                          >
                            <span
                              className="overflow-hidden border border-dashed d-flex align-items-center justify-content-center rounded"
                              style={{ height: "60px", width: "256px" }}
                            >
                              <img
                                src={logoDark}
                                className="card-logo card-logo-dark user-profile-image img-fluid"
                                alt="logo dark"
                              />
                              <img
                                src={logoLight}
                                className="card-logo card-logo-light user-profile-image img-fluid"
                                alt="logo light"
                              />
                            </span>
                          </label>
                        </div>

                        <div>
                          <label htmlFor="companyAddress">Address</label>
                        </div>
                        <div className="mb-2">
                          <Form.Control
                            id="companyAddress"
                            as="textarea"
                            // type="textarea"
                            className="bg-light border-0"
                            name="companyAddress"
                            value={validation.values.companyAddress || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="CompanyAddress"
                            readOnly
                            isInvalid={
                              validation.errors.companyAddress &&
                              validation.touched.companyAddress
                                ? true
                                : false
                            }
                          />
                          {validation.errors.companyAddress &&
                          validation.touched.companyAddress ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.companyAddress}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                        {/* <div className="mb-2">
                          <Form.Control
                            id="companyaddpostalcode"
                            className="bg-light border-0"
                            name="postalcode"
                            value={validation.values.postalcode || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Postal Code"
                            isInvalid={
                              validation.errors.postalcode &&
                              validation.touched.postalcode
                                ? true
                                : false
                            }
                          />
                          {validation.errors.postalcode &&
                          validation.touched.postalcode ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.postalcode}
                            </Form.Control.Feedback>
                          ) : null}
                        </div> */}

                        {/* <div className="mb-2">
                          <Form.Control
                            type="email"
                            className="bg-light border-0"
                            id="companyEmail"
                            name="email"
                            value={validation.values.email || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Email Address"
                            isInvalid={
                              validation.errors.email &&
                              validation.touched.email
                                ? true
                                : false
                            }
                          />
                          {validation.errors.email &&
                          validation.touched.email ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.email}
                            </Form.Control.Feedback>
                          ) : null}
                        </div> */}
                        <div className="mb-2">
                          <Form.Control
                            type="text"
                            className="bg-light border-0"
                            id="companyWebsite"
                            name="website"
                            readOnly
                            value={validation.values.website || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Website"
                            isInvalid={
                              validation.errors.website &&
                              validation.touched.website
                                ? true
                                : false
                            }
                          />
                          {validation.errors.website &&
                          validation.touched.website ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.website}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                        <div className="mb-2">
                          <Form.Control
                            type="text"
                            data-plugin="cleave-phone"
                            className="bg-light border-0"
                            id="compnayContactno"
                            name="contact"
                            value={validation.values.contact || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Contact No"
                            isInvalid={
                              validation.errors.contact &&
                              validation.touched.contact
                                ? true
                                : false
                            }
                          />
                          {validation.errors.contact &&
                          validation.touched.contact ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.contact}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  {/* <Card.Body className="p-4 border-top border-top-dashed">
                    <Row>
                      <Col lg={4} sm={6}>
                        <div>
                          <label
                            htmlFor="billingName"
                            className="text-muted text-uppercase fw-semibold"
                          >
                            Billing Address
                          </label>
                        </div>
                        <div className="mb-2">
                          <Form.Control
                            type="text"
                            className="bg-light border-0"
                            id="billingName"
                            name="name"
                            value={validation.values.name || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Full Name"
                            isInvalid={
                              validation.errors.name && validation.touched.name
                                ? true
                                : false
                            }
                          />
                          {validation.errors.name && validation.touched.name ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.name}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                        <div className="mb-2">
                          <Form.Control
                            as="textarea"
                            type="textarea"
                            className="bg-light border-0"
                            id="billingAddress"
                            name="billing_address"
                            value={validation.values.billing_address || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            rows={3}
                            placeholder="Address"
                            isInvalid={
                              validation.errors.billing_address &&
                              validation.touched.billing_address
                                ? true
                                : false
                            }
                          />
                          {validation.errors.billing_address &&
                          validation.touched.billing_address ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.billing_address}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                        <div className="mb-2">
                          <Form.Control
                            type="text"
                            data-plugin="cleave-phone"
                            className="bg-light border-0"
                            id="billingPhoneno"
                            name="billing_phone"
                            value={validation.values.billing_phone || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="(123)456-7890"
                            isInvalid={
                              validation.errors.billing_phone &&
                              validation.touched.billing_phone
                                ? true
                                : false
                            }
                          />
                          {validation.errors.billing_phone &&
                          validation.touched.billing_phone ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.billing_phone}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Form.Control
                            type="text"
                            id="billingTaxno"
                            className="bg-light border-0"
                            name="billing_taxno"
                            value={validation.values.billing_taxno || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Tax Number"
                            isInvalid={
                              validation.errors.billing_taxno &&
                              validation.touched.billing_taxno
                                ? true
                                : false
                            }
                          />
                          {validation.errors.billing_taxno &&
                          validation.touched.billing_taxno ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.billing_taxno}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="same"
                            name="same"
                          />
                          <label className="form-check-label" htmlFor="same">
                            Will your Billing and Shipping address same?
                          </label>
                        </div>
                      </Col>

                      <Col sm={6} className="ms-auto">
                        <Row>
                          <div className="col-lg-8">
                            <div>
                              <label
                                htmlFor="shippingName"
                                className="text-muted text-uppercase fw-semibold"
                              >
                                Shipping Address
                              </label>
                            </div>
                            <div className="mb-2">
                              <Form.Control
                                type="text"
                                id="shippingName"
                                className="bg-light border-0"
                                name="shipping_name"
                                value={validation.values.shipping_name || ""}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                placeholder="Full Name"
                                isInvalid={
                                  validation.errors.shipping_name &&
                                  validation.touched.shipping_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.shipping_name &&
                              validation.touched.shipping_name ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.shipping_name}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                            <div className="mb-2">
                              <Form.Control
                                as="textarea"
                                type="textarea"
                                className="bg-light border-0"
                                id="shippingAddress"
                                rows={3}
                                name="shipping_address"
                                value={validation.values.shipping_address || ""}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                placeholder="Address"
                                isInvalid={
                                  validation.errors.shipping_address &&
                                  validation.touched.shipping_address
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.shipping_address &&
                              validation.touched.shipping_address ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.shipping_address}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                            <div className="mb-2">
                              <Form.Control
                                type="text"
                                data-plugin="cleave-phone"
                                className="bg-light border-0"
                                id="shippingPhoneno"
                                name="shipping_phone"
                                value={validation.values.shipping_phone || ""}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                placeholder="(123)456-7890"
                                isInvalid={
                                  validation.errors.shipping_phone &&
                                  validation.touched.shipping_phone
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.shipping_phone &&
                              validation.touched.shipping_phone ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.shipping_phone}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                            <div>
                              <Form.Control
                                type="text"
                                id="shippingTaxno"
                                placeholder="Tax Number"
                                className="bg-light border-0"
                                name="shipping_taxno"
                                value={validation.values.shipping_taxno || ""}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                isInvalid={
                                  validation.errors.shipping_taxno &&
                                  validation.touched.shipping_taxno
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.shipping_taxno &&
                              validation.touched.shipping_taxno ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.shipping_taxno}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body> */}
                  <Card.Body className="p-4">
                    <div className="table-responsive">
                      <table className="invoice-table table table-borderless table-nowrap mb-0">
                        <thead className="align-middle">
                          <tr className="table-active">
                            <th scope="col" style={{ width: "50px" }}>
                              #
                            </th>
                            <th scope="col">Waste Details</th>
                            <th scope="col" style={{ width: "130px" }}>
                              {state?.company?.companyType !==
                              CompanyTypeEnum.SHIPMENT ? (
                                <div className="d-flex currency-select input-light align-items-center">
                                  Rate / Unit
                                  {/* <Form.Select
                                    className="form-selectborder-0 bg-light border-0"
                                    id="choices-payment-currency"
                                  >
                                    <option value="₦">(₦)</option>
                                    <option value="$">($)</option>
                                  <option value="£">(£)</option>
                                  <option value="₹">(₹)</option>
                                  <option value="€">(€)</option>
                                  </Form.Select> */}
                                </div>
                              ) : (
                                <div className="d-flex currency-select input-light align-items-center">
                                  Unit
                                </div>
                              )}
                            </th>
                            <th scope="col" style={{ width: "120px" }}>
                              Quantity
                            </th>

                            <th
                              scope="col"
                              className="text-end"
                              style={{ width: "150px" }}
                            >
                              {state?.company?.companyType !==
                              CompanyTypeEnum.SHIPMENT
                                ? "Amount"
                                : null}
                            </th>
                            <th
                              scope="col"
                              className="text-end"
                              style={{ width: "105px" }}
                            ></th>
                          </tr>
                        </thead>
                        <FormikProvider value={validation}>
                          <FieldArray
                            name="wasteDeclarations"
                            render={(arrayHelpers: any) => (
                              <Fragment>
                                <tbody id="newlink">
                                  {console.log(
                                    "validation.values.wasteDeclarations",
                                    validation.values.wasteDeclarations
                                  )}
                                  {validation.values.wasteDeclarations.map(
                                    (wd: any, index: number) => (
                                      <Fragment>
                                        {validation.values.wasteDeclarations &&
                                        validation.values.wasteDeclarations
                                          .length > 0 ? (
                                          <tr id={wd.id} className="product">
                                            <th
                                              scope="row"
                                              className="product-id"
                                            >
                                              {index + 1}
                                            </th>
                                            <td className="text-start">
                                              <div className="mb-2">
                                                <Form.Select
                                                  // type="text"
                                                  id="productName-1"
                                                  className="bg-light border-0"
                                                  placeholder="Waste Name"
                                                  name={`wasteDeclarations.${index}.wasteType`}
                                                  // value={validation.values[index].wasteType || ""}
                                                  value={wd.wasteType || ""}
                                                  // value={validation.values.product_name || ""}
                                                  onBlur={validation.handleBlur}
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                >
                                                  <option defaultValue="">
                                                    Select Waste Type
                                                  </option>
                                                  {Object.keys(wasteTypes)?.map(
                                                    (wd) => (
                                                      <option
                                                        value={wasteTypes[wd]}
                                                      >
                                                        {wasteTypes[wd]}
                                                      </option>
                                                    )
                                                  )}
                                                </Form.Select>
                                                {validation.errors
                                                  .wasteDeclarations &&
                                                validation.errors
                                                  .wasteDeclarations[index] &&
                                                validation.touched
                                                  .wasteDeclarations[index]
                                                  .wasteType ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .wasteDeclarations[
                                                        index
                                                      ].wasteType
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              {/* <textarea
                                                className="form-control bg-light border-0"
                                                id="productDetails-1"
                                                rows={2}
                                                placeholder="Waste Details"
                                              ></textarea> */}
                                            </td>
                                            <td>
                                              <Fragment>
                                                <Form.Control
                                                  // type="number"
                                                  type="text"
                                                  // name={`wasteDeclarations.${index}.rate`}
                                                  className="product-price bg-light border-0"
                                                  placeholder="0.00"
                                                  id="productRate-1"
                                                  step="0.01"
                                                  // value={wd.rate || ""}
                                                  value={
                                                    company?.companyType !==
                                                    CompanyTypeEnum.SHIPMENT
                                                      ? wd.rate
                                                        ? wd.rate +
                                                          " / " +
                                                          wasteDetails[
                                                            wd["wasteType"]
                                                          ].measurement
                                                        : ""
                                                      : wd.rate ? wasteDetails[
                                                          wd["wasteType"]
                                                        ].measurement : ""
                                                  }
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  readOnly
                                                />
                                                {validation.errors
                                                  .wasteDeclarations &&
                                                validation.errors
                                                  .wasteDeclarations[index] &&
                                                validation.touched
                                                  .wasteDeclarations[index]
                                                  .rate ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .wasteDeclarations[
                                                        index
                                                      ].rate
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </Fragment>
                                            </td>
                                            <td>
                                              <div className="input-step">
                                                <Button
                                                  type="button"
                                                  className="minus-btn"
                                                  onClick={
                                                    () =>
                                                      arrayHelpers.replace(
                                                        index,
                                                        {
                                                          ...wd,
                                                          quantity:
                                                            wd.quantity > 0
                                                              ? wd.quantity - 1
                                                              : wd.quantity,
                                                        }
                                                      )
                                                    // setCount(
                                                    //   count > 0
                                                    //     ? count - 1
                                                    //     : count
                                                    // )
                                                  }
                                                >
                                                  –
                                                </Button>
                                                <Form.Control
                                                  type="number"
                                                  name={`wasteDeclarations.${index}.quantity`}
                                                  className="product-quantity"
                                                  id="product-qty-1"
                                                  value={wd.quantity}
                                                  step="0.01"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  // readOnly
                                                />
                                                <Button
                                                  type="button"
                                                  className="plus-btn"
                                                  onClick={
                                                    () =>
                                                      arrayHelpers.replace(
                                                        index,
                                                        {
                                                          ...wd,
                                                          quantity:
                                                            wd.quantity + 1,
                                                        }
                                                      )
                                                    // setCount(count + 1)
                                                  }
                                                >
                                                  +
                                                </Button>
                                              </div>
                                            </td>
                                            <td className="text-end">
                                              {state?.company?.companyType !==
                                              CompanyTypeEnum.SHIPMENT ? (
                                                <div>
                                                  <Form.Control
                                                    type="text"
                                                    className="product-line-price"
                                                    id="productPrice-1"
                                                    placeholder="₦0.00"
                                                    value={
                                                      "₦" +
                                                      wd.rate * wd.quantity
                                                    }
                                                    readOnly
                                                  />
                                                </div>
                                              ) : null}
                                            </td>
                                            <td className="product-removal">
                                              <Link
                                                onClick={() =>
                                                  // deletelItem(wd.id)
                                                  arrayHelpers.remove(index)
                                                }
                                                to="#"
                                                className="btn btn-success"
                                              >
                                                Delete
                                              </Link>
                                            </td>
                                          </tr>
                                        ) : null}
                                      </Fragment>
                                    )
                                  )}
                                </tbody>
                                <tbody>
                                  <tr id="newForm" style={{ display: "none" }}>
                                    <td className="d-none" colSpan={5}>
                                      <p>Add New Form</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={5}>
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            ...initialWasteDetails,
                                            id: `${
                                              validation.values
                                                .wasteDeclarations.length + 1
                                            }`,
                                          })
                                        }
                                        id="add-item"
                                        className="btn btn-soft-secondary fw-medium"
                                      >
                                        <i className="ri-add-fill me-1 align-bottom"></i>{" "}
                                        Add Item
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr className="border-top border-top-dashed mt-2">
                                    <td
                                      colSpan={
                                        state?.company?.companyType !==
                                        CompanyTypeEnum.SHIPMENT
                                          ? 3
                                          : 2
                                      }
                                    ></td>
                                    <td
                                      colSpan={
                                        state?.company?.companyType !==
                                        CompanyTypeEnum.SHIPMENT
                                          ? 2
                                          : 3
                                      }
                                      className="p-0"
                                    >
                                      <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                                        <tbody>
                                          <tr>
                                            <th scope="row">
                                              {state?.company?.companyType ===
                                              CompanyTypeEnum.SHIPMENT
                                                ? "Environmental Protection Levy (EPL)"
                                                : "Sub Total"}
                                            </th>
                                            <td style={{ width: "150px" }}>
                                              <Form.Control
                                                type="text"
                                                id="cart-subtotal"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  getSubtotal(validation.values)
                                                  // rate * count
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">
                                              Estimated Tax (7.5%)
                                            </th>
                                            <td>
                                              <Form.Control
                                                type="text"
                                                id="cart-tax"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  getTaxAmount(
                                                    validation.values
                                                  )
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">
                                              Discount{" "}
                                              <small className="text-muted">
                                                (Mwp.ng24)
                                              </small>
                                            </th>
                                            <td>
                                              <Form.Control
                                                type="text"
                                                id="cart-discount"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  getDiscount(validation.values)
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">Shipping Charge</th>
                                            <td>
                                              <Form.Control
                                                type="text"
                                                id="cart-shipping"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  getCharges(validation.values)
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr className="border-top border-top-dashed">
                                            <th scope="row">Total Amount</th>
                                            <td>
                                              <Form.Control
                                                type="text"
                                                id="cart-total"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  Math.round(
                                                    getSubtotal(
                                                      validation.values
                                                    ) +
                                                      getTaxAmount(
                                                        validation.values
                                                      ) +
                                                      getCharges(
                                                        validation.values
                                                      ) -
                                                      getDiscount(
                                                        validation.values
                                                      )
                                                  )
                                                }
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </td>
                                  </tr>
                                </tbody>
                              </Fragment>
                            )}
                          />
                        </FormikProvider>
                      </table>
                    </div>

                    {addLayoutData(
                      [
                        CompanyTypeEnum.SHIPMENT,
                        CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                      ],
                      <div className="mt-4">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label text-muted text-uppercase fw-semibold"
                        >
                          NOTES
                        </label>
                        <Alert
                          className="form-control alert-info"
                          id="exampleFormControlTextarea1"
                          placeholder="Notes"
                        >
                          All bills are to be paid within 7 days from receipt of
                          invoice. To be paid by cheque or credit card or direct
                          payment online. If account is not paid within 7 days
                          the credits details supplied as confirmation of work
                          undertaken will be charged the agreed quoted fee noted
                          above.
                        </Alert>
                      </div>
                    )}
                    {/* <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                      <button type="submit" className="btn btn-info">
                        <i className="ri-printer-line align-bottom me-1"></i>{" "}
                        Save
                      </button>
                      <Link to="#" className="btn btn-primary">
                        <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                        Download Waste Notification
                      </Link>
                      <Link to="#" className="btn btn-danger">
                        <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                        Submit Waste notification
                      </Link>
                    </div> */}
                  </Card.Body>

                  <Card.Body className="border-bottom border-bottom-dashed p-4">
                    <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                      {/* <button type="submit" className="btn btn-info">
                        <i className="ri-printer-line align-bottom me-1"></i>{" "}
                        Save
                      </button> */}
                      {/* <Link to="#" className="btn btn-primary">
                        <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                        Download Waste Notification
                      </Link> */}
                      <button type="submit" className="btn btn-primary">
                        <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                        Confirm Waste Quantity
                      </button>
                    </div>
                  </Card.Body>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default ComplianceInspectionReport;
