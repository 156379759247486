import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Alert } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import SimpleBar from "simplebar-react";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { selectOperationalModule } from "../../slices/thunk";
import { coreOperationalModules } from "../../Common/data";
import { Navigate } from "react-router-dom";
import axios from "axios";

const API_WMP_URL = "https://api.mwp.ng";

const Home = () => {
  document.title = "Home | Mwp.ng Admin & Home";
  const dispatch = useDispatch();
  const [facilities, setFacilities] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      user: login.user,
      tokens: login.tokens,
      selected_module: login.selected_module,
    })
  );

  const { user, tokens, selected_module } = useSelector(selectAccountAndLogin);

  console.log(user)

  useEffect(() => {
    fetchFacilities();
  }, [dispatch, tokens]);

  const fetchFacilities = async () => {
    try {
      const endpoint = user?.type === 'SHIP STAKEHOLDER' ? 'ship' : 'platform';
      
      const response:any = await axios.get(`${API_WMP_URL}/api/v1/${endpoint}`, {
        headers: { Authorization: `Bearer ${tokens?.accessToken}` },
      });
      
      setFacilities(response || []);
      console.log(`${endpoint.charAt(0).toUpperCase() + endpoint.slice(1)}s:`, response);
    } catch (error) {
      console.error(`Error fetching facilities:`, error);
      setFacilities([]);
    } finally {
      setLoading(false);
    }
  };

  const shorten = (str: string, min: number) => {
    let string = "",
      newString = "",
      max = 280 - min,
      dotCount = 0,
      i = 0;
    while (i < max && dotCount < 2) {
      if (str[i] === ".") {
        string += newString + str[i];
        newString = "";
        dotCount++;
      } else if (str && str[i] !== undefined && dotCount < 3) {
        dotCount += str[i] === "." ? 1 : 0;
        newString += str[i];
      }
      i++;
    }
    return string;
  };

  const hasFullyRegisteredFacility = () => {
    return facilities.some(facility => facility.status === "In service");
  };

  const isNIMASAUser = () => {
    return user?.type === "(NIMASA) NIGERIAN MARITIME ADMINISTRATION AND SAFETY AGENCY";
  };

  const canAccessModule = (moduleId: string) => {
    if (isNIMASAUser()) {
      return true;
    }
    return moduleId === "ermcs" || hasFullyRegisteredFacility();
  };

  if (loading) {
    return (
      <div className="page-content">
        <Container fluid>
          <div className="text-center">Loading...</div>
        </Container>
      </div>
    );
  }

  if (selected_module) {
    if (!canAccessModule(selected_module)) {
      return <Navigate to="/dashboard" />;
    }

    switch (selected_module) {
      case "awms":
      case "ermcs":
        return <Navigate to="/dashboard" />;
      case "cds":
        return <div style={{ marginTop: 100 }}>Dashboard for cds</div>;
      default:
        return <Navigate to="/" />;
    }
  }

  // Filter modules for the cards section only
  const accessibleModules = coreOperationalModules
    .slice(0, 3)
    .filter((module: any) => canAccessModule(module.shortId));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Home" title={user.company.name} />
          
          {!isNIMASAUser() && !hasFullyRegisteredFacility() && (
            <Alert variant="warning" className="mb-3">
              You need at least one fully registered facility to access additional modules. Only ERMCS module is currently accessible.
            </Alert>
          )}

          <Row>
            {accessibleModules.map((operationalModule: any, key: any) => (
              <Col xl={4} key={key}>
                <Card>
                  <Card.Body
                    onClick={() => {
                      dispatch(selectOperationalModule(operationalModule.shortId));
                    }}
                    className="cursor-pointer"
                  >
                    <Row className="align-items-center gy-4">
                      <Col sm={12}>
                        <div
                          className="box"
                          style={{ maxHeight: 200, minHeight: 200 }}
                        >
                          <p className="text-muted fs-15">
                            <strong>{operationalModule.title}</strong>
                          </p>
                          <p>
                            <small>
                              {shorten(
                                operationalModule.description,
                                operationalModule.title.length
                              )}
                            </small>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <SimpleBar className="mx-n3 px-3" style={{ maxHeight: "418px" }}>
            {coreOperationalModules.map((operationalModule: any, key: any) => (
              <div key={key}>
                <div
                  className="border-bottom sales-history"
                  key={operationalModule.id}
                >
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-primary rounded-circle fs-3">
                        <i className={`${operationalModule.icon} fs-22`}></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3 overflow-hidden">
                      <h5 className="fs-15 mb-1 text-truncate">
                        {operationalModule.title}
                      </h5>
                      <p className="fs-14 text-muted text-truncate mb-0">
                        {operationalModule.date}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </SimpleBar>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Home;