import React from "react";
import BreadCrumb from "../../Common/BreadCrumb";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import nimasaLogo from "../../assets/images/nimasa.jpg";
import nimasaDark from "../../assets/images/nimasa-removebg.png";
import { Link, useParams } from "react-router-dom";
import Company from "./mock_data.json";

const ViewCompanyDetails = () => {
  document.title =
    "Comapny Details | Maritime Waste Management Admin & Dashboard Platform";

  const getCompany = (id: string) => {
    const facil = Company.filter((company) => company.id === id);
    return facil[0];
  };

  const { id } = useParams();

  let comapny;
  // console.log(id)

  // if (id) comapny = getCompany(id);
 comapny = getCompany("26337790-d2fa-4151-905f-676b0f509e")
  // else comapny = getCompany("1");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Company Details" title={`Company Details`} />
          <Row className="justify-content-center">
            <Col xxl={9}>
              <Card id="demo">
                <Card.Body>
                  <Row className="p-4">
                    <Col lg={9}>
                      <h3 className="fw-bold mb-4 mt-5">{comapny?.name || "Oil Company"}</h3>
                      <Row className="g-4">
                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Company Type
                          </p>
                          <h5 className="fs-16 mb-0">
                            <span id="invoice-no">{comapny?.type}</span>
                          </h5>
                        </Col>

                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-3  text-uppercase fw-medium fs-14">
                            Registration Number
                          </p>
                          <h5 className="fs-16 mb-0">
                            <span id="invoice-date">
                              {comapny?.registration_number
                                ? comapny?.registration_number
                                : "NIL"}
                            </span>{" "}
                          </h5>
                        </Col>
                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Compliance Status
                          </p>
                          {comapny?.is_compliant ? (
                            <span
                              className="badge bg-success-subtle text-success fs-11"
                              id="payment-status"
                            >
                              Is Compliant
                            </span>
                          ) : (
                            <span
                              className="badge bg-danger-subtle text-danger fs-11"
                              id="payment-status"
                            >
                              Is Compliant
                            </span>
                          )}
                        </Col>

                        <Col lg={6} className="col-6">
                          {comapny?.type === "PORT_OF_OPERATION" && (
                            <React.Fragment>
                              <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                                Parent Facility
                              </p>
                              <h5 className="fs-16 mb-0">
                                <span id="total-amount">My parent</span>
                              </h5>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <div className="mt-sm-0 mt-3">
                        <div className="mb-4">
                          <img
                            src={nimasaLogo}
                            className="card-logo card-logo-dark"
                            alt="logo dark"
                            height="77"
                          />
                          <img
                            src={nimasaDark}
                            className="card-logo card-logo-light"
                            alt="logo light"
                            height="77"
                          />
                          {/* <img
                            src={logoLight}
                            className="card-logo card-logo-light"
                            alt="logo light"
                            height="27"
                          /> */}
                        </div>
                        <h6 className="text-muted text-uppercase fw-semibold">
                          Address
                        </h6>
                        <p className="text-muted mb-1" id="address-details">
                          {comapny?.address}
                        </p>

                        <h6>
                          <span className="text-muted fw-normal">State: </span>
                          <span id="state">{comapny?.state}</span>
                        </h6>
                        <p className="text-muted mb-1" id="country">
                          <span>Country: </span> {comapny?.country}
                        </p>
                        <p className="text-muted mb-1" id="longitude">
                          <span>Longitude: </span>
                          {comapny?.longitude}
                        </p>
                        <p className="text-muted mb-1" id="latitude">
                          <span>Laititude: </span> {comapny?.latitude}
                        </p>
                        <h6>
                          <span className="text-muted fw-normal">
                            Website:{" "}
                          </span>{" "}
                          <Link
                            to={comapny?.website ? comapny?.website : "#"}
                            className="link-primary"
                            target="_blank"
                            rel="noreferrer"
                            id="website"
                          >
                            {comapny?.website}
                          </Link>
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  {comapny?.type === "SHIP" && (
                    <Row className="p-4 border-top border-top-dashed">
                      <Col lg={9}>
                        <Row className="g-3">
                          <div className="col-6">
                            <h6 className="text-muted text-uppercase fw-semibold mb-3">
                              Ship Information
                            </h6>
                            <p className="fw-medium mb-2" id="ship-type">
                              {comapny?.shipType}
                            </p>

                            <p className="text-muted mb-1">
                              <span>IMO Number: </span>
                              <span id="imo-number">{comapny?.imoNumber}</span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>MMSI Number: </span>
                              <span id="mmsi-number">
                                {comapny?.mmsiNumber}
                              </span>
                            </p>
                            <p className="text-muted mb-0">
                              <span>Gross Tonnage: </span>
                              <span id="gross-tonnage">
                                {comapny?.grossTonnage}
                              </span>{" "}
                            </p>
                          </div>

                          <div className="col-6">
                            <h6 className="text-muted text-uppercase fw-semibold mb-3">
                              Other Ship Information
                            </h6>
                            <p className="fw-medium mb-2" id="flag">
                              {comapny?.flag}
                            </p>
                            <p className="text-muted mb-1">
                              <span>Length: </span>
                              <span id="length">{comapny?.length}</span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Beam: </span>
                              <span id="beam">{comapny?.beam}</span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Year Built: </span>
                              <span id="year-built">{comapny?.yearBuilt}</span>
                            </p>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col lg={12}>
                      <Card.Body className="card-body p-4">
                        <div className="table-responsive">
                          <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                            <thead>
                              <tr className="table-active">
                                <th scope="col" style={{ width: "50px" }}>
                                  #
                                </th>
                                <th scope="col">Title</th>
                                <th scope="col">Issuing Authority</th>
                                <th scope="col">Issued At</th>
                                <th scope="col" className="text-end">Expires At</th>
                                <th scope="col" className="text-center">
                                  Status
                                </th>
                                <th scope="col" className="text-center">
                                  View
                                </th>
                              </tr>
                            </thead>
                            <tbody id="products-list">
                              <tr>
                                <th scope="row">01</th>
                                <td className="text-start">
                                  <span className="fw-medium">
                                    Certification Title
                                  </span>
                                  <p className="text-muted mb-0">
                                    Some Description of the Certificaton.
                                  </p>
                                </td>
                                <td>NPA</td>
                                <td>2024-12-13</td>

                                <td className="text-end">2024-12-13</td>
                                <td className="">
                                  <p className="text-success bg-success-subtle fs-12 badge text-center mx-auto">
                                    PENDING
                                  </p>
                                </td>
                                <td>
                                  <Link to={"edit"}>
                                    <p className="bg-warning-subtle">
                                      <i className="ri-eye-line align-middle text-warning"></i>
                                    </p>
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">02</th>
                                <td className="text-start">
                                  <span className="fw-medium">
                                    Certification Title
                                  </span>
                                  <p className="text-muted mb-0">
                                    Some Description of the Certificaton.
                                  </p>
                                </td>
                                <td>NPA</td>
                                <td>2024-12-13</td>

                                <td className="text-end">2024-12-13</td>
                                <td className="">
                                  <p className="text-success bg-success-subtle fs-12 badge text-center">
                                    PENDING
                                  </p>
                                </td>
                                <td>
                                  <Link to={"edit"}>
                                    <p className="bg-warning-subtle">
                                      <i className="ri-eye-line align-middle text-warning"></i>
                                    </p>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        {comapny?.compliance_notes && (
                          <div className="mt-4">
                            <div className="alert alert-info">
                              <p className="mb-0">
                                <span className="fw-semibold">
                                  COMPLIANCE NOTES:{" "}
                                </span>
                                <span id="compliance_notes">
                                  {comapny?.compliance_notes}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                          {/* <Link to={`/edit-company-details/${id}`} className="btn btn-primary"> */}
                          <Link to={`/edit-company-details/26337790-d2fa-4151-905f-676b0f509e`} className="btn btn-primary">
                            <i className="ri-edit-line align-bottom me-1"></i>
                            Edit
                          </Link>
                        </div>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewCompanyDetails;
