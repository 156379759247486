export enum FACILITIES_ENUM {
  SHIP = "SHIP",
  OIL_PLATFORM = "OIL_PLATFORM",
  WASTE_FACILITY = "WASTE_FACILITY",
  PORT_OF_OPERATION = "PORT_OF_OPERATION",
  PORT_RECEPTION_FACILITY = "PORT_RECEPTION_FACILITY",
}

export const regulator = [
  "(NIMASA) NIGERIAN MARITIME ADMINISTRATION AND SAFETY AGENCY",
  "(NPA) NIGERIA PORT AUTHORITY",
  "TECHNICAL ADMIN",
];

export const shakeHolder = [
  "WASTE MANAGEMENT / PROCESSING COMPANY",
  "PORT RECEPTION FACILITIES",
  "SHIPPING COMPANY / AGENT",
  "OIL AND GAS OFFSHORE FACILITY",
  "TECHNICAL ADMIN",
];

export const canLocate = [
  "OIL_PLATFORM",
  "WASTE_FACILITY",
  "PORT_OF_OPERATION",
  "PLATFORM ADMIN",
];
