import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import * as Yup from "yup";

import { editUsers as onEditUsers } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

interface usereditProps {
  isShow: any;
  handleClose: any;
  edit: any;
  // isEdit: any
}

const EditCompany = ({ isShow, handleClose, edit }: usereditProps) => {
  // image
  const [selectedImage, setSelectedImage] = useState<any>();

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      formik.setFieldValue("memberImage", e.target.result);
      setSelectedImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (edit && edit.id) || "",
      name: (edit && edit.name) || "",
      state: (edit && edit.state) || "",
      address: (edit && edit.address) || "",
      country: (edit && edit.country) || "",
      memberImage: (edit && edit.logo) || "",
      email: (edit && edit.email) || "",
      longitude: (edit && edit.longitude) || "",
      latitude: (edit && edit.latitude) || "",
      website: (edit && edit.website) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter First Name"),
      state: Yup.string().required("Please Enter Last Name"),
      username: Yup.string().required("Please Enter user Name"),
      address: Yup.string().required("Please Enter address"),
      country: Yup.string().required("Please Enter country"),
      website: Yup.string(),
      longitude: Yup.number().required("Please Enter longitude"),
      latitude: Yup.number().required("Please Enter latitude"),
      memberImage: Yup.string().required("Please Select Member Image"),
    }),

    onSubmit: (values: any) => {
      const UpdateUser = {
        id: values.id,
        name: values.name,
        logo: values.memberImage,
        email: values.email,
        state: values.state,
        country: values.country,
        address: values.address,
        latitude: values.latitude,
        longitude: values.longitude,
website: values.website
      };

      dispatch(onEditUsers(UpdateUser));
      formik.resetForm();
    },
  });

  useEffect(() => {
    setSelectedImage(edit?.memberImage);
  }, [edit]);

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Edit Company</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="text-center">
                <div className="position-relative d-inline-block">
                  <div className="position-absolute bottom-0 end-0">
                    <Form.Label
                      htmlFor="product-image-input"
                      className="mb-0"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Select Image"
                    >
                      <div className="avatar-xs cursor-pointer">
                        <div className="avatar-title bg-light border rounded-circle text-muted">
                          <i className="ri-image-fill"></i>
                        </div>
                      </div>
                    </Form.Label>
                    <Form.Control
                      name="memberImage"
                      className="form-control d-none"
                      value=""
                      id="product-image-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleImageChange}
                    />
                  </div>
                  <div className="avatar-lg p-1">
                    <div className="avatar-title bg-light rounded-circle">
                      <img
                        src={selectedImage || dummy}
                        alt=""
                        id="product-img"
                        className="avatar-md rounded-circle object-cover"
                      />
                    </div>
                  </div>
                </div>
                {formik.errors.memberImage && formik.touched.memberImage ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {" "}
                    {formik.errors.memberImage}{" "}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="users">
                      Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter company name"
                      value={formik.values.name || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.name}
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.name}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Email-input">
                      Email<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="Email-input"
                      name="email"
                      placeholder="Enter Your email"
                      value={formik.values.email || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.email}
                      disabled
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row>
                
              <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="address">
                      Address<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Enter last name"
                      value={formik.values.address || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.address}
                    />
                    {formik.errors.address && formik.touched.address ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.address}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="state">
                      State<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="state"
                      name="state"
                      placeholder="Enter last name"
                      value={formik.values.state || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.state}
                    />
                    {formik.errors.state && formik.touched.state ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.state}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="country">
                      Country<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="country"
                      name="country"
                      placeholder="Enter country"
                      value={formik.values.country || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.country}
                    />
                    {formik.errors.country && formik.touched.country ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.country}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="website">
                      Website
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="website"
                      name="website"
                      placeholder="Enter last name"
                      value={formik.values.website || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.website}
                    />
                    {formik.errors.website && formik.touched.website ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.website}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="longitude">
                      Longitude<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="longitude"
                      name="longitude"
                      placeholder="Enter Your longitude"
                      value={formik.values.longitude || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.longitude}
                    />
                    {formik.errors.longitude && formik.touched.longitude ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.longitude}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="latitude">
                      Latitude<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="latitude"
                      name="latitude"
                      placeholder="Enter Your latitude"
                      value={formik.values.latitude || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.latitude}
                    />
                    {formik.errors.latitude && formik.touched.latitude ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.latitude}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-success">
                  Update Company
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default EditCompany;
