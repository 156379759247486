import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { addUsers as onAddUsers } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import { PatternFormat } from "react-number-format";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
}

const AddUsers = ({ isShow, handleClose, handleShow }: userProps) => {
  // image
  const [selectedImage, setSelectedImage] = useState<any>();

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      formik.setFieldValue("memberImage", e.target.result);
      setSelectedImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      memberImage: "",
      email: "",
      phone: "",
      username: "",
      password: "",
      role: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      // memberImage: Yup.string().required("Please Select Member Name"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Mobile Number"),
      username: Yup.string().required("Please Enter username"),
      password: Yup.string().required("Please Enter password"),
      role: Yup.string().required("Please choose Your role"),
    }),

    onSubmit: (values: any) => {
      // let newid = Math.floor(Math.random() * (30 - 20)) + 20;
      const NewUser = {
        // id: newid,
        firstName: values["firstName"],
        lastName: values["lastName"],
        memberImage: values["memberImage"],
        email: values["email"],
        phone: values["phone"],
        username: values["username"],
        password: values['password'],
        role: values["role"],
      };

      dispatch(onAddUsers(NewUser));
      // formik.resetForm();

      if (NewUser === null) {
        handleShow();
      } else {
        handleClose();
      }
    },
  });

  useEffect(() => {
    setSelectedImage("");
  }, [handleClose]);

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Add User</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="text-center">
                <div className="position-relative d-inline-block">
                  <div className="position-absolute bottom-0 end-0">
                    <Form.Label
                      htmlFor="product-image-input"
                      className="mb-0"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Select Image"
                    >
                      <div className="avatar-xs cursor-pointer">
                        <div className="avatar-title bg-light border rounded-circle text-muted">
                          <i className="ri-image-fill"></i>
                        </div>
                      </div>
                    </Form.Label>
                    <Form.Control
                      name="memberImage"
                      className="form-control d-none"
                      value=""
                      id="product-image-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleImageChange}
                    />
                  </div>
                  <div className="avatar-lg p-1">
                    <div className="avatar-title bg-light rounded-circle">
                      <img
                        src={selectedImage || dummy}
                        alt=""
                        id="product-img"
                        className="avatar-md rounded-circle object-cover"
                      />
                    </div>
                  </div>
                </div>
                {formik.errors.memberImage && formik.touched.memberImage ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {" "}
                    {formik.errors.memberImage}{" "}
                  </Form.Control.Feedback>
                ) : null}
              </div>

                <Row>
                <Col lg={6}>
              <div className="mb-3">
              <Form.Label htmlFor="users">
                  First Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter first name"
                  value={formik.values.firstName || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.firstName}
                />
                {formik.errors.firstName && formik.touched.firstName ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.firstName}
                  </Form.Control.Feedback>
                ) : null}
                </div></Col>
                <Col lg={6}>
              <div className="mb-3">
              <Form.Label htmlFor="lastName">
                  Last Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter last name"
                  value={formik.values.lastName || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.lastName}
                />
                {formik.errors.lastName && formik.touched.lastName ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.lastName}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              </Col>
                </Row>
                
              
                
              <div className="mb-3">
                <Form.Label htmlFor="Email-input">
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Email-input"
                  name="email"
                  placeholder="Enter Your email"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              
                <Row>
              <Col lg={6}>
              <div className="mb-3">
                <Form.Label htmlFor="Username-input">
                  Username<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Username-input"
                  name="username"
                  placeholder="Enter Your username"
                  value={formik.values.username || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.username}
                />
                {formik.errors.username && formik.touched.username ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.username}
                  </Form.Control.Feedback>
                ) : null}
                </div>
              </Col>
              <Col lg={6}>
              <div className="mb-3">

                <Form.Label htmlFor="Password-input">
                  Password<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  id="Password-input"
                  name="password"
                  placeholder="Enter Your password"
                  value={formik.values.password || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.password}
                />
                {formik.errors.password && formik.touched.password ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                ) : null}
              </div>
                </Col>
                </Row>
              <Row>
                <Col lg={6}>
                <div className="mb-3">

                  <Form.Label htmlFor="users">
                    Mobile<span className="text-danger">*</span>
                  </Form.Label>
                  <PatternFormat
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Enter Your Mobile"
                    value={formik.values.phone || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    format="####-###-####"
                  />
                  {formik.errors.phone && formik.touched.phone ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {formik.errors.phone}
                    </Form.Control.Feedback>
                  ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="role" className="form-label">
                      Role<span className="text-danger">*</span>
                    </label>
                    <Form.Select
                      id="paymentType"
                      name="role"
                      placeholder="Enter Payment type"
                      value={formik.values.role || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.role}
                    >
                      <option>Select Role</option>
                      <option value="viewer">Viewer</option>
                      <option value="editor">Editor</option>
                    </Form.Select>
                    {formik.errors.role && formik.touched.role ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.role}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-success">
                  Add User
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddUsers;
