import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Card, Col, Dropdown, Form, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import {
  getOilPlatforms as onGetOilPlatforms,
  deleteOilPlatform as onDeleteOilPlatform,
  selectOperationalModule,
} from "../../../slices/thunk";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { DeleteModal } from "../../../Common/DeleteModal";
import { handleSearchData } from "../../../Common/Tabledata/SorttingData";
import EditOilPlatform from "../../../Common/CrudModal/EditOilPlatform";
import AddOilPlatform from "../../../Common/CrudModal/AddOilPlatform";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import axios from "axios";
import { PlatformStatusEnum } from "../../../helpers/enums/platform.enum";
import { useNavigate } from "react-router-dom";

interface oilPlatformProps {
  isShow: any;
  hideOilPlatformModal: any;
}

const OilPlatformTable = ({
  isShow,
  hideOilPlatformModal,
}: oilPlatformProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  type IState = { OilPlatform: any; Account: any };
  // const selectOilPlatformsList = createSelector(
  const selectStates = createSelector(
    ({ OilPlatform, Account }: IState) => ({ OilPlatform, Account }),
    ({ OilPlatform, Account }: IState) => ({
      oilPlatformList: OilPlatform.oilPlatformList,
      user: Account.user,
    })
  );

  const { oilPlatformList, user } = useSelector(selectStates);

  const [platforms, setOilPlatforms] = useState<any>([]);
  const [platformStatus, setOilPlatformStatus] = useState<any>({});

  useEffect(() => {
    dispatch(onGetOilPlatforms());
  }, [dispatch]);

  useEffect(() => {
    setOilPlatforms(oilPlatformList);
  }, [oilPlatformList]);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/platform/platform-status`
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setOilPlatformStatus(JSON.parse(JSON.stringify(response)));
    };

    getData();
  }, []);

  // Delete modal

  const [delet, setDelet] = useState<boolean>(false);
  const [deletid, setDeletid] = useState<any>();

  const handleDeleteModal = useCallback(
    (id: any) => {
      setDelet(!delet);
      setDeletid(id);
    },
    [delet]
  );

  const handleDeleteId = () => {
    dispatch(onDeleteOilPlatform(deletid.id));
    setDelet(false);
  };

  const toggleTab = (type: any) => {
    if (type !== "all") {
      setOilPlatforms(
        oilPlatformList.filter((platform: any) => platform.status === type)
      );
    } else {
      setOilPlatforms(oilPlatformList);
    }
  };

  const [editOilPlatform, setEditOilPlatform] = useState<boolean>(false);
  const [edit, setEdit] = useState<any>();

  const handleCloseEdit = () => setEditOilPlatform(false);
  const handleEditOilPlatform = (item: any) => {
    setEditOilPlatform(true);
    console.log("handleEditOilPlatform item", item);
    setEdit({
      id: item.id,
      name: item.name,
      longitude: item.longitude,
      latitude: item.latitude,
      address: item.address,
      status: item.status,
    });
  };

  // search
  const handleSearch = (ele: any) => {
    let item = ele.value;

    if (item === "All Tasks") {
      setOilPlatforms([...oilPlatformList]);
    } else {
      handleSearchData({
        data: oilPlatformList,
        item: item,
        setState: setOilPlatforms,
      });
    }
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "DATE",
        accessor: "createDateTime",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        Filter: false,
        isSortable: true,
      },
      // {
      //   Header: "PAYMENT TYPE",
      //   accessor: "platformType",
      //   Filter: false,
      //   isSortable: true,
      //   Cell: (cell) => {
      //     switch (cell.row.original.platformType) {
      //       case "Credit Card":
      //         return <div>{cell.row.original.platformType}</div>;
      //       case "Google Pay":
      //         return <div>{cell.row.original.platformType}</div>;
      //       case "Cash":
      //         return <div>{cell.row.original.platformType}</div>;
      //       case "Bank Transfer":
      //         return <div>{cell.row.original.platformType}</div>;
      //     }
      //   },
      // },
      // {
      //   Header: "AMOUNT",
      //   accessor: "amount",
      //   Filter: false,
      //   isSortable: true,
      //   Cell: (cell: any) => <>${cell.row.original.amount}</>,
      // },
      {
        Header: "Status",
        accessor: "status",
        key: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case PlatformStatusEnum.InService:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );

            case PlatformStatusEnum.InterimRegistered:
            case PlatformStatusEnum.Withdrawn:
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status}
                </span>
              );
            case PlatformStatusEnum.Suspended:
            case PlatformStatusEnum.Deleted:
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        style: { width: "12%" },

        isSortable: false,
        Cell: (cell: any) => (
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              className="btn btn-soft-secondary btn-sm arrow-none"
            >
              <i className="las la-ellipsis-h align-middle fs-18"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-end">
              {/* <li>
                <Dropdown.Item>
                  <i className="las la-eye fs-18 align-middle me-2 text-muted"></i>
                  View
                </Dropdown.Item>
              </li> */}
              <li>
                <Dropdown.Item
                  onClick={() => {
                    const item = cell.row.original;
                    handleEditOilPlatform(item);
                  }}
                >
                  <i className="las la-pen fs-18 align-middle me-2 text-muted"></i>
                  Edit
                </Dropdown.Item>
              </li>
              <li>
                <Dropdown.Item>
                  <i className="las la-file-download fs-18 align-middle me-2 text-muted"></i>
                  Download
                </Dropdown.Item>
              </li>
              <li className="dropdown-divider"></li>
              <li>
                <Dropdown.Item
                  className="remove-item-btn"
                  onClick={() => {
                    const item = cell.row.original;
                    handleDeleteModal(item);
                  }}
                >
                  <i className="las la-trash-alt fs-18 align-middle me-2 text-muted"></i>
                  Delete
                </Dropdown.Item>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    [handleDeleteModal]
  );

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <Col sm={4}>
          <button
            className="btn btn-primary addOilPlatform-modal"
            // onClick={hideOilPlatformModal}
            onClick={() => {
              dispatch(selectOperationalModule("ermcs"));
              navigate("/facility-management/oil-platforms");
            }}
          >
            <i className="las la-plus me-1"></i> Add Platform
          </button>
        </Col>

        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                id="searchMemberList"
                placeholder="Search for Result"
                onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>All</Dropdown.Item>
                <Dropdown.Item>Last Week</Dropdown.Item>
                <Dropdown.Item>Last Month</Dropdown.Item>
                <Dropdown.Item>Last Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              <Tab.Container defaultActiveKey="all">
                <Nav
                  as="ul"
                  variant="tabs"
                  className="nav-tabs nav-tabs-custom nav-success mb-3"
                >
                  <Nav.Item as="li">
                    <Nav.Link
                      eventKey="all"
                      onClick={() => {
                        toggleTab("all");
                      }}
                    >
                      All
                    </Nav.Link>
                  </Nav.Item>
                  {platformStatus &&
                    Object.keys(platformStatus).map((sh) => (
                      <Nav.Item as="li" key={sh}>
                        <Nav.Link
                          eventKey={platformStatus[sh]}
                          onClick={() => {
                            toggleTab(platformStatus[sh]);
                          }}
                        >
                          {platformStatus[sh]}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  {/* <Nav.Item as="li">
                    <Nav.Link
                      eventKey="paid"
                      onClick={() => {
                        toggleTab("Paid");
                      }}
                    >
                      Paid
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      eventKey="pending"
                      onClick={() => {
                        toggleTab("Pending");
                      }}
                    >
                      Pending
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>

                <Card>
                  <Card.Body>
                    {platforms && platforms.length > 0 ? (
                      <TableContainer
                        isPagination={true}
                        columns={columns}
                        data={platforms || []}
                        customPageSize={9}
                        divClassName="table-card table-responsive"
                        tableClass="table-hover table-nowrap align-middle mb-0"
                        isBordered={false}
                        theadClass="table-light"
                        PaginationClass="align-items-center mt-4 gy-3"
                      />
                    ) : (
                      <NoSearchResult />
                    )}
                  </Card.Body>
                </Card>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <AddOilPlatform
        isShow={isShow}
        handleClose={hideOilPlatformModal}
        handleShow={isShow}
      />

      <EditOilPlatform
        isShow={editOilPlatform}
        handleClose={handleCloseEdit}
        edit={edit}
      />

      <DeleteModal
        show={delet}
        handleClose={handleDeleteModal}
        deleteModalFunction={handleDeleteId}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default OilPlatformTable;
