import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { approveCertApplication as onApproveCertApplication, rejectCertApplication as onRejectCertApplication } from '../../slices/certificates/thunk';
import { useDispatch } from 'react-redux';


interface ViewApplicationDetailsProps {
  isShow: boolean;
  handleClose: () => void;
  applicationDetails?: {
    applicationTitle?: string;
    description?: string;
    companyName?: string;
    facilityName?: string;
    status?: string;
    title?: string;
    target_type?:string;
    target_id?:string;
    id?:string;
  };
}

const ViewApplicationDetails: React.FC<ViewApplicationDetailsProps> = ({ 
  isShow, 
  handleClose, 
  applicationDetails,

}) => {
  const [isRevoking, setIsRevoking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [revocationReason, setRevocationReason] = useState('');
  const dispatch = useDispatch();

  const handleApprove = (id:any) => {
       dispatch(onApproveCertApplication(id))
  };

  const handleRevoke = (id:any) => {
      dispatch(onRejectCertApplication(id))
  };

  return (
    <Modal show={isShow} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Application Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {applicationDetails ? (
          <div className="application-details">
            <div className="detail-row mb-3">
              <h5 className="mb-2">
                {applicationDetails.applicationTitle || applicationDetails.title}
              </h5>
            </div>
            
            <div className="detail-row mb-2">
              <strong>Description:</strong> {applicationDetails.description}
            </div>
            
            <div className="detail-row mb-2">
              <strong>Company Name:</strong> {applicationDetails.companyName}
            </div>

            <div className="detail-row mb-2">
              <strong>Application Id:</strong> {applicationDetails.id}
            </div>
            
            {applicationDetails.target_id && (
              <div className="detail-row mb-2">
                <strong>Facility Name:</strong> {applicationDetails.target_id}
              </div>
            )}

            {applicationDetails.target_type && (
              <div className="detail-row mb-2">
                <strong>Target Type:</strong> {applicationDetails.target_type}
              </div>
            )}

            {/* Approve and Revoke Section */}
            <div className="action-section mt-4">
              <h5>Application Actions</h5>
              <div className="d-flex flex-column">
                <div className="form-check mb-3">
                  <input 
                    type="checkbox" 
                    className="form-check-input" 
                    id="approveCheckbox"
                    checked={isApproving}
                    onChange={(e) => {
                      setIsApproving(e.target.checked);
                      if (e.target.checked) {
                        setIsRevoking(false);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="approveCheckbox">
                    Approve Application
                  </label>
                </div>

                {isApproving && (
                  <div className="alert alert-warning mb-3">
                    Are you sure you want to approve this application?
                    <div className="mt-2">
                      <button 
                        type="button" 
                        className="btn btn-success me-2"
                        onClick={() => handleApprove(applicationDetails.id)}
                      >
                        Confirm Approval
                      </button>
                      <button 
                        type="button" 
                        className="btn btn-secondary"
                        onClick={() => setIsApproving(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                <div className="form-check mb-3">
                  <input 
                    type="checkbox" 
                    className="form-check-input" 
                    id="revokeCheckbox"
                    checked={isRevoking}
                    onChange={(e) => {
                      setIsRevoking(e.target.checked);
                      if (e.target.checked) {
                        setIsApproving(false);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="revokeCheckbox">
                    Reject Application
                  </label>
                </div>

                {isRevoking && (
                  <Form.Group className="mb-3">
                    <Form.Label>Reason for Rejection</Form.Label>
                    <Form.Control 
                      as="textarea" 
                      rows={3} 
                      placeholder="Enter reason for revoking the application"
                      value={revocationReason}
                      onChange={(e) => setRevocationReason(e.target.value)}
                    />
                    {revocationReason.trim() && (
                      <div className="alert alert-warning mt-2">
                        Are you sure you want to reject this application?
                        <div className="mt-2">
                          <button 
                            type="button" 
                            className="btn btn-danger me-2"
                            onClick={() => handleRevoke(applicationDetails.id)}
                          >
                            Confirm Rejection
                          </button>
                          <button 
                            type="button" 
                            className="btn btn-secondary"
                            onClick={() => setIsRevoking(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                     )}
                  </Form.Group>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p>No application details available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button 
          type="button" 
          className="btn btn-light" 
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewApplicationDetails;