import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import EditUsers from "../../Common/CrudModal/EditUsers";
import EditPersonalInfo from "../../Common/CrudModal/EditPersonalInfo";
import EditCompany from "../../Common/CrudModal/EditCompany";

const Profile = () => {
  const selectAccount = createSelector(
    (state: any) => state.Login,
    (user: any) => ({ user: user.user, selectedModule: user.selected_module })
  );

  const [editInfo, setEditInfo] = useState<boolean>(false);
  const [editCompany, setEditCompany] = useState<boolean>(false);

  const handleCloseUserEdit = () => setEditInfo(false);
  const handleUserEdit = () => {
    setEditInfo(true);
  };
  const handleCloseCompanyEdit = () => setEditCompany(false);
  const handleCompanyEdit = () => {
    setEditCompany(true);
  };

  const { user } = useSelector(selectAccount);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Profile" title="Profile" />
          <Card>
            <Card.Header>
              <Card.Title>User Information (Role)</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="text-center mb-3">
                <div className="position-relative d-inline-block">
                  <div className="avatar-lg p-1">
                    <div className="avatar-title bg-light rounded-circle">
                      <img
                        src={dummy}
                        alt=""
                        id="company-logo"
                        className="avatar-md rounded-circle object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Row className="mb-3">
                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    First Name
                  </p>
                  <h5 className="fs-16 mb-0">
                    <span id="total-amount">{user.firstName}</span>
                  </h5>
                </Col>
                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Last Name
                  </p>
                  <h5 className="fs-16 mb-0">
                    <span id="total-amount">{user.lastName}</span>
                  </h5>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Email
                  </p>
                  <h5 className="fs-16 mb-0">
                    <span id="total-amount">{user.email}</span>
                  </h5>
                </Col>
                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Username
                  </p>
                  <h5 className="fs-16 mb-0">
                    <span id="total-amount">{user.username}</span>
                  </h5>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <div className="hstack gap-2 justify-content-end d-print-none">
                <Button
                  className="btn btn-primary"
                  onClick={() => handleUserEdit()}
                >
                  <i className="ri-edit-line align-bottom me-1"></i>
                  Edit
                </Button>
              </div>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title>Company ({user.company.companyType})</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="text-center mb-3">
                <div className="position-relative d-inline-block">
                  <div className="avatar-lg p-1">
                    <div className="avatar-title bg-light rounded-circle">
                      <img
                        src={dummy}
                        alt=""
                        id="company-logo"
                        className="avatar-md rounded-circle object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Row className="mb-3">
                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Email
                  </p>
                  <h5 className="fs-16 mb-0">
                    <span id="total-amount">{user.company.email}</span>
                  </h5>
                </Col>
                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Name
                  </p>
                  <h5 className="fs-16 mb-0">
                    <span id="total-amount">{user.company.name}</span>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="col-6">
                  <div className="mt-sm-0 mt-3">
                    <h6 className="text-muted text-uppercase fw-semibold">
                      Address
                    </h6>
                    <p className="text-muted mb-1" id="address-details">
                      {user.company.address}
                    </p>

                    <h6>
                      <span className="text-muted fw-normal">State: </span>
                      <span id="state">{user.company.state}</span>
                    </h6>
                    <p className="text-muted mb-1" id="country">
                      <span>Country: </span> {user.company.country}
                    </p>
                    <p className="text-muted mb-1" id="longitude">
                      <span>Longitude: </span>
                      {user.company.longitude}
                    </p>
                    <p className="text-muted mb-1" id="latitude">
                      <span>Laititude: </span> {user.company.latitude}
                    </p>
                    <h6>
                      <span className="text-muted fw-normal">Website: </span>{" "}
                      <Link
                        to={user.company.website ? user.company.website : "#"}
                        className="link-primary"
                        target="_blank"
                        rel="noreferrer"
                        id="website"
                      >
                        {user.company.website || "NIL"}
                      </Link>
                    </h6>
                  </div>
                </Col>
                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Status
                  </p>
                  {user.company.status === "active" ? (
                    <h5 className="fs-12 mb-0 badge bg-success-subtle text-success">
                      <span id="company-status">{user.company.status}</span>
                    </h5>
                  ) : user.company.status === "deactivated" ? (
                    <h5 className="fs-12 mb-0 badge bg-danger-subtle text-danger">
                      <span id="company-status">{user.company.status}</span>
                    </h5>
                  ) : (
                    <h5 className="fs-12 mb-0 badge bg-warning-subtle text-warning">
                      <span id="company-status">{user.company.status}</span>
                    </h5>
                  )}
                </Col>
              </Row>
            </Card.Body>

            <Card.Footer>
              <div className="hstack gap-2 justify-content-end d-print-none">
                <Button
                  className="btn btn-primary"
                  onClick={() => handleCompanyEdit()}
                >
                  <i className="ri-edit-line align-bottom me-1"></i>
                  Edit
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Container>
      </div>
      <EditPersonalInfo
        isShow={editInfo}
        handleClose={handleCloseUserEdit}
        edit={{ ...user }}
      />
      <EditCompany
        isShow={editCompany}
        handleClose={handleCloseCompanyEdit}
        edit={{ ...user.company }}
      />
    </React.Fragment>
  );
};

export default Profile;
