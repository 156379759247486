import { useFormik } from "formik";
import React from "react";
// import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { title } from "process";

enum LICENSE_TARGET_TYPE {
    FACILITY = 'FACILITY',
    VESSEL = 'VESSEL',
    PERSONNEL = 'PERSONNEL'
  }
  
  enum LICENSE_TYPE {
      COMMERCIAL = 'Commercial License',
      OPERATIONAL = 'Operational License',
      SAFETY = 'Safety License',
      ENVIRONMENTAL = 'Environmental License',
      TRAINING = 'Training License',
      RESEARCH = 'Research License'
    }

interface liscenceeditProps {
    isShow: any;
    handleClose: any;
    edit: any;
}

const EditLiscenceApplication = ({ isShow, handleClose, edit }: liscenceeditProps) => {

    // const dispatch = useDispatch();

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: (edit && edit.id) || "",
            title: (edit && edit.title) || "",
            description: (edit && edit.description) || "",
            target_type: (edit && edit.target_type) || "",

        },
        validationSchema: Yup.object({
            applicationTitle: Yup.string().required("Please enter application title"),
            description: Yup.string().required("Please enter application description"),
            companyName: Yup.string().required("Please enter company name"),
            facilityName: Yup.string().required("Please select a facility name"),
        }),

        onSubmit: (values: any) => {
            const UpdateApplication = {
                id: values.id,
                applicationTitle: values.applicationTitle,
                description: values.description,
                companyName: values.companyName,
                facilityName: values.facilityName,
            };

            //   dispatch(onEditUsers(UpdateUser));
            formik.resetForm();
        },
    });


    return (
        <React.Fragment>
            <Modal
                centered
                show={isShow}
                onHide={handleClose}
                style={{ display: "block" }}
                tabIndex={-1}
            >
                <div className="modal-content border-0">
                    <Modal.Header className="p-4 pb-0">
                        <Modal.Title as="h5">Edit Liscence Application</Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <Form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="title">License Type</Form.Label>
                                        <Form.Select
                                            id="title"
                                            name="title"
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.errors.title && formik.touched.title}
                                        >
                                            <option value="">Select License Type</option>
                                            {Object.values(LICENSE_TYPE).map((type) => (
                                                <option key={type} value={type}>{type}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.title}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="description">License Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="description"
                                            name="description"
                                            placeholder="Enter License Description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.errors.description && formik.touched.description}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.description}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="target_type">Target Type</Form.Label>
                                        <Form.Select
                                            id="target_type"
                                            name="target_type"
                                            value={formik.values.target_type}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.errors.target_type && formik.touched.target_type}
                                        >
                                            <option value="">Select Target Type</option>
                                            {Object.values(LICENSE_TARGET_TYPE).map((type) => (
                                                <option key={type} value={type}>{type}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.target_type}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>

                            <div className="hstack gap-2 justify-content-end">
                                <Button type="button" className="btn btn-light" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="submit" className="btn btn-success">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default EditLiscenceApplication;
