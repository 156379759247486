import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { CompanyTypeEnum } from "../helpers/enums/company.enum";
import { ALL_SELECTABLE_MODULES } from "../helpers/enums/modules.enum";

const Navdata = () => {
  //state data
  const [menuState, setMenuState] = useState<Record<string, boolean>>({
    isInvoiceManagement: false,
    isWasteNotificationManagement: false,
    isFacilityManagement: false,
    isShipManagement: false,
    isUserManagement: false,
    isOilPlatformManagement: false,
    isAuthentication: false,
    isPages: false,
    isProducts: false,
    isReport: false,
    isTransaction: false,
    isBootstrapUi: false,
    isAdvanceUi: false,
    isForms: false,
    isTables: false,
    isCharts: false,
    isIcons: false,
    isMaps: false,
    isOrder: false,
    isAuth: false,
    isMultiLevel: false,
    isLevel1: false,
    isLevel2: false,
    isCertificationManagement: false,
    isLicenseManagement: false,
  });

  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id: any = item.getAttribute("sub-items");
        var menusId = document.getElementById(id);
        if (menusId) {
          (menusId.parentElement as HTMLElement).classList.remove("show");
        }
      });
      e.target.classList.add("active");
    }
  }

  const selectAccount = createSelector(
    (state: any) => state.Login,
    (account: any) => {
      // console.log("account", account);
      return {
        user: account.user,
        isModuleSelected: account.isModuleSelected,
        selected_module: account.selected_module,
      };
    }
  );

  const { user, isModuleSelected, selected_module } =
    useSelector(selectAccount);

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(user?.company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) && isModuleSelected ? [child] : [];
  };

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    setMenuState({
      isInvoiceManagement: false,
      isWasteNotificationManagement: false,
      isFacilityManagement: false,
      isShipManagement: false,
      isUserManagement: false,
      isOilPlatformManagement: false,
      isAuthentication: false,
      isPages: false,
      isProducts: false,
      isReport: false,
      isTransaction: false,
      isBootstrapUi: false,
      isAdvanceUi: false,
      isForms: false,
      isTables: false,
      isCharts: false,
      isIcons: false,
      isMaps: false,
      isOrder: false,
      isAuth: false,
      isMultiLevel: false,
      isLevel1: false,
      isLevel2: false,
      isCertificationManagement: false,
      isLicenseManagement: false,
    });
  }, []);

  const toggleSubMenu = (e: any, menuKey: string) => {
    try {
      e.preventDefault();
      updateIconSidebar(e);
    } catch (error) {}
    // console.log("isCurrentState", isCurrentState);
    setMenuState((prevState) => ({
      ...prevState,
      [isCurrentState]: false,
      [menuKey]: !prevState[menuKey],
    }));
    setIsCurrentState(menuKey);
  };

  let commonMenuItems = [
    ...(isModuleSelected
      ? [
          {
            label: "Menu",
            isHeader: true,
          },
          {
            id: "dashboard",
            label: "Dashboard",
            icon: "las la-house-damage",
            link: "/dashboard",
          },
          {
            label: "Pages",
            isHeader: true,
          },
        ]
      : [
          {
            label: user?.company?.name,
            isHeader: true,
            isSelected: true,
          },
        ]),
  ];

  switch (selected_module) {
    case ALL_SELECTABLE_MODULES.AUTOMATED_WASTE_MANAGEMENT_SYSTEM:
      commonMenuItems = [
        ...commonMenuItems,
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
            CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          ],
          {
            id: "wasteNotificationManagement",
            label: "Waste Collections",
            icon: "las la-file-invoice",
            link: "/waste-notification",
          }
        ),
        ...addLayoutData(
          [CompanyTypeEnum.OIL_AND_GAS_PLATFORM, CompanyTypeEnum.SHIPMENT],
          {
            id: "wasteNotificationManagement",
            label: "Waste Declarations",
            icon: "las la-file-invoice",
            link: "/waste-notification",
          }
        ),

        // ...addLayoutData(
        //   [
        //     CompanyTypeEnum.PLATFORM_ADMIN,
        //     CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
        //     CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
        //     CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
        //     CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
        //   ],
        //   {
        //     id: "userManagement",
        //     label: "User Management",
        //     icon: "las la-file-invoice",
        //     link: "/user",
        //   }
        // ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          ],
          {
            id: "companiesManagement",
            label: "Companies",
            icon: "las la-file-invoice",
            link: "/companies",
          }
        ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
          ],
          {
            id: "wasteManagement",
            label: "Waste Management",
            icon: "las la-file-invoice",
            link: "/waste-managements",
          }
        ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          ],
          {
            id: "pooManagement",
            label: "Port Of Operations",
            icon: "las la-file-invoice",
            link: "/port-of-operations",
          }
        ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
            CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
          ],
          {
            id: "prfManagement",
            // label: "Facility Management",
            label: "Port Receptions",
            icon: "las la-file-invoice",
            link: "/port-receptions",
          }
        ),
        ...addLayoutData(
          [CompanyTypeEnum.PLATFORM_ADMIN, CompanyTypeEnum.SHIPMENT],
          {
            id: "shipManagement",
            label: "Ship Management",
            icon: "las la-file-invoice",
            link: "/ships",
          }
        ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
          ],
          {
            id: "oilPlatformManagement",
            label: "Platform Management",
            icon: "las la-file-invoice",
            link: "/oil-platforms",
          }
        ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.SHIPMENT,
            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
            CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
            CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
          ],
          {
            id: "wallet",
            label: "Wallet",
            icon: "las la-wallet",
            link: "/wallets",
          }
        ),
      ];
      break;

    case ALL_SELECTABLE_MODULES.ELECTRONIC_REGISTRATION_AND_CERTIFICATION_SYSTEM:
      commonMenuItems = [
        ...commonMenuItems,

        {
          id: "facilityManagement",
          label: "Facility Management",
          icon: "las la-building",
          link: "/#",
          click: (e: any) => toggleSubMenu(e, "isFacilityManagement"),
          stateVariables: menuState.isFacilityManagement,
          subItems: [
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.SHIPMENT,
                // CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
              ],
              {
                id: "shipManagement",
                label: "Ship Management",
                icon: "las la-file-invoice",
                parentId: "facilityManagement",
                link: "/facility-management/ships",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "oilPlatformManagement",
                label: "Oil Platform",
                parentId: "facilityManagement",
                link: "/facility-management/oil-platforms",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "companiesManagement",
                label: "Companies",
                parentId: "facilityManagement",
                icon: "las la-file-invoice",
                link: "/companies",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
              ],
              {
                id: "portReceptionsManagement",
                label: "Port Receptions",
                parentId: "facilityManagement",
                link: "/facility-management/port-receptions",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
              ],
              {
                id: "portOfOperationsManagement",
                label: "Port of Operations",
                parentId: "facilityManagement",
                link: "/facility-management/port-of-operations",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "wasteManagementManagement",
                label: "Waste Managements",
                parentId: "facilityManagement",
                link: "/facility-management/waste-managements",
              }
            ),
          ],
        },
        {
          id: "certificationManagement",
          label: "Cert Management",
          icon: "las la-certificate",
          link: "/#",
          click: (e: any) => toggleSubMenu(e, "isCertificationManagement"),
          stateVariables: menuState.isCertificationManagement,
          subItems: [
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.SHIPMENT,
                CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "addCert",
                label: "Apply For Cert",
                link: "/certification-management/apply",
                parentId: "certificationManagement",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.SHIPMENT,
                CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "certList",
                label: "Certificate List",
                link: "/certification-management/view-certificates",
                parentId: "certificationManagement",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "pendingApplication",
                label: "Pending Applications",
                link: "/certification-management/pending-applications",
                parentId: "certificationManagement",
              }
            ),

            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "GrantedCertificates",
                label: "Issued Certificates",
                link: "/certification-management/granted-certificates",
                parentId: "certificationManagement",
              }
            ),
          ],
        },
        {
          id: "licenseManagement",
          label: "License Management",
          icon: "las la-id-card",
          link: "/#",

          click: (e: any) => toggleSubMenu(e, "isLicenseManagement"),
          stateVariables: menuState.isLicenseManagement,

          subItems: [
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.SHIPMENT,
                CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "applyLicense",
                label: "Apply For License",
                link: "/license-management/apply",
                parentId: "licenseManagement",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.SHIPMENT,
                CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "licenseList",
                label: "License List",
                link: "/license-management/view-licenses",
                parentId: "licenseManagement",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "pendingApplications",
                label: "Pending Applications",
                link: "/license-management/pending-applications",
                parentId: "licenseManagement",
              }
            ),
            ...addLayoutData(
              [
                CompanyTypeEnum.PLATFORM_ADMIN,
                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
              ],
              {
                id: "grantedLicense",
                label: "Issued Licenses",
                link: "/license-management/granted-licenses",
                parentId: "licenseManagement",
              }
            ),
     
          ],
        },
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.SHIPMENT,
            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
            CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
            // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          ],
          {
            id: "permitManagement",
            label: "Permit Management",
            icon: "las la-file-alt",
            link: "/permit-management",
          }
        ),

        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.SHIPMENT,
            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
            CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
            // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          ],
          {
            id: "userManagement",
            label: "User Management",
            icon: "las la-user",
            link: "/user-management",
          }
        ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
            // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          ],
          {
            id: "profileManagement",
            label: "Profile Management",
            icon: "las la-user-cog",
            link: "/profile",
          }
        ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
            // CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          ],
          {
            id: "companyDetails",
            label: "Company Details",
            icon: "ri-settings-5-line",
            link: "/company-details",
          }
        ),
        ...addLayoutData(
          [
            CompanyTypeEnum.PLATFORM_ADMIN,
            CompanyTypeEnum.SHIPMENT,
            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
            CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
            CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
          ],
          {
            id: "wallet",
            label: "Wallet",
            icon: "las la-wallet",
            link: "/wallets",
          }
        ),
      ];
      break;

    default:
      commonMenuItems = [
        ...commonMenuItems,
      ]
      break;
  }

  // const commonMenuItems = [
  //   ...(isModuleSelected
  //     ? [
  //         {
  //           label: "Menu",
  //           isHeader: true,
  //         },
  //         {
  //           id: "dashboard",
  //           label: "Dashboard",
  //           icon: "las la-house-damage",
  //           link: "/dashboard",
  //         },
  //         {
  //           label: "Pages",
  //           isHeader: true,
  //         },
  //       ]
  //     : [
  //         {
  //           label: user?.company?.name,
  //           isHeader: true,
  //           isSelected: true,
  //         },
  //       ]),
  //   ...(selected_module === "awms"
  //     ? []
  //     : selected_module === "ermcs"
  //     ? []
  //     : []),
  // ];

  // Wallet menu item that appears in both modules
  // Combine menu items
  const menuItems: any = [
    ...commonMenuItems,
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;
