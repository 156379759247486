import React from 'react';
import { Modal } from 'react-bootstrap';

interface ViewCertDetailsProps {
  isShow: boolean;
  handleClose: () => void;
  certDetails?: {
    certification_number: string;
    companyName: string;
    issuingAuthority: string;
    expiresAt: string;
    target_name: string;
    issued_at: string;
    title: string;
    createdBy:string;
    status:string;

  };
}

const ViewCertDetails: React.FC<ViewCertDetailsProps> = ({
  isShow,
  handleClose,
  certDetails
}) => {
  return (
    <Modal show={isShow} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Certificate Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {certDetails ? (
          <div className="certificate-details" style={{ marginTop: 20 }}>
            <div className="" style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: 10 }}>
              <strong style={{ width: 125 }}> Cert Title:</strong> <div>{certDetails.title}</div>
            </div>
            <div className="" style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: 10 }}>
              <strong style={{ width: 125 }} >Certificate No:</strong> <div>{certDetails.certification_number}</div>
            </div>
            <div style={{width:"100%", display:"flex", flexDirection:"row" , marginBottom:10}}>
              <strong style={{width:125}} >Status</strong> <div className='badge bg-warning-subtle text-warning p-2'>{certDetails.status}</div>
            </div>
            <div className="" style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: 10 }}>
              <strong style={{ width: 125 }} >Cert Date:</strong> <div>{certDetails.issued_at}</div>
            </div>
            <div className="" style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: 10 }}>
              <strong style={{ width: 125 }} >Expires Date:</strong> <div>{certDetails.expiresAt}</div>
            </div>
            <div className="" style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: 10 }}>
              <strong style={{ width: 125 }} >Facility Name:</strong> <div>{certDetails.target_name}</div>
            </div>
            <div className="" style={{width:"100%", display:"flex", flexDirection:"row" , marginBottom:10}}>
              <strong style={{width:125}} >Issued By:</strong> <div>{certDetails.createdBy}</div>
            </div>
          </div>
        ) : (
          <p>No certificate details available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
      <button type="button" className="btn btn-success">
           Pay Now
        </button>
        <button
          type="button"
          className="btn btn-light"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewCertDetails;