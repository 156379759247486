import { AxiosResponse } from "axios";
import { APIClient } from "./api_helper";
import { UserTypeEnum } from "./enums/user.enum";
import { LoginDto } from "./models/auth.model";

import * as url from "./url_helper";
import { FACILITIES_ENUM } from "./enums/facility.enum";

const api = new APIClient();
// Gets the logged in user data from local session

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) =>
  api.create(
    process.env.REACT_APP_API_URL + url.POST_FAKE_PASSWORD_FORGET,
    data
  );

// Edit profile
export const postJwtProfile = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) =>
  api.update(
    process.env.REACT_APP_API_URL + url.POST_EDIT_PROFILE + "/" + data.idx,
    data
  );

// Retrieve Industries Method
export const getIndustries = (url: any) => {
  return api.get(process.env.REACT_APP_API_URL + url, {}).catch((err: any) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Register Method
export const postJwtRegister = async (url: any, data: any) => {
  return api
    .create(process.env.REACT_APP_API_URL + url, data)
    .catch((err: any) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = (data: LoginDto) =>
  api.create(process.env.REACT_APP_API_URL + url.POST_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) =>
  api.create(
    process.env.REACT_APP_API_URL + url.POST_FAKE_JWT_PASSWORD_FORGET,
    data
  );

// postSocialLogin
export const postSocialLogin = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.SOCIAL_LOGIN, data);

export const addClientWasteNotifications = (data: any) =>
  api.create(
    process.env.REACT_APP_API_URL + url.ADD_CLIENT_WASTE_NOTIFICATIONS,
    data
  );
export const getClientWasteNotifications = () =>
  api.get(
    process.env.REACT_APP_API_URL + url.GET_CLIENT_WASTE_NOTIFICATIONS,
    null
  );
export const editClientWasteNotifications = (data: any, id: string) =>
  api.update(
    process.env.REACT_APP_API_URL +
      `${url.EDIT_CLIENT_WASTE_NOTIFICATIONS}/${id}`,
    data
  );
export const deleteClientWasteNotifications = (id: any) =>
  api.delete(
    process.env.REACT_APP_API_URL + url.DELETE_CLIENT_WASTE_NOTIFICATIONS,
    { headers: { id } }
  );

export const getUsers = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_USERS, null);
export const addUsers = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.ADD_USERS, data);
export const editUsers = (data: any) =>
  api.update(process.env.REACT_APP_API_URL + url.EDIT_USERS, data);
export const deleteUsers = (id: any) =>
  api.delete(process.env.REACT_APP_API_URL + url.DELETE_USERS, {
    headers: { id },
  });

export const getShips = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_SHIPS, null);
export const addShips = async (data: any) => {
  const response = api.create(
    process.env.REACT_APP_API_URL + url.ADD_SHIPS,
    data
  );
  return response;
};
export const editShip = (data: any) =>
  api.put(process.env.REACT_APP_API_URL + `${url.EDIT_SHIPS}/${data.id}`, data);
export const deleteShip = (id: any) =>
  api.delete(process.env.REACT_APP_API_URL + `${url.DELETE_SHIPS}/${id}`, {
    header: { id },
  });
export const activateShip = (id: any) =>
  api.delete(
    process.env.REACT_APP_API_URL + `${url.DELETE_SHIPS}/${id}/activate`,
    { header: { id } }
  );
export const deactivateShip = (id: any) =>
  api.delete(
    process.env.REACT_APP_API_URL + `${url.DELETE_SHIPS}/${id}/deactivate`,
    { header: { id } }
  );

export const getFacilities = async (userType: string) => {
  const returnData: AxiosResponse<any, any>[] = [];

  if (
    userType === UserTypeEnum.PLATFORM_STAKEHOLDER ||
    userType === UserTypeEnum.NIMASA ||
    userType === UserTypeEnum.TechnicalAdmin
  )
    returnData.push(await getOilPlatforms());

  if (
    userType === UserTypeEnum.NPA ||
    userType === UserTypeEnum.PRF ||
    userType === UserTypeEnum.SHIP_STAKEHOLDER ||
    userType === UserTypeEnum.TechnicalAdmin
  )
    returnData.push(await getPortOfOperations());
  if (
    userType === UserTypeEnum.WMP ||
    userType === UserTypeEnum.NIMASA ||
    userType === UserTypeEnum.PLATFORM_STAKEHOLDER ||
    userType === UserTypeEnum.TechnicalAdmin
  )
    returnData.push(await getWasteManagements());
  returnData.push(await getShips());
  // console.log("returnData", returnData);
  return returnData;
};
export const addFacility = async (data: any) => {
  if (data.type === FACILITIES_ENUM.SHIP)
    return api.create(process.env.REACT_APP_API_URL + url.ADD_SHIPS, data);
  if (data.type === FACILITIES_ENUM.OIL_PLATFORM) {
    console.log("in here");
    const result = api.create(
      process.env.REACT_APP_API_URL + url.ADD_OIL_PLATFORMS,
      data
    );
    console.log(await result);
    return result;
  }
  if (data.type === FACILITIES_ENUM.WASTE_FACILITY)
    return api.create(
      process.env.REACT_APP_API_URL + url.ADD_WASTE_MANAGEMENTS,
      data
    );
  if (data.type === FACILITIES_ENUM.PORT_OF_OPERATION)
    return api.create(
      process.env.REACT_APP_API_URL + url.ADD_PORT_OF_OPERATIONS,
      data
    );
  if (data.type === FACILITIES_ENUM.PORT_RECEPTION_FACILITY)
    return api.create(
      process.env.REACT_APP_API_URL + url.ADD_PORT_RECEPTIONS,
      data
    );
};
export const editFacility = (data: any) =>
  api.update(process.env.REACT_APP_API_URL + url.EDIT_FACILITIES, data);
export const deleteFacility = (id: any) =>
  api.delete(process.env.REACT_APP_API_URL + url.DELETE_FACILITIES, {
    headers: { id },
  });

export const getCompanies = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_COMPANIES, null);
export const addCompanies = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.ADD_COMPANIES, data);
export const editCompany = (data: any) =>
  api.update(process.env.REACT_APP_API_URL + url.EDIT_COMPANIES, data);
export const activateCompany = (data: any) =>
  api.put(
    process.env.REACT_APP_API_URL +
      `${url.EDIT_COMPANIES}/${data?.id}/activate`,
    data
  );
export const deactivateCompany = (data: any) =>
  api.put(
    process.env.REACT_APP_API_URL +
      `${url.EDIT_COMPANIES}/${data?.id}/deactivate`,
    data
  );
export const deleteCompany = (id: any) =>
  api.delete(process.env.REACT_APP_API_URL + url.DELETE_COMPANIES, {
    headers: { id },
  });

export const getWalletMetrics = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_WALLET_METRICS, null);
export const getWalletHistories = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_WALLET_HISTORIES, null);
export const getWallets = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_WALLETS, null);
export const addWallets = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.ADD_WALLETS, data);
export const editWallet = (data: any) =>
  api.update(process.env.REACT_APP_API_URL + url.EDIT_WALLETS, data);
export const deleteWallet = (id: any) =>
  api.delete(process.env.REACT_APP_API_URL + url.DELETE_WALLETS, {
    headers: { id },
  });

export const getOilPlatforms = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_OIL_PLATFORMS, null);
export const addOilPlatforms = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.ADD_OIL_PLATFORMS, data);
export const editOilPlatform = (data: any, id: string) =>
  api.update(
    process.env.REACT_APP_API_URL + `${url.EDIT_OIL_PLATFORMS}/${id}`,
    data
  );
export const deleteOilPlatform = (id: any) =>
  api.delete(
    process.env.REACT_APP_API_URL + `${url.DELETE_OIL_PLATFORMS}/${id}`,
    { headers: { id } }
  );

export const getRigs = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_RIGS, null);
export const addRig = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.ADD_RIGS, data);
export const editRig = (data: any) =>
  api.update(process.env.REACT_APP_API_URL + url.EDIT_RIGS, data);
// export const deleteRig = (id: any) =>
//   api.delete(process.env.REACT_APP_API_URL + url.DELETE_RIGS, { headers: { id } });

// export const getPortReceptions = (query?: any) =>
//   api.get(process.env.REACT_APP_API_URL + `${url.GET_PORT_RECEPTIONS}?${query}`, null);
// export const addPortReception = (data: any) =>
//   api.create(process.env.REACT_APP_API_URL + url.ADD_PORT_RECEPTIONS, data);
// export const editPortReception = (data: any) =>
//   api.update(process.env.REACT_APP_API_URL + url.EDIT_PORT_RECEPTIONS, data);
// export const deletePortReception = (id: any) =>
//   api.delete(process.env.REACT_APP_API_URL + url.DELETE_PORT_RECEPTIONS, { headers: { id } });

// export const getPortOfOperations = () =>
//   api.get(process.env.REACT_APP_API_URL + url.GET_PORT_OF_OPERATIONS, null);
// export const addPortOfOperations = (data: any) =>
//   api.create(process.env.REACT_APP_API_URL + url.ADD_PORT_OF_OPERATIONS, data);
// export const editPortOfOperations = (data: any, id: string) =>
//   api.update(process.env.REACT_APP_API_URL + `${url.EDIT_PORT_OF_OPERATIONS}/${id}`, data);
// export const deletePortOfOperations = (id: any) =>
//   api.delete(process.env.REACT_APP_API_URL + url.DELETE_PORT_OF_OPERATIONS, { headers: { id } });

// export const getWasteManagements = (query?: any) =>
//   api.get(process.env.REACT_APP_API_URL + `${url.GET_WASTE_MANAGEMENTS}?${query}`, null);
// export const addWasteManagement = (data: any) =>
//   api.create(process.env.REACT_APP_API_URL + url.ADD_WASTE_MANAGEMENTS, data);
// export const editWasteManagement = (data: any, id: string) =>
//   api.update(process.env.REACT_APP_API_URL + `${url.EDIT_WASTE_MANAGEMENTS}/${id}`, data);
// export const deleteWasteManagement = (id: any) =>
// api.delete(process.env.REACT_APP_API_URL + url.DELETE_WASTE_MANAGEMENTS, { headers: { id } });

export const addCertApplication = (data: any) =>
  api.create(
    process.env.REACT_APP_API_SECONDARY_URL + url.ADD_CERT_APPLICATION,
    data
  );
export const getCertApplications = (params: any) => {
  const query = new URLSearchParams(params).toString();
  return api.get(
    process.env.REACT_APP_API_SECONDARY_URL +
      `${url.GET_CERT_APPLICATION}?${query}`,
    null
  );
};
export const editCertApplications = (data: any, id: string) =>
  api.update(
    process.env.REACT_APP_API_SECONDARY_URL +
      `${url.EDIT_CERT_APPLICATION}/${id}`,
    data
  );
export const approveCertApplication = (id: string) =>
  api.update(
    process.env.REACT_APP_API_SECONDARY_URL +
      `${url.APPROVE_APPLICATION}/${id}/approve`,
    null
  );
export const rejectCertApplication = (id: string) =>
  api.update(
    process.env.REACT_APP_API_SECONDARY_URL +
      `${url.REJECT_APPLICATION}/${id}/revoke`,
    null
  );

export const addLicenseApplication = (data: any) =>
  api.create(process.env.REACT_APP_API_SECONDARY_URL + url.ADD_LICENSE_APPLICATION, data);
export const getLicenseApplications = (params: any) => {
  const query = new URLSearchParams(params).toString();
  return api.get(
    process.env.REACT_APP_API_SECONDARY_URL +
      `${url.GET_LICENSE_APPLICATIONS}?${query}`,
    null
  );
};
export const approveLicenseApplication = (id: string) =>
  api.update(
    process.env.REACT_APP_API_SECONDARY_URL +
      `${url.APPROVE_LICENSE_APPLICATION}/${id}/approve`,
    null
  );
export const rejectLicenseApplication = (id: string) =>
  api.update(
    process.env.REACT_APP_API_SECONDARY_URL +
      `${url.REJECT_LICENSE_APPLICATION}/${id}/revoke`,
    null
  );

export const getCertificates = (params: any) => {
  const query = new URLSearchParams(params).toString();
  return api.get(
    process.env.REACT_APP_API_SECONDARY_URL +
      `${url.GET_CERTIFICATES}?${query}`,
    null
  );
};

export const getLicenses = (params: any) => {
  const query = new URLSearchParams(params).toString();
  return api.get(process.env.REACT_APP_API_SECONDARY_URL +`${url.GET_LICENSES}?${query}`, null);
};

export const deleteRig = (id: any) =>
  api.delete(process.env.REACT_APP_API_URL + url.DELETE_RIGS, {
    headers: { id },
  });

export const getPortReceptions = (query?: any) =>
  api.get(
    process.env.REACT_APP_API_URL + `${url.GET_PORT_RECEPTIONS}?${query}`,
    null
  );
export const addPortReception = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.ADD_PORT_RECEPTIONS, data);
export const editPortReception = (data: any) =>
  api.put(
    process.env.REACT_APP_API_URL + `${url.EDIT_PORT_RECEPTIONS}/${data?.id}`,
    data
  );
export const deletePortReception = (id: any) =>
  api.delete(
    process.env.REACT_APP_API_URL + `${url.DELETE_PORT_RECEPTIONS}/${id}`,
    { headers: { id } }
  );

export const getPortOfOperations = () =>
  api.get(process.env.REACT_APP_API_URL + url.GET_PORT_OF_OPERATIONS, null);
export const addPortOfOperations = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.ADD_PORT_OF_OPERATIONS, data);
export const editPortOfOperations = (data: any, id: string) =>
  api.put(
    process.env.REACT_APP_API_URL + `${url.EDIT_PORT_OF_OPERATIONS}/${id}`,
    data
  );

export const deletePortOfOperations = (id: any) =>
  api.delete(
    process.env.REACT_APP_API_URL + `${url.DELETE_PORT_OF_OPERATIONS}/${id}`,
    { headers: { id } }
  );

export const getWasteManagements = (query?: any) =>
  api.get(
    process.env.REACT_APP_API_URL + `${url.GET_WASTE_MANAGEMENTS}?${query}`,
    null
  );
export const addWasteManagement = (data: any) =>
  api.create(process.env.REACT_APP_API_URL + url.ADD_WASTE_MANAGEMENTS, data);
export const editWasteManagement = (data: any, id: string) =>
  api.put(
    process.env.REACT_APP_API_URL + `${url.EDIT_WASTE_MANAGEMENTS}/${id}`,
    data
  );
export const deleteWasteManagement = (id: any) =>
  api.delete(
    process.env.REACT_APP_API_URL + `${url.DELETE_WASTE_MANAGEMENTS}/${id}`,
    { headers: { id } }
  );
export const activateWasteManagement = (id: any) =>
  api.put(
    process.env.REACT_APP_API_URL +
      `${url.ACTIVATE_WASTE_MANAGEMENTS}/${id}/activate`,
    {
      headers: { id },
    }
  );
export const deActivateWasteManagement = (id: any) =>
  api.put(
    process.env.REACT_APP_API_URL +
      `${url.DEACTIVATE_WASTE_MANAGEMENTS}/${id}/deactivate`,
    {
      headers: { id },
    }
  );
