import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";

interface appChatProps {
  // appId?: string;
  attachmentId: string;
  index: number;
  isLoadingOtherAttachments: boolean;
  handleViewAttachment: (
    setIsLoadingAttachment: (value: React.SetStateAction<boolean>) => void
  ) => Promise<void>;
  handleDownloadAttachment: (
    setIsLoadingAttachment: (value: React.SetStateAction<boolean>) => void
  ) => Promise<void>;
}

const AttachmentView: React.FC<appChatProps> = ({
  attachmentId,
  index,
  handleViewAttachment,
  handleDownloadAttachment,
  isLoadingOtherAttachments,
}) => {
  const [isLoadingAttachment, setIsLoadingAttachment] = useState(false);

  const onViewAttachment = async () => {
    handleViewAttachment(setIsLoadingAttachment);
  };

  const onDownloadAttachment = async () => {
    handleDownloadAttachment(setIsLoadingAttachment);
  };

  return (
    attachmentId && (
      <div
        key={attachmentId}
        className="d-flex align-items-center bg-light p-2 rounded"
      >
        <i className="ri-file-line me-2 text-muted"></i>
        <span className="me-3">
          {isLoadingAttachment ? <Spinner size="sm" /> : "Attachment"}{" "}
          {index + 1}
        </span>
        <div className="d-flex gap-1">
          <Button
            variant="link"
            size="sm"
            onClick={onViewAttachment}
            className="p-0 text-primary"
            disabled={isLoadingAttachment || isLoadingOtherAttachments}
          >
            <i className="ri-eye-line me-1"></i>
            View
          </Button>
          <div className="vr mx-1"></div>
          <Button
            variant="link"
            size="sm"
            disabled={isLoadingAttachment || isLoadingOtherAttachments}
            onClick={onDownloadAttachment}
            className="p-0 text-primary"
          >
            <i className="ri-download-line me-1"></i>
            Download
          </Button>
        </div>
      </div>
    )
  );
};

export default AttachmentView;
