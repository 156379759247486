import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { useSelector } from "react-redux";
import Dashboard from "../pages/Dashboard/Index";
import ErmcsDashboard from "../pages/DashboardErmcs/Index";
import { Navigate } from "react-router-dom";

const DashboardSelector = () => {
  const selectAccount = createSelector(
    (state: any) => state.Login,
    (user: any) => ({ selectedModule: user.selected_module })
  );

  const { selectedModule } = useSelector(selectAccount);
  return selectedModule === "awms" ? (
    <Dashboard />
  ) : selectedModule === "ermcs" ? (
    <ErmcsDashboard />
  ) : selectedModule === 'cds' ? (
    <div style={{ marginTop: 100 }}>Dashboard for cds</div>
  ) : <Navigate to={'/'} />;
};

export default DashboardSelector;
