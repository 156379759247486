import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import {
  getPortReceptions as getPortReceptionsApi,
  addPortReception as addPortReceptionsApi,
  editPortReception as editPortReceptionsApi,
  deletePortReception as deletePortReceptionsApi,
  getPortOfOperations as getPortOfOperationsApi,
  addPortOfOperations as addPortOfOperationsApi,
  editPortOfOperations as editPortOfOperationsApi,
  deletePortOfOperations as deletePortOfOperationsApi,
  getWasteManagements as getWasteManagementsApi,
  addWasteManagement as addWasteManagementsApi,
  editWasteManagement as editWasteManagementsApi,
  deleteWasteManagement as deleteWasteManagementsApi,
  deActivateWasteManagement as deActivateWasteManagementApi,
  activateWasteManagement as activateWasteManagementApi
} from "../../helpers/fakebackend_helper";

export const getPortReceptions = createAsyncThunk(
  "user/getPortReceptions",
  async (query?: any) => {
    try {
      const response = getPortReceptionsApi(query);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addPortReceptions = createAsyncThunk(
  "user/addPortReceptions",
  async (user: any) => {
    try {
      const response = addPortReceptionsApi(user);
      const data: any = await response;
      // return data;
      if (
        data?.statusCode &&
        (data.statusCode !== 200 || data?.statusCode !== 201)
      ) {
        toast.error("Port Reception Added Failed", { autoClose: 2000 });
        throw new Error(data?.message, data);
      } else {
        toast.success("Port Reception Added Successfully", { autoClose: 2000 });
        return data;
      }
    } catch (error) {
      toast.error("Port Reception Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const editPortReceptions = createAsyncThunk(
  "user/editPortReceptions",
  async (user: any) => {
    try {
      const response = editPortReceptionsApi(user);
      const data: any = await response;
      toast.success("Port Reception edited Successfully", { autoClose: 2000 });
      if (
        data?.statusCode &&
        (data.statusCode !== 200 || data?.statusCode !== 201)
      ) {
        toast.error("PortReception edited Failed", { autoClose: 2000 });
        throw data;
      } else {
        return data;
      }
    } catch (error) {
      toast.error("PortReception edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deletePortReceptions = createAsyncThunk(
  "user/deletePortReceptions",
  async (id: any) => {
    try {
      const response = deletePortReceptionsApi(id);
      toast.success("PortReception deleted Successfully", { autoClose: 2000 });
      return { id, ...response };
    } catch (error) {
      toast.error("PortReception deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

// Port of Operations
export const getPortOfOperations = createAsyncThunk(
  "user/getPortOfOperations",
  async () => {
    try {
      const response = getPortOfOperationsApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addPortOfOperations = createAsyncThunk(
  "user/addPortOfOperations",
  async (user: any) => {
    try {
      const response = addPortOfOperationsApi(user);
      const data: any = await response;
      toast.success("Port Of Operation Added Successfully", {
        autoClose: 2000,
      });
      if (
        data?.statusCode &&
        (data.statusCode !== 200 || data?.statusCode !== 201)
      ) {
        toast.error("PortReception edited Failed", { autoClose: 2000 });
        throw data;
      } else {
        return data;
      }
    } catch (error) {
      toast.error("Invoices Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const editPortOfOperations = createAsyncThunk(
  "user/editPortOfOperations",
  async (payload: any) => {
    try {
      const response = editPortOfOperationsApi(payload, payload?.id);
      const data = await response;
      toast.success("PortOfOperation edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      console.log(error)
      toast.error("PortOfOperation edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deletePortOfOperations = createAsyncThunk(
  "user/deletePortOfOperations",
  async (id: any) => {
    try {
      const response = deletePortOfOperationsApi(id);
      toast.success("PortOfOperation deleted Successfully", {
        autoClose: 2000,
      });
      return { id, ...response };
    } catch (error) {
      toast.error("PortOfOperation deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

// Waste Managements
export const getWasteManagements = createAsyncThunk(
  "user/getWasteManagements",
  async (query?: any) => {
    try {
      const response = getWasteManagementsApi(query);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addWasteManagements = createAsyncThunk(
  "user/addWasteManagements",
  async (user: any) => {
    try {
      const response = addWasteManagementsApi(user);
      const data = await response;
      toast.success("WasteManagement Added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("WasteManagement Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const editWasteManagements = createAsyncThunk(
  "user/editWasteManagements",
  async (payload: any) => {
    try {
      const response = editWasteManagementsApi(payload, payload?.id);
      const data = await response;
      toast.success("WasteManagement edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("WasteManagement edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteWasteManagements = createAsyncThunk(
  "user/deleteWasteManagements",
  async (id: any) => {
    try {
      const response = deleteWasteManagementsApi(id);
      toast.success("WasteManagement deleted Successfully", {
        autoClose: 2000,
      });
      return { id, ...response };
    } catch (error) {
      toast.error("WasteManagement deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const activateWasteManagements = createAsyncThunk(
  "user/activateWasteManagements",
  async (id: any) => {
    try {
      const response = activateWasteManagementApi(id);
      toast.success("WasteManagement deleted Successfully", {
        autoClose: 2000,
      });
      return { id, ...response };
    } catch (error) {
      toast.error("WasteManagement deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deActivateWasteManagements = createAsyncThunk(
  "user/deActivateWasteManagements",
  async (id: any) => {
    try {
      const response = deActivateWasteManagementApi(id);
      toast.success("WasteManagement deleted Successfully", {
        autoClose: 2000,
      });
      return { id, ...response };
    } catch (error) {
      toast.error("WasteManagement deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
