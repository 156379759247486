import React, { Fragment, useEffect, useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Card, Container, Row, Col } from "react-bootstrap";
import logoDark from "../../../assets/images/logo-dark.png";
import logoLight from "../../../assets/images/logo.png";
import NIMASSALOGO from "../../../assets/images/NIMASSA-LOGO.png";
import NPALOGO from "../../../assets/images/npa2.webp";
import { useNavigate, useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../../helpers/url_helper";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { CompanyTypeEnum } from "../../../helpers/enums/company.enum";
import { WasteTypeKey } from "../../../helpers/enums/visitRequisition.enum";
import moment from "moment";
import { Link } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";

const WasteComplianceCertificate = () => {
  document.title =
    "Waste Notification Details | Maritime Waste Management Admin & Dashboard Platform";
  const params = useParams();
  let navigate = useNavigate();

  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { tokens, company } = useSelector(selectAccountAndLogin);

  const [state, setState] = useState<any>({});

  const [wasteTypes, setWasteTypes] = useState<{ [key: string]: any }>({});
  const [wasteDetails, setWasteDetails] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setState(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [dispatch, navigate, params.id, tokens]);

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/waste-type`
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        const responseBody = JSON.parse(JSON.stringify(response));
        const newResponse: { [key: string]: any } = {};
        const newResponseBody: { [key: string]: any } = {};

        if (responseBody) {
          Object.keys(responseBody).filter((res) => {
            const eachWasteType = responseBody[res as WasteTypeKey];
            if (
              eachWasteType &&
              eachWasteType.category.includes(company?.companyType)
            ) {
              const name = responseBody[res].name;
              newResponse[res] = name;
              newResponseBody[name] = responseBody[res];
            }
            return res;
          });
        }
        setWasteTypes(JSON.parse(JSON.stringify(newResponse)));
        setWasteDetails(JSON.parse(JSON.stringify(newResponseBody)));
        // setWasteDetails(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [company?.companyType, navigate]);

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(company?.companyType);
  };

  const addLayoutData = (
    requiredTypes: CompanyTypeEnum[],
    child: any,
    alt: any = null
  ) => {
    return hasPermission(requiredTypes) ? child : alt;
  };

  const getMeasurement = (wasteDetails: any, key: any) => {
    return wasteDetails[key] ? wasteDetails[key].measurement : "";
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ overflowX: "scroll", paddingTop: "10px" }}>
          <BreadCrumb
            pageTitle="Waste Notification"
            title="Compliance certificate"
          />
          <Card
            id="demo"
            style={{
              border: "5px solid gold",
              borderStyle: "ridge",
              position: "relative",
              minWidth: '1132px'
            }}
          >
            <div
              style={{
                background: `url(${addLayoutData(
                  [
                    CompanyTypeEnum.PLATFORM_ADMIN,
                    CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                    CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                    CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                  ],
                  NIMASSALOGO,
                  NPALOGO
                )})`,
                width: "100%",
                height: "100%",
                // zIndex: 0.3,
                position: "absolute",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
                backgroundBlendMode: "lighten",
                backgroundSize: "750px",
                backgroundClip: "padding-box",
                // transform: "translate(-50%, -50%)",
                opacity: 0.25,
              }}
            ></div>
            <Card.Body className="pt-5" style={{ zIndex: 1 }}>
              {/* <Row className="p-4"> */}
              {/* <Col lg={9}> */}

              <div className="mb-4">
                <div
                  className="mx-auto"
                  style={{
                    maxWidth: "300px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {addLayoutData(
                    [
                      CompanyTypeEnum.PLATFORM_ADMIN,
                      CompanyTypeEnum.SHIPMENT,
                      CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                      CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                    ],
                    <Fragment>
                      <img
                        src={NPALOGO}
                        className="card-logo card-logo-dark"
                        alt="logo dark"
                        height="45"
                        style={{ height: "45px" }}
                      />
                      <img
                        src={NPALOGO}
                        className="card-logo card-logo-light"
                        alt="logo light"
                        height="45"
                        style={{ height: "45px" }}
                      />
                    </Fragment>
                  )}
                  {addLayoutData(
                    [
                      CompanyTypeEnum.PLATFORM_ADMIN,
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                      CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                      CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                    ],
                    <Fragment>
                      <img
                        src={NIMASSALOGO}
                        className="card-logo card-logo-dark"
                        alt="logo dark"
                        height="25"
                        style={{ height: "45px" }}
                      />
                      <img
                        src={NIMASSALOGO}
                        className="card-logo card-logo-light"
                        alt="logo light"
                        height="25"
                        style={{ height: "45px" }}
                      />
                    </Fragment>
                  )}
                  {/* <img
                    src={logoDark}
                    className="card-logo card-logo-dark"
                    alt="logo dark"
                    height="17"
                  />
                  <img
                    src={logoLight}
                    className="card-logo card-logo-light"
                    alt="logo light"
                    height="17"
                  /> */}
                </div>
              </div>
              <h3 className="fw-bold mb-4 text-center">
                Certificate Of Disposal
              </h3>
              <p className="text-muted mb-3 fw-medium fs-14 text-center">
                This Certificate of Disposal is issued to:
              </p>
              <h2 className="fw-bold mb-3 text-center text-uppercase">
                {state?.company?.name}
              </h2>
              <p className="text-muted mb-3 fw-medium fs-14 text-center">
                for the disposal of:
              </p>
              <p
                className="text-muted mb-3 fw-medium fs-14 text-center"
                style={{ maxWidth: "400px", margin: "auto" }}
              >
                {state.wasteDeclarations &&
                  state.wasteDeclarations.map((wd: any, index: number) => (
                    <span key={wd.id} className="fw-medium">
                      {wd.wasteType}: ({wd.quantity}){" "}
                      {getMeasurement(wasteDetails, wd["wasteType"])}{" "}
                      {state.wasteDeclarations.length - 1 === index ? "" : "- "}
                    </span>
                  ))}
              </p>
              <Row
                style={{
                  maxWidth: "800px",
                  margin: "auto",
                  justifyContent: "center",
                  fontSize: "24px",
                  fontWeight: 700,
                }}
              >
                <Col lg={7} className="text-end">
                  <span
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                    }}
                  >
                    {state.portReceptionFacility ? (
                      "Port Reception Facility:"
                    ) : state.wasteManagementProcessingFacility ? (
                      "Waste Management Company:"
                    ) : (
                      <small className="text-muted">--</small>
                    )}
                  </span>
                </Col>
                <Col lg={5} className="text-start">
                  <p className="text-muted mb-4 fw-medium fs-14 text-start">
                    <span
                      className="text-uppercase"
                      style={{
                        fontSize: "24px",
                        fontWeight: 700,
                      }}
                    >
                      {state.portReceptionFacility ? (
                        state.portReceptionFacility.name
                      ) : state.wasteManagementProcessingFacility ? (
                        state.wasteManagementProcessingFacility.name
                      ) : (
                        <small className="text-muted">--</small>
                      )}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      <strong>
                        {state.portReceptionFacility ? (
                          `${state.portReceptionFacility.address}`
                        ) : state.wasteManagementProcessingFacility ? (
                          `${state.wasteManagementProcessingFacility.address}`
                        ) : (
                          <small className="text-muted">--</small>
                        )}
                      </strong>

                      {state.portReceptionFacility ? (
                        `, ${state.portReceptionFacility.state}`
                      ) : state.wasteManagementProcessingFacility ? (
                        `, ${state.wasteManagementProcessingFacility.state}`
                      ) : (
                        <small className="text-muted">--</small>
                      )}
                    </span>
                  </p>
                </Col>
              </Row>
              <p
                className="mb-3 fw-medium fs-14 text-center"
                style={{
                  // maxWidth: "900px",
                  margin: "auto",
                  padding: "0 40px",
                }}
              >
                {addLayoutData(
                  [
                    CompanyTypeEnum.PLATFORM_ADMIN,
                    CompanyTypeEnum.SHIPMENT,
                    CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                    CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                  ],
                  <span>NPA</span>
                )}
                {addLayoutData(
                  [
                    CompanyTypeEnum.PLATFORM_ADMIN,
                    CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                    CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                    CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                  ],
                  <span>NIMASA</span>
                )}
                <span>
                  {" "}
                  certifies that the transport, storage, processing and disposal
                  methods for the above named materials are in accordance with
                  all applicable state and Federal Environmental Protection Laws
                  and Regulations
                  {/* for the above named materials are in accordance with all
                  Federal and State Regulations, DOT Regulations and all
                  applicable state and Federal Environmental Protection Agency
                  (EPA) and Department of Toxic Substance Control (DTSC) Laws{" "} */}
                </span>
              </p>
              <Row className="g-4" style={{ margin: "auto 40px" }}>
                <Col lg={4} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Work Order:{" "}
                    <small>
                      {String(state?.id).substring(0, 28).toUpperCase()}
                    </small>
                  </p>
                </Col>

                <Col lg={4} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Invoice No:{" "}
                    <small>
                      {String(state?.bill?.id).substring(0, 28).toUpperCase()}
                    </small>
                  </p>
                </Col>

                <Col lg={4} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Waste Received on:{" "}
                    {moment(state?.scheduledCollectionDate).format(
                      "MMM Do YYYY"
                    )}
                  </p>
                </Col>
              </Row>

              <Row className="g-4" style={{ margin: "auto 40px" }}>
                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Certified By:{" "}
                    <small>
                      {String(state?.id).substring(0, 28).toUpperCase()}
                    </small>
                  </p>
                </Col>

                <Col lg={6} className="col-6">
                  <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                    Signature:{" "}
                    {/* {moment(state?.scheduledCollectionDate).format(
                      "MMM Do YYYY"
                    )} */}
                  </p>
                </Col>
              </Row>

              <div
                style={{
                  position: "absolute",
                  top: "40px",
                  left: "40px",
                  fontWeight: 900,
                }}
              >
                {moment(state?.createDateTime).format("MMMM D, YYYY")}
              </div>
              {addLayoutData(
                [
                  CompanyTypeEnum.PLATFORM_ADMIN,
                  CompanyTypeEnum.SHIPMENT,
                  CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                  CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                ],
                <div style={{ position: "absolute", top: 0, right: 0 }}>
                  <QRCode
                    value="https://app.mwp.ng/verify-certificate/5f5dfb48-d61d-44a6-86a6-73f941ab15c5"
                    logoImage={logoLight}
                    logoHeight={17}
                  />
                </div>
              )}
              {addLayoutData(
                [
                  CompanyTypeEnum.PLATFORM_ADMIN,
                  CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                  CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                  CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                ],
                <div style={{ position: "absolute", top: 0, right: 0 }}>
                  <QRCode
                    value="https://app.mwp.ng/verify-certificate/5f5dfb48-d61d-44a6-86a6-73f941ab15c5"
                    logoImage={logoLight}
                    logoHeight={17}
                  />
                </div>
              )}

              <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                <Link to="#" className="btn btn-info">
                  <i className="ri-printer-line align-bottom me-1"></i> Print
                </Link>
                <Link to="#" className="btn btn-primary">
                  <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                  Download
                </Link>
              </div>
            </Card.Body>
          </Card>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WasteComplianceCertificate;
