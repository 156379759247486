import React, { Fragment, useState, useRef } from "react";
import { Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GET_CERT_APPLICATION_NOTIFICATIONS } from "../helpers/url_helper";
import axios from "axios";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface appChatProps {
  appId?: string;
  getData: () => Promise<void>;
}

const ApplicationChatBox: React.FC<appChatProps> = ({ appId, getData }) => {
  let navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Login,
    (login: any) => ({
      tokens: login.tokens,
    })
  );

  const { tokens } = useSelector(selectAccountAndLogin);

  const postMessage = async () => {
    setLoading(true);
    try {
      // Create FormData to send both text and files
      const jsonData = {
        content: message || "this is the attachment",
        topic: "CERTIFICATION",
        topicId: appId || "",
      };
      const formData = new FormData();
      formData.append("content", message || "this is the attachment");
      formData.append("topic", "CERTIFICATION");
      formData.append("topicId", appId || "");

      // Append each file to the FormData
      attachments.forEach((file) => {
        formData.append(`files`, file);
      });

      if (attachments && attachments.length) {
        await axios.post(
          `${process.env.REACT_APP_API_SECONDARY_URL}${GET_CERT_APPLICATION_NOTIFICATIONS}/attachments`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${tokens?.accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_SECONDARY_URL}${GET_CERT_APPLICATION_NOTIFICATIONS}`,
          jsonData,
          {
            headers: {
              Authorization: `Bearer ${tokens?.accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
      }

      await getData();
      toast.success("Message and Attachments Added Successfully", {
        autoClose: 2000,
      });
      setMessage("");
      setAttachments([]); // Clear attachments after successful upload
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset file input
      }
    } catch (error: any) {
      toast.error("Message or Attachment Upload Failed", { autoClose: 2000 });
      if (typeof error === "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });
        navigate(process.env.PUBLIC_URL + "/logout");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Convert FileList to Array and update state
      setAttachments(Array.from(event.target.files));
    }
  };

  const handleAttachmentClick = () => {
    // Trigger file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const removeAttachment = (index: number) => {
    // Remove a specific attachment by index
    setAttachments((prev) => prev.filter((_, i) => i !== index));
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  return (
    <Fragment>
      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        multiple
        style={{ display: "none" }}
      />

      {/* Attachment preview */}
      {attachments.length > 0 && (
        <div className="mb-3">
          <div className="d-flex flex-wrap gap-2">
            {attachments.map((file, index) => (
              <div
                key={index}
                className="d-flex align-items-center bg-light p-2 rounded"
              >
                <span className="me-2">{file.name}</span>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => removeAttachment(index)}
                  className="text-danger p-0"
                >
                  <i className="ri-close-line"></i>
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mb-3">
        <div className="form-icon right">
          <Form.Control
            type="text"
            as="textarea"
            id="memberName"
            name="name"
            placeholder="Enter Your message"
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
          />
          <Col>
            <i className="ri-mail-unread-line"></i>
          </Col>
        </div>
      </div>
      <div className="hstack gap-2 justify-content-end">
        <Button
          type="button"
          className="btn btn-light"
          onClick={handleAttachmentClick}
        >
          <i className="ri-attachment-line me-1"></i>
          Attachments {attachments.length > 0 ? `(${attachments.length})` : ""}
        </Button>
        <Button
          onClick={postMessage}
          type="submit"
          className="btn btn-success"
          disabled={loading || (!message && attachments.length === 0)}
        >
          {loading ? "Sending..." : "Send"}
        </Button>
      </div>
    </Fragment>
  );
};

export default ApplicationChatBox;
