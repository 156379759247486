import React, { Fragment, useEffect, useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import logoDark from "../../../assets/images/logo-dark.png";
import logoLight from "../../../assets/images/logo-light.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../../helpers/url_helper";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { getWallets as onGetWallets } from "../../../slices/thunk";
import WasteNotificationChat from "../../../Common/WasteNotificationChat";
import WasteNotificationRecentActivities from "../../../Common/WasteNotificationRecentActivities";
import AddWasteNotificationInspections from "../../../Common/CrudModal/AddWasteNotificationInspection";
import AddWasteCollections from "../../../Common/CrudModal/AddWasteCollections";
import { CompanyTypeEnum } from "../../../helpers/enums/company.enum";
import MakePayments from "../../../Common/CrudModal/MakePayment";
import { PaymentStatusEnum } from "../../../helpers/enums/bill.enum";
import {
  InspectionStatusEnum,
  VisitationRequisitionStatusEnum,
  VisitationRequisitionTypeEnum,
  WasteCollectionStatusEnum,
  WasteTypeKey,
} from "../../../helpers/enums/visitRequisition.enum";
import ScheduleWasteCollection from "../../../Common/CrudModal/ScheduleWasteCollection";
import ScheduleInspection from "../../../Common/CrudModal/ScheduleInspection";
import moment from "moment";

const WasteNotificationDetails = () => {
  document.title =
    "Waste Notification Details | Maritime Waste Management Admin & Dashboard Platform";
  const params = useParams();
  let navigate = useNavigate();

  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { tokens, company } = useSelector(selectAccountAndLogin);

  const [state, setState] = useState<any>({});

  const [wasteTypes, setWasteTypes] = useState<{ [key: string]: any }>({});
  const [wasteDetails, setWasteDetails] = useState<{ [key: string]: any }>({});

  const [paymentWasteNotifications, setPaymentWasteNotifications] =
    useState<boolean>(false);
  const [inspectionWasteNotifications, setInspectionWasteNotifications] =
    useState<boolean>(false);
  const [
    scheduleWasteCollectionNotifications,
    setScheduleCollectionWasteNotifications,
  ] = useState<boolean>(false);
  const [scheduleInspectionNotifications, setScheduleInspectionNotifications] =
    useState<boolean>(false);
  const [collectionWasteNotifications, setCollectionWasteNotifications] =
    useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setState(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [dispatch, navigate, params.id, tokens]);

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/waste-type`
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        const responseBody = JSON.parse(JSON.stringify(response));
        const newResponse: { [key: string]: any } = {};
        const newResponseBody: { [key: string]: any } = {};

        if (responseBody) {
          Object.keys(responseBody).filter((res) => {
            const eachWasteType = responseBody[res as WasteTypeKey];
            if (
              eachWasteType &&
              eachWasteType.category.includes(company?.companyType)
            ) {
              const name = responseBody[res].name;
              newResponse[res] = name;
              newResponseBody[name] = responseBody[res];
            }
            return res;
          });
        }
        setWasteTypes(JSON.parse(JSON.stringify(newResponse)));
        setWasteDetails(JSON.parse(JSON.stringify(newResponseBody)));
        // setWasteDetails(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [company?.companyType, navigate]);

  const getMeasurement = (wasteDetails: any, key: any) => {
    return wasteDetails[key] ? wasteDetails[key].measurement : "";
  };

  const handleClosePayment = () => setPaymentWasteNotifications(false);

  const submitPayment = async (resetFunc: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/payment/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      dispatch(onGetWallets());
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setState(JSON.parse(JSON.stringify(response)));
      if (resetFunc) resetFunc();
      toast.success("Payment Successful", {
        autoClose: 2000,
      });
      handleClosePayment();
    } catch (error: any) {
      // console.error("error", error);
      console.log("typeof error", typeof error);
      if (typeof error == "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });

        navigate(process.env.PUBLIC_URL + "/logout");
      }
    }
  };

  const handleCloseInspection = () => setInspectionWasteNotifications(false);

  const handleCloseScheduleWasteCollection = () =>
    setScheduleCollectionWasteNotifications(false);

  const handleCloseScheduleInspection = () =>
    setScheduleInspectionNotifications(false);

  const submitInitiatedWasteCollection = async (data: any, resetFunc: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/initiate-collection/${params.id}`,
        data,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setState(JSON.parse(JSON.stringify(response)));
      if (resetFunc) resetFunc();
      toast.success("Collection Confirmed Successfully", {
        autoClose: 2000,
      });
      handleCloseWasteCollection();
    } catch (error: any) {
      toast.error("Collection Confirmation Failed", {
        autoClose: 2000,
      });
      if (resetFunc) resetFunc();
      handleCloseWasteCollection();
      // console.error("error", error);
      console.log("typeof error", typeof error);
      if (typeof error == "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });

        navigate(process.env.PUBLIC_URL + "/logout");
      }
    }
  };

  const submitScheduledWasteCollection = async (data: any, resetFunc: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/schedule-collection/${params.id}`,
        data,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setState(JSON.parse(JSON.stringify(response)));
      if (resetFunc) resetFunc();
      toast.success("Inspection Schedule Added Successfully", {
        autoClose: 2000,
      });
      handleCloseScheduleWasteCollection();
    } catch (error: any) {
      toast.error("Inspection Schedule Added Failed", {
        autoClose: 2000,
      });
      if (resetFunc) resetFunc();
      handleCloseScheduleWasteCollection();
      // console.error("error", error);
      console.log("typeof error", typeof error);
      if (typeof error == "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });

        navigate(process.env.PUBLIC_URL + "/logout");
      }
    }
  };

  const submitScheduledInspection = async (data: any, resetFunc: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/schedule-inspection/${params.id}`,
        data,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setState(JSON.parse(JSON.stringify(response)));
      if (resetFunc) resetFunc();
      toast.success("Inspection Schedule Added Successfully", {
        autoClose: 2000,
      });
      handleCloseScheduleInspection();
    } catch (error: any) {
      toast.error("Inspection Schedule Added Failed", {
        autoClose: 2000,
      });
      if (resetFunc) resetFunc();
      handleCloseScheduleInspection();
      // console.error("error", error);
      console.log("typeof error", typeof error);
      if (typeof error == "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });

        navigate(process.env.PUBLIC_URL + "/logout");
      }
    }
  };

  const submitInitiatedInspection = async (data: any, resetFunc: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/initiate-inspection/${params.id}`,
        // data,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setState(JSON.parse(JSON.stringify(response)));
      if (resetFunc) resetFunc();
      toast.success("Inspection Schedule Added Successfully", {
        autoClose: 2000,
      });
      handleCloseScheduleInspection();
    } catch (error: any) {
      toast.error("Inspection Schedule Added Failed", {
        autoClose: 2000,
      });
      if (resetFunc) resetFunc();
      handleCloseScheduleInspection();
      // console.error("error", error);
      console.log("typeof error", typeof error);
      if (typeof error == "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });

        navigate(process.env.PUBLIC_URL + "/logout");
      }
    }
  };

  const handleCloseWasteCollection = () =>
    setCollectionWasteNotifications(false);

  const handlePaymentWasteNotifications = () => {
    setPaymentWasteNotifications(true);
  };

  const handleScheduleInspectionNotifications = (item: any) => {
    setScheduleInspectionNotifications(true);
  };

  const handleScheduleWasteCollectionNotifications = (item: any) => {
    setScheduleCollectionWasteNotifications(true);
  };

  const handleInspectionWasteNotifications = (item: any) => {
    setInspectionWasteNotifications(true);
  };

  const handleCollectionWasteNotifications = (item: any) => {
    setCollectionWasteNotifications(true);
  };

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(company?.companyType);
  };

  const addLayoutData = (
    requiredTypes: CompanyTypeEnum[],
    child: any,
    alt: any = null
  ) => {
    return hasPermission(requiredTypes) ? child : alt;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            pageTitle="Waste Notification"
            title="Waste Notifications Details"
          />
          <Row className="justify-content-center">
            <Col xxl={9}>
              <Card id="demo">
                <Card.Body>
                  <Row className="p-4">
                    <Col lg={9}>
                      <h3 className="fw-bold mb-4">
                        Waste Notification Ref No:{" "}
                        {String(state?.id).substring(0, 13).toUpperCase()}{" "}
                      </h3>
                      <Row className="g-4">
                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Waste Notification Ref No
                          </p>
                          <h5 className="fs-16 mb-0">
                            #
                            <span id="invoice-no">
                              {String(state?.id).substring(0, 13).toUpperCase()}
                            </span>
                          </h5>
                        </Col>

                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Created Date
                          </p>
                          <h5 className="fs-16 mb-0">
                            <span id="invoice-date">
                              {/* 23 Nov, 2021 */}

                              {moment(state?.createDateTime).format(
                                "MMM Do YYYY"
                              )}
                            </span>{" "}
                            {/* <small className="text-muted" id="invoice-time">
                              02:36PM
                            </small> */}
                          </h5>
                        </Col>

                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Payment Status
                          </p>
                          <span
                            className={`badge ${
                              state.paymentStatus ===
                              PaymentStatusEnum.PaymentCompleted
                                ? "bg-success-subtle text-success"
                                : "bg-warning-subtle text-warning"
                            } fs-11`}
                            id="payment-status"
                          >
                            {state.paymentStatus}
                          </span>
                          {/* <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Waste Notification Requisition Status
                          </p>
                          <span
                            className={`badge ${
                              state.paymentStatus ===
                              VisitationRequisitionStatusEnum.COMPLETED
                                ? "bg-success-subtle text-success"
                                : "bg-warning-subtle text-warning"
                            } fs-11`}
                            id="payment-status"
                          >
                            {state.status}
                          </span> */}
                          {addLayoutData(
                            [
                              CompanyTypeEnum.PLATFORM_ADMIN,
                              CompanyTypeEnum.SHIPMENT,
                              CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                              CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                            ],
                            <Fragment>
                              <p className="text-muted mb-1 text-uppercase fw-medium fs-14 mt-1">
                                Inspection Status
                              </p>
                              <span
                                className={`badge ${
                                  state.inspectionStatus ===
                                  InspectionStatusEnum.InspectionCompleted
                                    ? "bg-success-subtle text-success"
                                    : state.inspectionStatus ===
                                      InspectionStatusEnum.InspectionScheduled
                                    ? "bg-info-subtle text-info"
                                    : "bg-warning-subtle text-warning"
                                } fs-11`}
                                id="payment-status"
                              >
                                {state.inspectionStatus ||
                                  InspectionStatusEnum.AwaitingInspection}
                              </span>
                            </Fragment>
                          )}
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Collection Status
                          </p>
                          <span
                            className={`badge ${
                              state?.wasteCollectionStatus ===
                              WasteCollectionStatusEnum.CollectionCompleted
                                ? "bg-success-subtle text-success"
                                : state.wasteCollectionStatus ===
                                    WasteCollectionStatusEnum.CollectionScheduled ||
                                  state.wasteCollectionStatus ===
                                    WasteCollectionStatusEnum.AdjustmentConfirmed
                                ? "bg-info-subtle text-info"
                                : "bg-warning-subtle text-warning"
                            } fs-11`}
                            id="payment-status"
                          >
                            {state?.wasteCollectionStatus ||
                              WasteCollectionStatusEnum.AwaitingCollection}
                          </span>
                        </Col>

                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Expected Collection Date
                          </p>
                          <h5 className="fs-16 mb-0">
                            {/* ₦ */}
                            <span id="total-amount">
                              {moment(state.estimatedArrival).format(
                                "MMM Do YYYY"
                              )}
                            </span>
                          </h5>
                        </Col>

                        <Col lg={12} className="col-12">
                          {/* <h6 className="my-3">COLLECTION</h6> */}
                          <div className="mb-3">
                            <h6 className="text-primary">
                              <strong>
                                <u>COLLECTION SCHEDULE</u>
                              </strong>
                            </h6>
                          </div>

                          <Row>
                            <Col lg={6} className="col-6">
                              <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                                Scheduled Collection Date
                              </p>
                              <h5 className="fs-16 mb-0">
                                {/* ₦ */}
                                <span id="total-amount">
                                  {state.collectionScheduledDate ? (
                                    moment(
                                      state.collectionScheduledDate
                                    ).format("MMM Do YYYY")
                                  ) : (
                                    <small className="text-muted">--</small>
                                  )}
                                </span>
                              </h5>
                            </Col>

                            <Col lg={6} className="col-6">
                              <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                                Collection Facility Name
                              </p>
                              <h5 className="fs-16 mb-0">
                                {/* ₦ */}
                                <span
                                  style={{ textTransform: "capitalize" }}
                                  id="total-amount fw-medium user-name-text text-capitalize"
                                >
                                  {state.portReceptionFacility ? (
                                    state.portReceptionFacility.name
                                  ) : state.wasteManagementProcessingFacility ? (
                                    state.wasteManagementProcessingFacility.name
                                  ) : (
                                    <small className="text-muted">--</small>
                                  )}
                                </span>
                              </h5>
                            </Col>
                          </Row>
                        </Col>

                        {addLayoutData(
                          [
                            CompanyTypeEnum.SHIPMENT,
                            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                            CompanyTypeEnum.PLATFORM_ADMIN,
                            CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                          ],
                          <Col lg={12} className="col-12">
                            {/* <h6 className="my-3">COLLECTION</h6> */}
                            <div className="mb-3">
                              <h6 className="text-primary">
                                <strong>
                                  <u>INSPECTION SCHEDULE</u>
                                </strong>
                              </h6>
                            </div>

                            <Row>
                              <Col lg={6} className="col-6">
                                <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                                  Scheduled Inspection Date
                                </p>
                                <h5 className="fs-16 mb-0">
                                  {/* ₦ */}
                                  <span id="total-amount">
                                    {state.inspectionScheduledDate ? (
                                      moment(
                                        state.inspectionScheduledDate
                                      ).format("MMM Do YYYY")
                                    ) : (
                                      <small className="text-muted">--</small>
                                    )}
                                  </span>
                                </h5>
                              </Col>

                              <Col lg={6} className="col-6">
                                <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                                  Inspection Facility Name
                                </p>
                                <h5 className="fs-16 mb-0">
                                  {/* ₦ */}
                                  <span id="total-amount fw-medium user-name-text text-capitalize">
                                    {CompanyTypeEnum.NIGERIA_PORT_AUTHORITY}
                                  </span>
                                </h5>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {/* <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Total Amount
                          </p>
                          <h5 className="fs-16 mb-0">
                            ₦
                            <span id="total-amount">
                              {(
                                state.bill?.totalTransactionValue || 0
                              ).toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                            </span>
                          </h5>
                        </Col> */}
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <div className="mt-sm-0 mt-3">
                        <div className="mb-4">
                          <img
                            src={logoDark}
                            className="card-logo card-logo-dark"
                            alt="logo dark"
                            height="17"
                          />
                          <img
                            src={logoLight}
                            className="card-logo card-logo-light"
                            alt="logo light"
                            height="17"
                          />
                        </div>
                        <h6 className="text-muted text-uppercase fw-semibold">
                          Address
                        </h6>
                        <p className="text-muted mb-1" id="address-details">
                          {state.company?.state}, {state.company?.country}
                        </p>
                        {/* <p className="text-muted mb-1" id="zip-code">
                          <span>Zip-code:</span> 90201
                        </p> */}
                        <h6>
                          <span className="text-muted fw-normal">Email:</span>
                          <span id="email">{state.company?.email}</span>
                        </h6>
                        {/* <h6>
                          <span className="text-muted fw-normal">Website:</span>{" "}
                          <a
                            href="https://themesbrand.com/"
                            className="link-primary"
                            target="_blank"
                            rel="noreferrer"
                            id="website"
                          >
                            www.themesbrand.com
                          </a>
                        </h6> */}
                        <h6 className="mb-0">
                          <span className="text-muted fw-normal">
                            Contact No:{" "}
                          </span>
                          <span id="contact-no"> +(01) 234 6789</span>
                        </h6>
                      </div>
                    </Col>
                  </Row>

                  <Row className="p-4 border-top border-top-dashed">
                    <Col lg={9}>
                      <Row className="g-3">
                        <div className="col-6">
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">
                            Billing Address
                          </h6>
                          <p className="fw-medium mb-2" id="billing-name">
                            {state.user?.firstName} {state.user?.lastName}
                          </p>
                          <p
                            className="text-muted mb-1"
                            id="billing-address-line-1"
                          >
                            {state.user?.address}
                          </p>
                          <p className="text-muted mb-1">
                            <span>Phone: +</span>
                            <span id="billing-phone-no">(123) 456-7890</span>
                          </p>
                          <p className="text-muted mb-0">
                            <span>Tax: </span>
                            <span id="billing-tax-no">12-3456789</span>{" "}
                          </p>
                        </div>

                        {/* <div className="col-6">
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">
                            Shipping Address
                          </h6>
                          <p className="fw-medium mb-2" id="shipping-name">
                            {state.user?.firstName} {state.user?.lastName}
                          </p>
                          <p
                            className="text-muted mb-1"
                            id="shipping-address-line-1"
                          >
                            {state.user?.address}
                          </p>
                          <p className="text-muted mb-1">
                            <span>Phone: +</span>
                            <span id="shipping-phone-no">(123) 456-7890</span>
                          </p>
                        </div> */}
                      </Row>
                    </Col>

                    <Col lg={3}>
                      <h6 className="text-muted text-uppercase fw-semibold mb-3">
                        Total Amount
                      </h6>
                      <h3 className="fw-bold mb-2">
                        ₦
                        {(
                          +state.bill?.totalTransactionValue || 0
                        ).toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                        })}
                      </h3>
                      {addLayoutData(
                        [
                          CompanyTypeEnum.SHIPMENT,
                          CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                          CompanyTypeEnum.PLATFORM_ADMIN,
                          CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                        ],
                        state.createDateTime,
                        state.collectionScheduledDate
                      ) ? (
                        <Fragment>
                          {state?.bill?.status !==
                          PaymentStatusEnum.PaymentCompleted ? (
                            <span
                              className={`
                        badge 
                        ${
                          moment(
                            addLayoutData(
                              [
                                CompanyTypeEnum.SHIPMENT,
                                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                                CompanyTypeEnum.PLATFORM_ADMIN,
                                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                              ],
                              state.createDateTime,
                              state.collectionScheduledDate
                            )
                          )
                            .add(7, "days")
                            .isAfter(moment())
                            ? "bg-success-subtle text-success"
                            : "bg-danger-subtle text-danger"
                        } fs-12
                        `}
                            >
                              Due Date:{" "}
                              {addLayoutData(
                                [
                                  CompanyTypeEnum.SHIPMENT,
                                  CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                                  CompanyTypeEnum.PLATFORM_ADMIN,
                                  CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                                ],
                                state.createDateTime,
                                state.collectionScheduledDate
                              )
                                ? moment(
                                    addLayoutData(
                                      [
                                        CompanyTypeEnum.SHIPMENT,
                                        CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                                        CompanyTypeEnum.PLATFORM_ADMIN,
                                        CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                                      ],
                                      state.createDateTime,
                                      state.collectionScheduledDate
                                    )
                                  )
                                    .add(7, "days")
                                    .format("MMM Do YYYY")
                                : "23 April, 2024"}
                            </span>
                          ) : null}
                        </Fragment>
                      ) : null}
                    </Col>
                  </Row>

                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    {/* <Row> */}

                    {(company?.companyType ===
                      CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ||
                      company?.companyType ===
                        CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
                      company?.companyType === CompanyTypeEnum.PLATFORM_ADMIN) &&
                    (state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.CollectionScheduled ||
                      state.wasteCollectionStatus ===
                        WasteCollectionStatusEnum.AdjustmentConfirmed) ? (
                      <Link
                        onClick={(e) => {
                          // const item = cell.row.original;
                          e.preventDefault();
                          handleCollectionWasteNotifications(state);
                        }}
                        to="#"
                        className="btn btn-info"
                      >
                        Initiate Collection
                      </Link>
                    ) : null}

                    {(company?.companyType ===
                      CompanyTypeEnum.NIGERIA_PORT_AUTHORITY ||
                      company?.companyType === CompanyTypeEnum.PLATFORM_ADMIN ||
                      company?.companyType === CompanyTypeEnum.PLATFORM_ADMIN) &&
                    state.inspectionStatus ===
                      InspectionStatusEnum.InspectionScheduled ? (
                      <Link
                        onClick={(e) => {
                          // const item = cell.row.original;
                          e.preventDefault();
                          handleInspectionWasteNotifications(state);
                        }}
                        to={`#`}
                        // to={`/compliance-inspection-report/${state.id}`}
                        className="btn btn-info"
                      >
                        Initiate Inspection
                      </Link>
                    ) : null}

                    {(company?.companyType ===
                      CompanyTypeEnum.NIGERIA_PORT_AUTHORITY ||
                      company?.companyType === CompanyTypeEnum.PLATFORM_ADMIN) &&
                    state.paymentStatus ===
                      PaymentStatusEnum.PaymentCompleted &&
                    state.inspectionStatus !==
                      InspectionStatusEnum.InspectionCompleted ? (
                      <Link
                        onClick={(e) => {
                          // const item = cell.row.original;
                          e.preventDefault();
                          handleScheduleInspectionNotifications(state);
                        }}
                        to="#"
                        className="btn btn-info"
                      >
                        Schedule Inspection
                      </Link>
                    ) : null}

                    {/* SHIPMENT */}
                    {company?.companyType ===
                      CompanyTypeEnum.PORT_RECEPTION_FACILITIES &&
                    state.paymentStatus ===
                      PaymentStatusEnum.PaymentCompleted &&
                    (state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.AwaitingCollection ||
                      state.wasteCollectionStatus ===
                        WasteCollectionStatusEnum.CollectionScheduled) ? (
                      <Link
                        onClick={(e) => {
                          // const item = cell.row.original;
                          e.preventDefault();
                          handleScheduleWasteCollectionNotifications(state);
                        }}
                        to="#"
                        className="btn btn-info"
                      >
                        Schedule Collection
                      </Link>
                    ) : null}

                    {/* OIL AND GAS PLATFORM */}
                    {company?.companyType ===
                      CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING &&
                    (state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.AwaitingCollection ||
                      state.wasteCollectionStatus ===
                        WasteCollectionStatusEnum.CollectionScheduled) ? (
                      <Link
                        onClick={(e) => {
                          // const item = cell.row.original;
                          e.preventDefault();
                          handleScheduleWasteCollectionNotifications(state);
                        }}
                        to="#"
                        className="btn btn-info"
                      >
                        Schedule Collection
                      </Link>
                    ) : null}

                    {/* SHIPMENT */}
                    {company?.companyType === CompanyTypeEnum.SHIPMENT &&
                    state.paymentStatus !==
                      PaymentStatusEnum.PaymentCompleted ? (
                      <button
                        onClick={handlePaymentWasteNotifications}
                        className="btn btn-primary"
                      >
                        Pay Now
                      </button>
                    ) : null}

                    {/* OIL AND GAS PLATFORM */}
                    {company?.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM &&
                    state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.CollectionCompleted &&
                    state.paymentStatus !==
                      PaymentStatusEnum.PaymentCompleted ? (
                      <button
                        onClick={handlePaymentWasteNotifications}
                        className="btn btn-primary"
                      >
                        Pay Now
                      </button>
                    ) : null}
                    {/* GENERATE CERTIFICATE */}
                    {(company?.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
                      company?.companyType === CompanyTypeEnum.SHIPMENT) &&
                    state.paymentStatus ===
                      PaymentStatusEnum.PaymentCompleted &&
                    state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.CollectionCompleted ? (
                      <Link
                        to={`/compliance-certificate/${params.id}`}
                        className="btn btn-info"
                      >
                        Generate Compliance Certificate
                      </Link>
                    ) : null}
                    {/* Confirmed Quantity by Collector */}

                    {/* {(company?.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
                      company?.companyType === CompanyTypeEnum.SHIPMENT) &&
                    state.wasteCollectionStatus !==
                      WasteCollectionStatusEnum.AwaitingCollection ? (
                      <button className="btn btn-info">
                        Confirm Collection Quantity
                      </button>
                    ) : null} */}
                    {/* Confirmed Quantity by Requestor */}

                    {/* {(company?.companyType ===
                      CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ||
                      company?.companyType ===
                        CompanyTypeEnum.PORT_RECEPTION_FACILITIES) &&
                    state.paymentStatus !==
                      PaymentStatusEnum.PaymentCompleted &&
                    state.wasteCollectionStatus !==
                      WasteCollectionStatusEnum.AwaitingCollection ? (
                      <button className="btn btn-info">
                        Initiate Collection Confirmation
                      </button>
                    ) : null} */}

                    {/* PORT_RECEPTION_FACILITIES / WASTE_MANAGEMENT_PROCESSING */}
                    {(company?.companyType ===
                      CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
                      company?.companyType ===
                        CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING) &&
                    (state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.CollectionScheduled ||
                      state.wasteCollectionStatus ===
                        WasteCollectionStatusEnum.AwaitingAdjustmentConfirmation) ? (
                      <Link
                        to={`/waste-notification-details/${state.id}/edit`}
                        className="btn btn-info"
                      >
                        Edit Waste Notification
                      </Link>
                    ) : null}

                    {/* SHIPMENT / OIL AND GAS PLATFORM */}
                    {(company?.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
                      company?.companyType === CompanyTypeEnum.SHIPMENT) &&
                    state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.AwaitingCollection ? (
                      <Link
                        to={`/waste-notification-details/${state.id}/edit`}
                        className="btn btn-info"
                      >
                        Edit Waste Notification
                      </Link>
                    ) : null}

                    {/* APPROVAL FOR SHIPMENT / OIL AND GAS PLATFORM */}
                    {(company?.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
                      company?.companyType === CompanyTypeEnum.SHIPMENT) &&
                    state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.AwaitingAdjustmentConfirmation ? (
                      <Link
                        to={`/waste-notification-details/${state.id}/edit`}
                        className="btn btn-info"
                      >
                        Confirm Adjustment
                      </Link>
                    ) : null}
                    {/* </Row> */}
                  </div>
                  <Row>
                    <Col lg={12}>
                      <Card.Body className="card-body p-4">
                        <div className="table-responsive">
                          <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                            <thead>
                              <tr className="table-active">
                                <th scope="col" style={{ width: "50px" }}>
                                  #
                                </th>
                                <th scope="col">Waste Details</th>
                                <th scope="col">
                                  {company?.companyType !==
                                  CompanyTypeEnum.SHIPMENT
                                    ? "Rate / Unit"
                                    : "Unit"}
                                </th>
                                <th scope="col">Quantity</th>
                                {company?.companyType !==
                                CompanyTypeEnum.SHIPMENT ? (
                                  <th scope="col" className="text-end">
                                    Amount
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody id="products-list">
                              {state.wasteDeclarations &&
                                state.wasteDeclarations.map(
                                  (wd: any, index: number) => (
                                    <tr key={wd.id}>
                                      <th scope="row">{index + 1}</th>
                                      <td className="text-start">
                                        <span className="fw-medium">
                                          {wd.wasteType}
                                        </span>
                                        <p className="text-muted mb-0">
                                          {wd.name}
                                        </p>
                                      </td>
                                      <td>
                                        {company?.companyType !==
                                        CompanyTypeEnum.SHIPMENT
                                          ? wd.rate
                                            ? "₦" +
                                              wd.rate +
                                              " / " +
                                              getMeasurement(
                                                wasteDetails,
                                                wd["wasteType"]
                                              )
                                            : ""
                                          : wd.rate
                                          ? getMeasurement(
                                              wasteDetails,
                                              wd["wasteType"]
                                            )
                                          : ""}
                                        {/* ₦{wd.rate || "239.98"} */}
                                      </td>
                                      <td>{wd.quantity}</td>

                                      {company?.companyType !==
                                      CompanyTypeEnum.SHIPMENT ? (
                                        <td className="text-end">
                                          ₦{wd.quantity * (wd?.rate || 239.98)}
                                        </td>
                                      ) : null}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                        <div className="border-top border-top-dashed mt-2">
                          <Table
                            className="table-borderless table-nowrap align-middle mb-0 ms-auto"
                            style={{ width: "250px" }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  {company?.companyType ===
                                  CompanyTypeEnum.SHIPMENT
                                    ? "Environmental Protection Levy (EPL)"
                                    : "Sub Total"}
                                </td>
                                <td className="text-end">
                                  ₦
                                  {(
                                    +state.bill?.totalTransactionValue ||
                                    0 - state.bill?.totalTax ||
                                    0 - state.bill?.discount ||
                                    0
                                  ).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <td>Estimated Tax (7.5%)</td>
                                <td className="text-end">
                                  ₦
                                  {(+state.bill?.totalTax || 0).toLocaleString(
                                    navigator.language,
                                    { minimumFractionDigits: 2 }
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Discount{" "}
                                  <small className="text-muted">
                                    (Wastify24)
                                  </small>
                                </td>
                                <td className="text-end">
                                  - ₦
                                  {(state.bill?.discount || 0).toLocaleString(
                                    navigator.language,
                                    { minimumFractionDigits: 2 }
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Shipping Charge</td>
                                <td className="text-end">₦65.00</td>
                              </tr>
                              <tr className="border-top border-top-dashed fs-15">
                                <th scope="row">Total Amount</th>
                                <th className="text-end">
                                  ₦
                                  {(
                                    +state.bill?.totalTransactionValue || 0
                                  ).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  })}
                                </th>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        {/* <div className="mt-3">
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">
                            Payment Details:
                          </h6>
                          <p className="text-muted mb-1">
                            Payment Method:{" "}
                            <span className="fw-medium" id="payment-method">
                              Mastercard
                            </span>
                          </p>
                          <p className="text-muted mb-1">
                            Card Holder:{" "}
                            <span className="fw-medium" id="card-holder-name">
                              David Nichols
                            </span>
                          </p>
                          <p className="text-muted mb-1">
                            Card Number:{" "}
                            <span className="fw-medium" id="card-number">
                              xxx xxxx xxxx 1234
                            </span>
                          </p>
                          <p className="text-muted">
                            Total Amount:{" "}
                            <span className="fw-medium" id="">
                              ₦{" "}
                            </span>
                            <span id="card-total-amount">
                              {(
                                state.bill?.totalTransactionValue || 0
                              ).toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                            </span>
                          </p>
                        </div> */}
                        {addLayoutData(
                          [
                            CompanyTypeEnum.SHIPMENT,
                            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                          ],
                          <div className="mt-4">
                            <div className="alert alert-info">
                              <p className="mb-0">
                                <span className="fw-semibold">NOTES:</span>
                                <span id="note">
                                  All bills are to be paid within 7 days from
                                  receipt of invoice. To be paid by cheque or
                                  credit card or direct payment online. If
                                  account is not paid within 7 days the credits
                                  details supplied as confirmation of work
                                  undertaken will be charged the agreed quoted
                                  fee noted above.
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                          <Link to="#" className="btn btn-info">
                            <i className="ri-printer-line align-bottom me-1"></i>{" "}
                            Print
                          </Link>
                          <Link to="#" className="btn btn-primary">
                            <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                            Download
                          </Link>
                        </div>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <WasteNotificationChat />
              <WasteNotificationRecentActivities state={state} />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>

      {company?.companyType === CompanyTypeEnum.NIGERIA_PORT_AUTHORITY ||
      company?.companyType === CompanyTypeEnum.PLATFORM_ADMIN ? (
        <AddWasteNotificationInspections
          isShow={inspectionWasteNotifications}
          handleClose={handleCloseInspection}
          handleShow={handleInspectionWasteNotifications}
          handleInitiatedInspection={submitInitiatedInspection}
        />
      ) : null}

      {company?.companyType === CompanyTypeEnum.NIGERIA_PORT_AUTHORITY ||
      company?.companyType === CompanyTypeEnum.PLATFORM_ADMIN ? (
        <ScheduleInspection
          isShow={scheduleInspectionNotifications}
          data={state}
          handleClose={handleCloseScheduleInspection}
          handleShow={handleScheduleInspectionNotifications}
          handleScheduledInspection={submitScheduledInspection}
        />
      ) : null}

      {company?.companyType === CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
      company?.companyType === CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ? (
        <ScheduleWasteCollection
          isShow={scheduleWasteCollectionNotifications}
          data={state}
          handleClose={handleCloseScheduleWasteCollection}
          handleShow={handleScheduleWasteCollectionNotifications}
          handleScheduledCollection={submitScheduledWasteCollection}
        />
      ) : null}

      <MakePayments
        amountDue={state?.bill?.amountDue}
        isShow={paymentWasteNotifications}
        handlePayment={submitPayment}
        handleClose={handleClosePayment}
        handleShow={handlePaymentWasteNotifications}
      />

      {company?.companyType === CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
      company?.companyType === CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ? (
        <AddWasteCollections
          isShow={collectionWasteNotifications}
          handleClose={handleCloseWasteCollection}
          handleShow={handleCollectionWasteNotifications}
          handleInitiatedCollection={submitInitiatedWasteCollection}
        />
      ) : null}
    </React.Fragment>
  );
};

export default WasteNotificationDetails;
