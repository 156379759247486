import React, { useState } from "react";
import PortOfOperationTable from "./PortOfOperationTable";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../../Common/BreadCrumb";

const PortOfOperationsFacility = () => {
  document.title = "PortOfOperation | Maritime Waste Management Admin & Dashboard Platform";

  const [isShow, setIsShow] = useState(false);

  const hidePortOfOperationModal = () => {
    setIsShow(!isShow);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Port of Operations" title="Port of Operations" />
          <PortOfOperationTable isShow={isShow} hidePortOfOperationModal={hidePortOfOperationModal} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PortOfOperationsFacility;
