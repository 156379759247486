import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

// Front
import LayoutReducer from "./layouts/reducer";

//authantication
import LoginReducer from "./login/reducer";
import AccountReducer from "./register/reducer";
import ForgetPasswordReducer from "./forgetpwd/reducer";
import ProfileReducer from "./profile/reducer";
import UserReducer from "./users/reducer";
import ShipReducer from "./ships/reducer";
import WalletReducer from "./wallet/reducer";
import OilPlatformReducer from "./oilplatforms/reducer";
import FacilityReducer from "./facilities1/reducer";
import WasteNotificationReducer from "./wastenotifications/reducer";
import CertificationReducer from "./certificates/reducer";
import LicenseReducer from "./license/reducer";

import CompanyReducer from "./companies/reducer";
import FacilitiesReducer from "./facilities/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  User: UserReducer,
  Facility: FacilityReducer,
  OilPlatform: OilPlatformReducer,
  Wallet: WalletReducer,
  Ship: ShipReducer,
  WasteNotification: WasteNotificationReducer,
  Certification: CertificationReducer,
  License: LicenseReducer,

  Facilities: FacilitiesReducer,
  Company: CompanyReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export default rootReducer
export default persistedReducer;
