import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FacilitySelectDropdown from "../../pages/CertManagement/components/FacilitySelctor";

export const MARITIME_LICENSE_TYPES = [
  "FLAG REGISTRATION",
  "CHANGE OF OWNERSHIP REGISTRATION",
  "PROVISIONAL REGISTRATION",
  "CHANGE OF VESSEL NAME",
  "RENEWALOF FLAG REGISTRATION",
  "REGISTRATION OF MORTGAGE",
  "CABOTAGE SHIP REGISTRATION",
  "BAREBOAT VESSEL FLAG STATE REGISTRATION",
  "NOTATION OF FOREIGN MORTGAGE REGISTRATION",
  "REQUIREMENTSFORLICENSING 200 HORSEPOWER AND ABOVE ENGINE BOATS REGISTRATION"
];

interface UserProps {
  isShow: boolean;
  handleClose: () => void;
}

const LicenseApplicationModal = ({ isShow, handleClose }: UserProps) => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [targetType, setTargetType] = useState<string | null>(null);
  const [selectedFacility, setSelectedFacility] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      type: "LICENSE",
      target_id: id,
      target_type: targetType
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter the license title"),
    }),
    onSubmit: () => {}, // Empty since we're handling submission differently
  });

  const handleApplyNow = () => {
    if (formik.isValid && formik.dirty) {
      const applicationDetails = {
        title: formik.values.title,
        description: formik.values.description,
        type: "LICENSE",
        target_id: id,
        target_type: targetType,
        facility: selectedFacility // Include the selected facility in the navigation state
      };
      navigate('/license-management/apply/app-creation', { state: { applicationDetails } });
      handleClose();
    } else {
      formik.validateForm();
    }
  };

  return (
    <Modal centered show={isShow} onHide={handleClose} tabIndex={-1}>
      <div className="modal-content border-0">
        <Modal.Header className="p-4 pb-0">
          <Modal.Title as="h5">License Application</Modal.Title>
          <button type="button" className="btn-close" onClick={handleClose}></button>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form autoComplete="off">
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="title">License Type</Form.Label>
                  <Form.Select
                    id="title"
                    name="title"
                    placeholder="Enter License Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.title && formik.touched.title}
                  >
                    <option value="">Select License Title</option>
                    {MARITIME_LICENSE_TYPES.map((title) => (
                      <option key={title} value={title}>
                        {title}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.title}
                  </Form.Control.Feedback>
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="description">License Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    id="description"
                    name="description"
                    placeholder="Enter License Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.description && formik.touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </div>
              </Col>

              <div style={{ marginBottom: 20 }}>
                <FacilitySelectDropdown
                  onFacilitySelect={(facility) => {
                    console.log('Selected Facility:', facility.name);
                    setSelectedFacility(facility.name);
                  }}
                  formik={formik}
                  setId={setId}
                  setTargetType={setTargetType}
                  targetType={targetType}
                />
              </div>
            </Row>

            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={handleClose}>
                Close
              </Button>
              <Button type="button" className="btn btn-success" onClick={handleApplyNow}>
                Apply now
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default LicenseApplicationModal;