import { createSlice } from "@reduxjs/toolkit";

import { getShips, addShip, editShip, deleteShip } from "./thunk";

export const initialState = {
  shipList: [],
  error: {},
};

const ShipSlice: any = createSlice({
  name: "Ship",
  initialState,
  reducers: {},

  extraReducers: (builder: any) => {
    builder.addCase(getShips.fulfilled, (state: any, action: any) => {
      console.log(action.payload)
      state.shipList = action.payload;
    });

    builder.addCase(getShips.rejected, (state: any, action: any) => {
      console.log(action.payload)
      state.error = action.payload?.error || null;
    });

    // add
    builder.addCase(addShip.fulfilled, (state: any, action: any) => {
      state.shipList.push(action.payload?.data);
    });
    builder.addCase(addShip.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(editShip.fulfilled, (state: any, action: any) => {
      state.shipList = state.shipList.map((ship: any) =>
        ship.id === action.payload.id
          ? { ...ship, ...action.payload }
          : ship
      );
    });

    builder.addCase(editShip.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(deleteShip.fulfilled, (state: any, action: any) => {
      state.shipList = (state.shipList || []).filter(
        (ship: any) => ship.id !== action.payload.id
      );
    });
    builder.addCase(deleteShip.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });
  },
});

export default ShipSlice.reducer;
