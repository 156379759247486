//country images
import USflag from "../../assets/images/flags/us.svg";
import Italyflag from "../../assets/images/flags/italy.svg";
import Spainflag from "../../assets/images/flags/spain.svg";
import Russiaflag from "../../assets/images/flags/russia.svg";
import Arabicflag from "../../assets/images/flags/ae.svg";
import Frenchflag from "../../assets/images/flags/french.svg";

import userImg1 from "../../assets/images/users/avatar-1.jpg";
import userImg2 from "../../assets/images/users/avatar-2.jpg";
import userImg3 from "../../assets/images/users/avatar-3.jpg";
import userImg5 from "../../assets/images/users/avatar-5.jpg";
import userImg6 from "../../assets/images/users/avatar-6.jpg";
import userImg7 from "../../assets/images/users/avatar-7.jpg";

const coreOperationalModules = [
  {
    id: 1,
    title: "Electronic Registration, Management and Certification System",
    shortId: "ermcs",
    badgeColor: "bg-success-subtle text-success",
    icon: "lab la-buffer",
    description: `This module facilitates the electronic registration and management of maritime operators, vessels, and related entities. It streamlines the certification process, ensuring compliance with regulatory standards. The system provides a centralized database for easy access and management of stakeholders' information, certifications, and operational status, enhancing efficiency and transparency`,
  },
  {
    id: 2,
    title: "Automated Waste Management System",
    shortId: "awms",
    badgeColor: "bg-success-subtle text-success",
    icon: "lab la-telegram-plane",
    description: `This module automates the management of the entire processes involved in waste generation and collection within the operators in the maritime space, focusing on pollution prevention and control. It ensures that waste disposal practices comply with environmental regulations, tracking waste generation, collection, and disposal. The system provides real-time monitoring and reporting capabilities, helping to minimize environmental impact and maintain sustainability in maritime operations`,
  },
  {
    id: 3,
    title: "Cargo Declaration System",
    shortId: "cds",
    badgeColor: "bg-success-subtle text-success",
    icon: "las la-ship",
    description: `The Cargo Declaration System streamlines the process of declaring cargo for maritime transport. It automates documentation and reporting, ensuring accuracy and compliance with regulatory requirements. The system facilitates real-time tracking and verification of cargo, enhancing transparency and reducing delays in the logistics chain`,
  },
  {
    id: 4,
    title: "Performance Analytics",
    shortId: "pa",
    badgeColor: "bg-success-subtle text-success",
    icon: "las la-file-image",
    description: `Incident and Risks Management System: This module provides a comprehensive approach to managing maritime incidents, such as accidents, spills, or security breaches. It enables real-time reporting, response coordination, and documentation of incidents. The system helps to ensure a swift and effective response to emergencies, minimizing impact and supporting regulatory compliance`,
  },
  // {
  //   title: "Incident Management System",
  //   description: `Electronic Registration, Management and Certification System for Maritime Operators Read More`,
  // },
  {
    id: 5,
    title: "Seafarer Management",
    shortId: "sm",
    badgeColor: "bg-success-subtle text-success",
    icon: "lab la-buffer",
    description: `The Seafarer Management System focuses on the administration and welfare of seafarers. It manages seafarer certification, training, health records, and employment contracts. The system ensures compliance with international maritime labour standards, providing a centralized platform for managing seafarer information and enhancing the overall management of human resources in the maritime sector`,
  },
];

const recentTrasaction = [
  {
    id: 1,
    day: "Recent",
    transactionDetails: [
      {
        id: 1,
        transactionModeIcon: "lab la-paypal",
        title: "LIQUID OILY WASTE FROM SHIP MACHINERY",
        date: "20 Sep, 2022",
        badgeColor: "bg-danger-subtle text-danger",
        transactionPercentage: "- ₦62.45",
      },
      {
        id: 2,
        transactionModeIcon: "lab la-buffer",
        title: "SEWAGE",
        date: "28 Mar, 2022",
        badgeColor: "bg-success-subtle text-success",
        transactionPercentage: "+ ₦45.84",
      },
    ],
  },
  {
    id: 2,
    day: "Yesterday",
    transactionDetails: [
      {
        id: 1,
        transactionModeIcon: "las la-file-image",
        title: "GARBAGE",
        date: "18 Sep, 2022",
        badgeColor: "bg-success-subtle text-success",
        transactionPercentage: "+ ₦25.52",
      },
      {
        id: 2,
        transactionModeIcon: "las la-bus",
        title: "SEWAGE",
        date: "30 Nov, 2022",
        badgeColor: "bg-danger-subtle text-danger",
        transactionPercentage: "- ₦84.45",
      },
      {
        id: 3,
        transactionModeIcon: "lab la-telegram-plane",
        title: "HAZARDOUS WASTE",
        date: "12 Feb, 2022",
        badgeColor: "bg-success-subtle text-success",
        transactionPercentage: "+ ₦53.23",
      },
      {
        id: 4,
        transactionModeIcon: "las la-store-alt",
        title: "OILY CARGO RESIDUE",
        date: "12 Apl, 2022",
        badgeColor: "bg-success-subtle text-success",
        transactionPercentage: "+ ₦42.63",
      },
    ],
  },
];

const recentERMCSTrasaction = [
  {
    id: 1,
    day: "Recent",
    transactionDetails: [
      {
        id: 1,
        transactionModeIcon: "lab la-paypal",
        title: "Certification for Royal Ark Ship",
        date: "20 Sep, 2022",
        badgeColor: "bg-danger-subtle text-danger",
        transactionPercentage: "- ₦62.45",
      },
      {
        id: 2,
        transactionModeIcon: "lab la-buffer",
        title: "Permit",
        date: "28 Mar, 2022",
        badgeColor: "bg-success-subtle text-success",
        transactionPercentage: "+ ₦45.84",
      },
    ],
  },
  {
    id: 2,
    day: "Yesterday",
    transactionDetails: [
      {
        id: 1,
        transactionModeIcon: "las la-file-image",
        title: "Permit for Platform",
        date: "18 Sep, 2022",
        badgeColor: "bg-success-subtle text-success",
        transactionPercentage: "+ ₦25.52",
      },
      {
        id: 2,
        transactionModeIcon: "las la-bus",
        title: "Certification for platform",
        date: "30 Nov, 2022",
        badgeColor: "bg-danger-subtle text-danger",
        transactionPercentage: "- ₦84.45",
      },
      {
        id: 3,
        transactionModeIcon: "lab la-telegram-plane",
        title: "Permit for Waste Management",
        date: "12 Feb, 2022",
        badgeColor: "bg-success-subtle text-success",
        transactionPercentage: "+ ₦53.23",
      },
      {
        id: 4,
        transactionModeIcon: "las la-store-alt",
        title: "License for Ship",
        date: "12 Apl, 2022",
        badgeColor: "bg-success-subtle text-success",
        transactionPercentage: "+ ₦42.63",
      },
    ],
  },
];

const salesRevenue = [
  {
    id: 1,
    countryImg: USflag,
    country: "US",
    company: { name: "Total energy" },
    order: 6253,
    earning: "₦26,524",
  },
  {
    id: 2,
    countryImg: Italyflag,
    country: "Italy",
    company: { name: "Cornoil energy" },
    order: 5563,
    earning: "₦32,562",
  },
  {
    id: 3,
    countryImg: Spainflag,
    country: "Spain",
    company: { name: "Ascorn" },
    order: 3258,
    earning: "₦65,214",
  },
  {
    id: 4,
    countryImg: Frenchflag,
    country: "French",
    company: { name: "French Flies" },
    order: 6325,
    earning: "₦63,254",
  },
  {
    id: 5,
    countryImg: Russiaflag,
    country: "Russia",
    company: { name: "Russell Cargo" },
    order: 8652,
    earning: "₦53,621",
  },
  {
    id: 6,
    countryImg: Arabicflag,
    country: "Arabic",
    company: { name: "Dumberman" },
    order: 4256,
    earning: "₦86,526",
  },
];

const invoiceList = [
  {
    id: 1,
    invoiceID: "Lec-2152",
    clientImg: userImg1,
    clientName: "Donald Risher",
    date: "20 Sep, 2022",
    status: "Paid",
    statusClass: "bg-success-subtle text-success",
  },
  {
    id: 2,
    invoiceID: "Lec-2153",
    clientImg: userImg2,
    clientName: "Brody Holman",
    date: "12 Arl, 2022",
    status: "Unpaid",
    statusClass: "bg-warning-subtle text-warning",
  },
  {
    id: 3,
    invoiceID: "Lec-2154",
    clientImg: userImg3,
    clientName: "Jolie Hood",
    date: "28 Mar, 2022",
    status: "Paid",
    statusClass: "bg-success-subtle text-success",
  },
  {
    id: 4,
    invoiceID: "Lec-2156",
    clientImg: userImg5,
    clientName: "Howard Lyons",
    date: "18 Sep, 2022",
    status: "Refund",
    statusClass: "bg-info-subtle text-info",
  },
  {
    id: 5,
    invoiceID: "Lec-2157",
    clientImg: userImg6,
    clientName: "Howard Oneal",
    date: "12 Feb, 2022",
    status: "Paid",
    statusClass: "bg-success-subtle text-success",
  },
  {
    id: 6,
    invoiceID: "Lec-2158",
    clientImg: userImg7,
    clientName: "Jena Hall",
    date: "30 Nov, 2022",
    status: "Cancel",
    statusClass: "bg-danger-subtle text-danger",
  },
];

export { recentTrasaction, salesRevenue, invoiceList, coreOperationalModules, recentERMCSTrasaction };
