import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import {
  getShips as getShipsApi,
  addShips as addShipsApi,
  editShip as editShipApi,
  deleteShip as deleteShipApi,
} from "../../helpers/fakebackend_helper";

export const getShips = createAsyncThunk("invoice/getShips", async () => {
  try {
    const response = getShipsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addShip = createAsyncThunk(
  "invoice/addShip",
  async (user: any, { getState }) => {
    try {
      // const state = getState();
      // console.log('addShip getState', state)
      const response = addShipsApi(user);
      
      toast.success("Ship added Successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error("Ship added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const editShip = createAsyncThunk(
  "invoice/editShip",
  async (payment: any) => {
    try {
      const response = editShipApi(payment);
      const data = await response;
      toast.success("Ship edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Ship edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteShip = createAsyncThunk(
  "invoice/deleteShip",
  async (id: any) => {
    try {
      const response = await deleteShipApi(id);
      toast.success("Ship deleted Successfully", { autoClose: 1000 });
      return { id, ...response };
    } catch (error) {
      console.log(error)
      toast.error("Ship deleted Failed", { autoClose: 5000 });
      return error;
    }
  }
);
