import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { logoutUser, addShip as onAddShip } from "../../slices/thunk";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  FACILITIES_ENUM,
  regulator,
  shakeHolder,
} from "../../helpers/enums/facility.enum";
import BreadCrumb from "../../Common/BreadCrumb";
import countries from "../../Common/data/country";
import { useParams } from "react-router-dom";
import Company from "./mock_data.json";

const EditCompanyDetails = () => {
  const dispatch = useDispatch();
  const selectState = createSelector(
    (state: any) => state.Ship,
    (state: any) => state.Login,
    (ship: any, login: any) => ({
      user: login.user,
      error: ship.error,
      shipList: ship.shipList,
    })
  );

  const { id } = useParams();
  const getFacility = (id: string) => {
    const facil = Company.filter((company) => company.id === id);
    return facil[0];
  };

  let companyData;

  if (id) companyData = getFacility(id);
  else companyData = getFacility("1");

  const { error, user } = useSelector(selectState);

  console.log(regulator.includes(user.company.companyType));

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      // id: "",
      name: companyData.name,
      type: companyData.type,
      imoNumber: companyData.imoNumber,
      mmsiNumber: companyData.mmsiNumber,
      shipType: companyData.shipType,
      flag: companyData.flag,
      grossTonnage: companyData.grossTonnage,
      registrationNumber: companyData.registration_number,
      country: companyData.country,
      length: companyData.length,
      beam: companyData.beam,
      yearBuilt: companyData.yearBuilt,
      complianceNote: companyData.compliance_notes,
      website: companyData.website,
      longitude: companyData.longitude,
      latitude: companyData.latitude,
      address: companyData.address,
      state: companyData.state,
      parentfacilityId: "",
      is_compliant: companyData.is_compliant,
      children: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Facility Name"),
      type: Yup.string().required("Please Select a facility type"),
      registrationNumber: Yup.string(),
      imoNumber: Yup.string().when("type", {
        is: (type: string) => type === "SHIP",
        then: () => Yup.string().required("Please enter imo Number"),
        otherwise: () => Yup.string().notRequired(),
      }),
      mmsiNumber: Yup.string().when("type", {
        is: (type: string) => type === "SHIP",
        then: () => Yup.string().required("Please enter mmsi Number"),
        otherwise: () => Yup.string().notRequired(),
      }),
      shipType: Yup.string().when("type", {
        is: (type: string) => type === "SHIP",
        then: () => Yup.string().required("Please choose Your Ship type"),
        otherwise: () => Yup.string().notRequired(),
      }),
      flag: Yup.string().when("type", {
        is: (type: string) => type === "SHIP",
        then: () => Yup.string().required("Please enter Flag"),
        otherwise: () => Yup.string().notRequired(),
      }),
      grossTonnage: Yup.string().when("type", {
        is: (type: string) => type === "SHIP",
        then: () => Yup.string().required("Please choose Your Gross Tonnage"),
        otherwise: () => Yup.string().notRequired(),
      }),
      country: Yup.string().notRequired(),
      length: Yup.number().notRequired(),
      beam: Yup.number().notRequired(),
      yearBuilt: Yup.number().notRequired(),
      portId: Yup.string().notRequired(),
      complianceNote: Yup.string(),
      website: Yup.string().notRequired(),
      longitude: Yup.number().notRequired(),
      latitude: Yup.number().notRequired(),
      address: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
      logoId: Yup.string().notRequired(),
      parentFacilityId: Yup.string().when("type", {
        is: (type: string) => type === "PORT_RECEPTION_FACILITY",
        then: () => Yup.string().required("Please choose a parent facility"),
        otherwise: () => Yup.string().notRequired(),
      }),
      is_compliant: Yup.boolean().notRequired(),
      ownerId: Yup.string().notRequired(),
      companyId: Yup.string().notRequired(),
      children: Yup.array().of(Yup.string()),
    }),

    onSubmit: (values: any) => {
      const UpdateFacility = {
        name: values["name"],
        type: values["type"],
        registrationNumber: values["registrationNumber"],
        imoNumber: values["imoNumber"],
        mmsiNumber: values["mmsiNumber"],
        shipType: values["shipType"],
        flag: values["flag"],
        grossTonnage: values["grossTonnage"],
        country: values["country"],
        length: values["length"],
        beam: values["beam"],
        yearBuilt: values["yearBuilt"],
        portId: values["portId"],
        complianceNote: values["complianceNote"],
        website: values["website"],
        longitude: values["longitude"],
        latitude: values["latitude"],
        address: values["address"],
        state: values["state"],
        logoId: values["logoId"],
        parentFacilityId: values["parentFacilityId"],
        is_compliant: values["is_compliant"],
        ownerId: values["ownerId"],
        companyId: values["companyId"],
        children: values["children"],
      };

      dispatch(onAddShip(UpdateFacility));
      // formik.resetForm();
    },
  });

  useEffect(() => {
    if (error && error?.message === "Invalid or expired token") {
      dispatch(logoutUser());
      sessionStorage.removeItem("authUser");
    }
  }, [dispatch, error]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Company" title="Edit Company" />
          <Row>
            <Col xl={12}>
              <div className="card">
                <div className="card-body">
                  <div className="p-2">
                    <Form autoComplete="off" onSubmit={formik.handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="name " className="form-label">
                          Company Name
                        </label>
                        <Form.Control
                          type="name"
                          name="name"
                          className="form-control bg-light border-light"
                          placeholder="Enter Company Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name || ""}
                          isInvalid={
                            formik.touched.name && formik.errors.name
                              ? true
                              : false
                          }
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="name " className="form-label">
                          Compnany Type
                        </label>
                        <Form.Select
                          name="type"
                          className="form-control bg-light border-light"
                          placeholder="Enter Compnany Number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.type || ""}
                          isInvalid={
                            formik.touched.type && formik.errors.type
                              ? true
                              : false
                          }
                        >
                          <option>Select a Company Type</option>
                          {shakeHolder.includes(user.company.companyType) && (
                            <React.Fragment>
                              <option value={FACILITIES_ENUM.OIL_PLATFORM}>
                                {FACILITIES_ENUM.OIL_PLATFORM}
                              </option>
                              <option value={FACILITIES_ENUM.SHIP}>
                                {FACILITIES_ENUM.SHIP}
                              </option>
                              <option value={FACILITIES_ENUM.WASTE_FACILITY}>
                                {FACILITIES_ENUM.WASTE_FACILITY}
                              </option>
                            </React.Fragment>
                          )}
                          {regulator.includes(user.company.companyType) && (
                            <React.Fragment>
                              <option value={FACILITIES_ENUM.PORT_OF_OPERATION}>
                                {FACILITIES_ENUM.PORT_OF_OPERATION}
                              </option>
                              <option
                                value={FACILITIES_ENUM.PORT_RECEPTION_FACILITY}
                              >
                                {FACILITIES_ENUM.PORT_RECEPTION_FACILITY}
                              </option>
                            </React.Fragment>
                          )}
                        </Form.Select>
                        {formik.touched.type && formik.errors.type ? (
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.type}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="name " className="form-label">
                          Registration Number
                        </label>
                        <Form.Control
                          type="registrationNumber"
                          name="registrationNumber"
                          className="form-control bg-light border-light"
                          placeholder="Enter Company Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.registrationNumber || ""}
                          isInvalid={
                            formik.touched.registrationNumber &&
                            formik.errors.registrationNumber
                              ? true
                              : false
                          }
                        />
                        {formik.touched.registrationNumber &&
                        formik.errors.registrationNumber ? (
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.registrationNumber}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>

                      {formik.values.type === "PORT_OF_OPERATION" && (
                        <React.Fragment>
                          <div className="mt-5 mb-3">
                            <hr />

                            <h5>Add Children Facilities</h5>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="name " className="form-label">
                              Child Company
                            </label>
                            <Form.Select
                              name="parentFacility"
                              className="form-control bg-light border-light"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.type || ""}
                              isInvalid={
                                formik.touched.type && formik.errors.type
                                  ? true
                                  : false
                              }
                            >
                              <option>Select a Facility Type</option>
                              <option value={FACILITIES_ENUM.OIL_PLATFORM}>
                                {FACILITIES_ENUM.OIL_PLATFORM}
                              </option>
                              <option value={FACILITIES_ENUM.PORT_OF_OPERATION}>
                                {FACILITIES_ENUM.PORT_OF_OPERATION}
                              </option>
                              <option
                                value={FACILITIES_ENUM.PORT_RECEPTION_FACILITY}
                              >
                                {FACILITIES_ENUM.PORT_RECEPTION_FACILITY}
                              </option>
                              <option value={FACILITIES_ENUM.SHIP}>
                                {FACILITIES_ENUM.SHIP}
                              </option>
                              <option value={FACILITIES_ENUM.WASTE_FACILITY}>
                                {FACILITIES_ENUM.WASTE_FACILITY}
                              </option>
                            </Form.Select>
                            {formik.touched.name && formik.errors.name ? (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                        </React.Fragment>
                      )}
                      {formik.values.type === "PORT_RECEPTION_FACILITY" && (
                        <React.Fragment>
                          <div className="mt-5 mb-3">
                            <hr />

                            <h5>Add Parent Company</h5>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="name " className="form-label">
                              Parent Company
                            </label>
                            <Form.Select
                              name="parentFacility"
                              className="form-control bg-light border-light"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.type || ""}
                              isInvalid={
                                formik.touched.type && formik.errors.type
                                  ? true
                                  : false
                              }
                            >
                              <option>Select a Company Type</option>
                              <option value={FACILITIES_ENUM.OIL_PLATFORM}>
                                {FACILITIES_ENUM.OIL_PLATFORM}
                              </option>
                              <option value={FACILITIES_ENUM.PORT_OF_OPERATION}>
                                {FACILITIES_ENUM.PORT_OF_OPERATION}
                              </option>
                              <option
                                value={FACILITIES_ENUM.PORT_RECEPTION_FACILITY}
                              >
                                {FACILITIES_ENUM.PORT_RECEPTION_FACILITY}
                              </option>
                              <option value={FACILITIES_ENUM.SHIP}>
                                {FACILITIES_ENUM.SHIP}
                              </option>
                              <option value={FACILITIES_ENUM.WASTE_FACILITY}>
                                {FACILITIES_ENUM.WASTE_FACILITY}
                              </option>
                            </Form.Select>
                            {formik.touched.name && formik.errors.name ? (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                        </React.Fragment>
                      )}
                      <React.Fragment>
                        <div className="mt-5 mb-3">
                          <hr />
                          <h5>Address Information</h5>
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col>
                              <label htmlFor="website" className="form-label">
                                Website
                              </label>
                              <Form.Control
                                type="text"
                                name="website"
                                className="form-control bg-light border-light"
                                placeholder="https://example.com"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.website || ""}
                                isInvalid={
                                  formik.touched.website &&
                                  formik.errors.website
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.website &&
                              formik.errors.website ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.website}
                                </Form.Control.Feedback>
                              ) : null}
                            </Col>
                            <Col>
                              <label htmlFor="address" className="form-label">
                                Address
                              </label>
                              <Form.Control
                                type="text"
                                name="address"
                                className="form-control bg-light border-light"
                                placeholder="123 Main Street, City"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.address || ""}
                                isInvalid={
                                  formik.touched.address &&
                                  formik.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.address &&
                              formik.errors.address ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.address}
                                </Form.Control.Feedback>
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col>
                              <label htmlFor="state" className="form-label">
                                State
                              </label>
                              <Form.Control
                                type="text"
                                name="state"
                                className="form-control bg-light border-light"
                                placeholder="Enter State"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state || ""}
                                isInvalid={
                                  formik.touched.state && formik.errors.state
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.state && formik.errors.state ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.state}
                                </Form.Control.Feedback>
                              ) : null}
                            </Col>
                            <Col>
                              <label htmlFor="country" className="form-label">
                                Country
                              </label>
                              <Form.Select
                                name="country"
                                className="form-control bg-light border-light"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.country || ""}
                                isInvalid={
                                  formik.touched.country &&
                                  formik.errors.country
                                    ? true
                                    : false
                                }
                              >
                                <option>Select a country</option>
                                {countries.map((country) => (
                                  <option
                                    key={country.id}
                                    value={country.countryCode}
                                  >
                                    {country.countryName}
                                  </option>
                                ))}
                              </Form.Select>
                              {formik.touched.country &&
                              formik.errors.country ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.country}
                                </Form.Control.Feedback>
                              ) : null}
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Row>
                            <Col>
                              <label htmlFor="longitude" className="form-label">
                                Longitude
                              </label>
                              <Form.Control
                                type="number"
                                name="longitude"
                                className="form-control bg-light border-light"
                                placeholder="Enter longitude"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.longitude || ""}
                                isInvalid={
                                  formik.touched.longitude &&
                                  formik.errors.longitude
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.longitude &&
                              formik.errors.longitude ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.longitude}
                                </Form.Control.Feedback>
                              ) : null}
                            </Col>
                            <Col>
                              <label htmlFor="latitude" className="form-label">
                                Latitude
                              </label>
                              <Form.Control
                                type="number"
                                name="latitude"
                                className="form-control bg-light border-light"
                                placeholder="Enter latitude"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.latitude || ""}
                                isInvalid={
                                  formik.touched.latitude &&
                                  formik.errors.latitude
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.latitude &&
                              formik.errors.latitude ? (
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.latitude}
                                </Form.Control.Feedback>
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </React.Fragment>
                      {formik.values.type === "SHIP" && (
                        <React.Fragment>
                          {" "}
                          <div className="mt-5 mb-3">
                            <hr />
                            <h5>Ship Information</h5>
                          </div>
                          <div className="mb-3">
                            <Row>
                              <Col>
                                <label
                                  htmlFor="imoNumber"
                                  className="form-label"
                                >
                                  Imo Number
                                </label>
                                <Form.Control
                                  type="text"
                                  name="imoNumber"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter Imo Number"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.imoNumber || ""}
                                  isInvalid={
                                    formik.touched.imoNumber &&
                                    formik.errors.imoNumber
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.imoNumber &&
                                formik.errors.imoNumber ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.imoNumber}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col>
                                <label
                                  htmlFor="mmsiNumber"
                                  className="form-label"
                                >
                                  MMSI Number
                                </label>
                                <Form.Control
                                  type="text"
                                  name="mmsiNumber"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter MMSI Number"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.mmsiNumber || ""}
                                  isInvalid={
                                    formik.touched.mmsiNumber &&
                                    formik.errors.mmsiNumber
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.mmsiNumber &&
                                formik.errors.mmsiNumber ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.mmsiNumber}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Row>
                              <Col>
                                <label htmlFor="flag" className="form-label">
                                  Flag Bearing
                                </label>
                                <Form.Control
                                  type="text"
                                  name="flag"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter Flag Bearing"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.flag || ""}
                                  isInvalid={
                                    formik.touched.flag && formik.errors.flag
                                      ? true
                                      : false
                                  }
                                />

                                {formik.touched.flag && formik.errors.flag ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.flag}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>

                              <Col>
                                <label
                                  htmlFor="shipType"
                                  className="form-label"
                                >
                                  Ship Type
                                </label>
                                <Form.Control
                                  type="text"
                                  name="shipType"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter Ship Type"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.shipType || ""}
                                  isInvalid={
                                    formik.touched.shipType &&
                                    formik.errors.shipType
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.shipType &&
                                formik.errors.shipType ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.shipType}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Row>
                              <Col>
                                <label
                                  htmlFor="grossTonnage"
                                  className="form-label"
                                >
                                  Gross Tonnage
                                </label>
                                <Form.Control
                                  type="number"
                                  name="grossTonnage"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter grossTonnage"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.grossTonnage || ""}
                                  isInvalid={
                                    formik.touched.grossTonnage &&
                                    formik.errors.grossTonnage
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.grossTonnage &&
                                formik.errors.grossTonnage ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.grossTonnage}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col>
                                <label
                                  htmlFor="yearBuilt"
                                  className="form-label"
                                >
                                  Year Built
                                </label>
                                <Form.Control
                                  type="number"
                                  name="yearBuilt"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter Year Built"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.yearBuilt || ""}
                                  isInvalid={
                                    formik.touched.yearBuilt &&
                                    formik.errors.yearBuilt
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.yearBuilt &&
                                formik.errors.yearBuilt ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.yearBuilt}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Row>
                              <Col>
                                <label htmlFor="length" className="form-label">
                                  Length
                                </label>
                                <Form.Control
                                  type="number"
                                  name="length"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter Length"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.length || ""}
                                  isInvalid={
                                    formik.touched.length &&
                                    formik.errors.length
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.length &&
                                formik.errors.length ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.length}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col>
                                <label htmlFor="beam" className="form-label">
                                  Beam
                                </label>
                                <Form.Control
                                  type="number"
                                  name="beam"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter Beam"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.beam || ""}
                                  isInvalid={
                                    formik.touched.beam && formik.errors.beam
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.beam && formik.errors.beam ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.beam}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        </React.Fragment>
                      )}
                      {user.company.companyType === "PLATFORM ADMIN" && (
                        <React.Fragment>
                          <div className="mb-3">
                            <Row>
                              <Col>
                                <label
                                  htmlFor="is_compliant"
                                  className="form-label"
                                >
                                  Compliance Status
                                </label>
                                <Form.Select
                                  name="is_compliant"
                                  className="form-control bg-light border-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.is_compliant || ""}
                                  isInvalid={
                                    formik.touched.is_compliant &&
                                    formik.errors.is_compliant
                                      ? true
                                      : false
                                  }
                                >
                                  <option value={"true"}>True</option>
                                  <option value={"false"}>False</option>
                                </Form.Select>
                                {formik.touched.is_compliant &&
                                formik.errors.is_compliant ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.is_compliant}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Row>
                              <Col>
                                <label
                                  htmlFor="complaince_notes"
                                  className="form-label"
                                >
                                  Compliance Notes
                                </label>
                                <Form.Control
                                  type="text"
                                  as={"textarea"}
                                  name="complaince_notes"
                                  className="form-control bg-light border-light"
                                  placeholder="Enter complaince notes"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.complaince_notes || ""}
                                  isInvalid={
                                    formik.touched.complaince_notes &&
                                    formik.errors.complaince_notes
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.complaince_notes &&
                                formik.errors.complaince_notes ? (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.complaince_notes}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="hstack gap-2 justify-content-end">
                        <Button type="submit" className="btn btn-success">
                          Update Company
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCompanyDetails;
