import React, { useMemo, useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import CertificateApplicationModal from "../../../Common/CrudModal/CertificateApplicationModal";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import { getCertApplications as onGetCertApplications } from "../../../slices/certificates/thunk";
// import { StopApplication } from "../../../Common/CrudModal/StopApplicationModal";
// import EditApplication from "../../../Common/CrudModal/EditApplication";

interface userProps {
  isShow: any;
  hideUserModal: any;
}

interface ApplicationData {
  id: string;
  applicationTitle: string;
  description: string;
  status: string;
  target_name: string;
}

const ApplicationTable = ({ isShow, hideUserModal }: userProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const [applicationList, setApplicationList] = useState<ApplicationData[]>([]);

  const state = useSelector((state: any) => state); // Logs the entire state

  console.log("Redux State:", state.Certification);
  // Selector to get the applicationList from the Redux state
  const selectApplicationList = createSelector(
    (state: any) => state.Certification,
    (certifications: any) => certifications.applicationList
  );

  const applicationListFromRedux = useSelector(selectApplicationList);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: 10,
      status: "PENDING",
      type: "CERTIFICATION",
    };
    dispatch(onGetCertApplications(params));
  }, [dispatch]);

  const handleRowClick = (row: any) => {

    const application = row.row.original;
    // Create a plain object with only the necessary, serializable data
    const serializableApplicationDetails = {
      id: application.id,
      applicationTitle: application.applicationTitle,
      description: application.description,
      status: application.status,
      target_name: application.target_name,
      companyName: application.companyName,
      type:application.type,
      applicantId:application.applicantId
    };
  
    navigate(`/cert-applications/${application.id}`, {
      state: { applicationDetails: serializableApplicationDetails }
    });
  };

  console.log(applicationListFromRedux);

  useEffect(() => {
    if (
      applicationListFromRedux &&
      applicationListFromRedux.data &&
      applicationListFromRedux.data.applications
    ) {
      const mappedApplications =
        applicationListFromRedux?.data?.applications?.map((app: any) => ({
          id: app.id,
          applicationTitle: app.title,
          description: app.description,
          status: app.status.toLowerCase(),
          target_name: app.target_name,
          companyName: app.companyName,
          type:app.type,
        })) || [];

      setApplicationList(mappedApplications);
    }
  }, [applicationListFromRedux]);

  const columns: any[] = useMemo(
    () => [
      {
        Header: "Application Title",
        accessor: "applicationTitle",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Facility Name",
        accessor: "target_name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell:any) => {
          switch (cell.row.original.status) {
            case "active":
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
            case "pending":
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status}
                </span>
              );
            case "disabled":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            default:
              return (
                <span className="badge bg-secondary-subtle text-secondary p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <Col sm={4}>
          <button
            className="btn btn-primary addPayment-modal"
            onClick={hideUserModal}
          >
            <i className="las la-plus me-1"></i> Apply for certificate
          </button>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {applicationList && applicationList.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={applicationList || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  onRowClick={handleRowClick}
                  PaginationClass="align-items-center mt-4 gy-3"
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <CertificateApplicationModal
        isShow={isShow}
        handleClose={hideUserModal}
      />

      {/* <EditApplication
        isShow={editCert}
        handleClose={handleCloseEdit}
        edit={edit}
      />

      <StopApplication show={stopApplication} handleClose={handleStopModal} /> */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default ApplicationTable;
