import React, { useState } from "react";
import WasteManagementTable from "./WasteManagementTable";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../../Common/BreadCrumb";

const WasteManagementFacility = () => {
  document.title = "Facility Management | Maritime Waste Management Admin & Dashboard Platform";

  const [isShow, setIsShow] = useState(false);

  const hideWasteManagementModal = () => {
    setIsShow(!isShow);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Facility Managements" title="Facility Managements" />
          <WasteManagementTable isShow={isShow} hideWasteManagementModal={hideWasteManagementModal} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WasteManagementFacility;
