import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { addLicenseApplication as onAddLicenseApplication } from "../../slices/license/thunk";
import BreadCrumb from '../../Common/BreadCrumb';
import MakePayments from '../../Common/CrudModal/MakePayment';

const ApplicationCreationDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { applicationDetails } = location.state || {};
  const dispatch = useDispatch();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [paymentWasteNotifications, setPaymentWasteNotifications] =
    useState<boolean>(false);
  const [collectionWasteNotifications, setCollectionWasteNotifications] =
    useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      setAttachments(prevAttachments => [...prevAttachments, ...fileArray]);
    }
  };

  const removeAttachment = (index: number) => {
    setAttachments(prevAttachments =>
      prevAttachments.filter((_, fileIndex) => fileIndex !== index)
    );
  };

  const handlePaymentWasteNotifications = () => {
    setPaymentWasteNotifications(true);
  };

  const handleCloseWasteCollection = () =>
    setCollectionWasteNotifications(false);

  const handleClosePayment = () => setPaymentWasteNotifications(false);





  const formik = useFormik({
    initialValues: {
      vesselName: '',
      registrationNumber: '',
      ownerName: '',
      ownerAddress: '',
      contactNumber: '',
      email: '',
      additionalNotes: ''
    },
    validationSchema: Yup.object({
      // vesselName: Yup.string().required('Vessel name is required'),
      // registrationNumber: Yup.string().required('Registration number is required'),
      // ownerName: Yup.string().required('Owner name is required'),
      // ownerAddress: Yup.string().required('Owner address is required'),
      // contactNumber: Yup.string().required('Contact number is required'),
      // email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: async () => {
      const applicationData = {
        ...applicationDetails,

        title: applicationDetails.title,
        description: applicationDetails.description,
        type: "LICENSE",
        target_id: applicationDetails.target_id,
        target_type: applicationDetails.target_type
      };
      try {
        await dispatch(onAddLicenseApplication(applicationData));
        formik.resetForm();
        setAttachments([]);
        navigate('/license-management/apply');
        window.location.reload();
      } catch (error) {
        console.error("Failed to add license application:", error);
      }
    }
  });

  if (!applicationDetails) {
    return (
      <div className="container mt-5">
        <Card>
          <Card.Body>
            <h3>No application details found</h3>
            <Button onClick={() => navigate('/')}>Return to Home</Button>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Application Creation Details" title="Application Creation Details" />
          <div className="mt-5">
            <Card className="mb-4">
              <Card.Header>
                <h4>Initial Application Details</h4>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <p><strong>License Type:</strong> {applicationDetails.title}</p>
                    <p><strong>Target ID:</strong> {applicationDetails.target_id}</p>
                  </Col>
                  <Col md={6}>
                    <p><strong>Target Type:</strong> {applicationDetails.target_type}</p>
                    <p><strong>Description:</strong> {applicationDetails.description}</p>
                  </Col>
                  <Col md={6}>
                    <p><strong>Facility Name:</strong> {applicationDetails.facility}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>
                <h4>Additional Information</h4>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Vessel Name</Form.Label>
                        <Form.Control
                          type="text"
                          {...formik.getFieldProps('vesselName')}
                          isInvalid={!!formik.errors.vesselName && formik.touched.vesselName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.vesselName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Registration Number</Form.Label>
                        <Form.Control
                          type="text"
                          {...formik.getFieldProps('registrationNumber')}
                          isInvalid={!!formik.errors.registrationNumber && formik.touched.registrationNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.registrationNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Owner Name</Form.Label>
                        <Form.Control
                          type="text"
                          {...formik.getFieldProps('ownerName')}
                          isInvalid={!!formik.errors.ownerName && formik.touched.ownerName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.ownerName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Owner Address</Form.Label>
                        <Form.Control
                          type="text"
                          {...formik.getFieldProps('ownerAddress')}
                          isInvalid={!!formik.errors.ownerAddress && formik.touched.ownerAddress}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.ownerAddress}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control
                          type="text"
                          {...formik.getFieldProps('contactNumber')}
                          isInvalid={!!formik.errors.contactNumber && formik.touched.contactNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.contactNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          {...formik.getFieldProps('email')}
                          isInvalid={!!formik.errors.email && formik.touched.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Additional Notes</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          {...formik.getFieldProps('additionalNotes')}
                        />
                      </Form.Group>
                    </Col>

                    {/* File Attachment Section */}
                    <Col md={12}>
                      <Card className="mt-3 mb-3">
                        <Card.Header>
                          <h5>Attachments</h5>
                        </Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>Upload Documents</Form.Label>
                            <Form.Control
                              type="file"
                              multiple
                              onChange={handleFileChange}
                              className="mb-3"
                            />
                          </Form.Group>

                          {/* Display attached files */}
                          {attachments.length > 0 && (
                            <div className="mt-3">
                              <h6>Attached Files:</h6>
                              <ul className="list-group">
                                {attachments.map((file, index) => (
                                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                    {file?.name}
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() => removeAttachment(index)}
                                    >
                                      Remove
                                    </Button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                        </Card.Body>
                      </Card>
                    </Col>

                  </Row>

                  <div className="d-flex justify-content-end gap-2">
                    <Button variant="secondary" onClick={() => navigate('/license-management/apply')}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="primary" >
                      Submit Application
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>

      <MakePayments
        amountDue={500000}
        isShow={paymentWasteNotifications}
        // handlePayment={submitPayment}
        handleClose={handleClosePayment}
        handleShow={handlePaymentWasteNotifications}
      />
    </React.Fragment>
  );
};

export default ApplicationCreationDetails;