import { createSlice } from "@reduxjs/toolkit";
import {
  getCertApplications,
  addCertApplication,
  approveCertApplication,
  rejectCertApplication,
  getCertificates

} from "./thunk";


export const initialState = {
  applicationList: [],
  error: {},
  application: {},
  cetificateList:[]
}


const CertificateSlice: any = createSlice({
  name: "Certificate",
  initialState,
  reducers: {},

  extraReducers: (builder: any) => {
    builder.addCase(getCertApplications.fulfilled, (state: any, action: any) => {
      state.applicationList = action.payload;
    });
    builder.addCase(getCertApplications.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    //add
    builder.addCase(addCertApplication.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        // Replace .push with direct assignment
        state.application = action.payload.data;
        state.error = null;
      }
    });

    builder.addCase(addCertApplication.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    })

    builder.addCase(approveCertApplication.fulfilled, (state: any, action: any) => {
      const updatedApplication = action.payload?.data?.application; // Extract the application object

      if (updatedApplication) {
        state.application = updatedApplication; // Directly set the updated application object
      }
    });

    builder.addCase(approveCertApplication.rejected, (state: any, action: any) => {
      state.error = action.payload?.message || "An error occurred";
    });


    builder.addCase(rejectCertApplication.fulfilled, (state: any, action: any) => {
      const updatedApplication = action.payload?.data?.application; // Extract the application object

      if (updatedApplication) {
        state.application = updatedApplication; // Directly set the updated application object
      }
    });

    builder.addCase(rejectCertApplication.rejected, (state: any, action: any) => {
      state.error = action.payload || null;
    });


    builder.addCase(getCertificates.fulfilled, (state: any, action: any) => {
      state.cetificateList = action.payload;
    });
    builder.addCase(getCertificates.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });


  }
})


export default CertificateSlice.reducer;