import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { addPortOfOperations as onAddPortOfOperations } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import { PatternFormat } from "react-number-format";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
}

const AddPortOfOperations = ({
  isShow,
  handleClose,
  handleShow,
}: userProps) => {
  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      // id: "",
      name: "",
      // logo: "",
      address: "",
      latitude: "",
      longitude: "",
      state: "",
      country: "",
      // status: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Member Name"),
      // logo: Yup.string().required("Please Select Member Name"),
      // email: Yup.string()
      //   .email()
      //   .matches(/^(?!.*@[^,]*,)/)
      //   .required("Please Enter Your Email"),
      address: Yup.string().required("Please Enter address"),
      latitude: Yup.number().required("Please Enter latitude"),
      longitude: Yup.number().required("Please Enter longitude"),
      state: Yup.string().required("Please Enter State"),
      country: Yup.string().required("Please Enter Country"),
      // status: Yup.string().required("Please choose Your status"),
    }),

    onSubmit: (values: any) => {
      const NewPortOfOperation = {
        name: values["name"],
        address: values["address"],
        latitude: values["latitude"],
        longitude: values["longitude"],
        state: values["state"],
        country: values["country"],
      };

      try {
        dispatch(onAddPortOfOperations(NewPortOfOperation));
      formik.resetForm();
      } catch (error) {
        
      }
      

      if (NewPortOfOperation === null) {
        handleShow();
      } else {
        handleClose();
      }
    },
  });

  // useEffect(() => {
  //   setSelectedImage("");
  // }, [handleClose]);

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Add Port Of Operation</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="users">Name</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="Name">Address</Form.Label>
                <Form.Control
                  id="address"
                  name="address"
                  placeholder="Enter address"
                  value={formik.values.address || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.address}
                />
                {formik.errors.address && formik.touched.address ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.address}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">State</Form.Label>
                    <Form.Control
                      id="state"
                      name="state"
                      type="text"
                      placeholder="Enter state"
                      value={formik.values.state || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.state}
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.state}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Country</Form.Label>
                    <Form.Control
                      id="country"
                      name="country"
                      type="text"
                      placeholder="Enter country"
                      value={formik.values.country || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.country}
                    />
                    {formik.errors.country && formik.touched.country ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.country}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Latitude</Form.Label>
                    <Form.Control
                      id="latitude"
                      name="latitude"
                      type="number"
                      placeholder="Enter latitude"
                      value={formik.values.latitude || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.latitude}
                    />
                    {formik.touched.latitude && formik.errors.latitude ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.latitude}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Longitude</Form.Label>
                    <Form.Control
                      id="longitude"
                      name="longitude"
                      type="number"
                      placeholder="Enter longitude"
                      value={formik.values.longitude || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.longitude}
                    />
                    {formik.errors.longitude && formik.touched.longitude ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.longitude}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                {/* <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Registered Date</Form.Label>
                    <FlatPickr
                      className="form-control"
                      id="registeredOn"
                      name="registeredOn"
                      placeholder="Select date"
                      options={{
                        mode: "single",
                        dateFormat: "d M, Y",
                      }}
                      onChange={(registeredOn: any) =>
                        formik.setFieldValue(
                          "registeredOn",
                          moment(registeredOn[0]).format("DD MMMM ,YYYY")
                        )
                      }
                      value={formik.values.registeredOn || ""}
                    />
                    {formik.errors.registeredOn &&
                    formik.touched.registeredOn ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.registeredOn}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col> */}
              </Row>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-success">
                  Add PortOfOperation
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddPortOfOperations;
