import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { handleSearchData } from "../../../Common/Tabledata/SorttingData";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import ViewApplicationDetails from "../../../Common/CrudModal/ViewApplication";
import { getCertApplications as onGetCertApplications } from "../../../slices/certificates/thunk";

interface userProps {
  isShow: any;
  hideUserModal: any;
}

interface ApplicationData {
  title: string;
  description: string;
  companyName: string;
  status: string;
  id: string;
  // facilitName:string
}

const PendingApplicationTable = ({ isShow, hideUserModal }: userProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [applicationList, setApplicationList] = useState<ApplicationData[]>([]);

  // Selector to get the applicationList from the Redux state
  const selectApplicationList = createSelector(
    (state: any) => state.Certification,
    (certifications: any) => certifications.applicationList
  );

  // Destructuring applicationList from Redux store using useSelector
  const applicationListFromRedux = useSelector(selectApplicationList);

  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: 10,
      status: "PENDING",
      type: "CERTIFICATION",

    };
    dispatch(onGetCertApplications(params));
  }, [dispatch]);


  const handleRowClick = (row: any) => {

    const application = row.row.original;
    // Create a plain object with only the necessary, serializable data
    const serializableApplicationDetails = {
      id: application.id,
      applicationTitle: application.applicationTitle,
      description: application.description,
      status: application.status,
      target_name: application.target_name,
      companyName: application.companyName,
      type:application.type,
  
    };
  
    navigate(`/pending-applications/${application.id}`, {
      state: { applicationDetails: serializableApplicationDetails }
    });
  };

  console.log(applicationListFromRedux);


  useEffect(() => {
    if (
      applicationListFromRedux &&
      applicationListFromRedux.data &&
      applicationListFromRedux.data.applications
    ) {
      const mappedApplications =
        applicationListFromRedux?.data?.applications?.map((app: any) => ({
          id: app.id,
          applicationTitle: app.title,
          description: app.description,
          companyName: app.companyName,
          target_name: app.target_name,
          status: app.status.toLowerCase(),
          type:app.type
        })) || [];

      setApplicationList(mappedApplications);
    }
  }, [applicationListFromRedux]);

  const [showApplicationDetailsModal, setShowApplicationDetailsModal] =
    useState(false);
  const [selectedApplication, setSelectedApplication] = useState<any>(null);

  const handleViewCertDetails = (applications: any) => {
    setSelectedApplication(applications);
    setShowApplicationDetailsModal(true);
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "Application Id",
        accessor: "id",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Application Title",
        accessor: "applicationTitle",
        Filter: false,
        isSortable: true,
      },

      {
        Header: "Description",
        accessor: "description",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Facility Name",
        accessor: "target_name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "Company Name",
        accessor: "companyName",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        style: { width: "12%" },

        isSortable: false,
        Cell: (cell: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            <li
              className="list-inline-item"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-placement="top"
              aria-label="View"
              data-bs-original-title="View"
            >
              <Link
                to="#"
                className="btn btn-soft-info btn-sm d-inline-block"
                onClick={() => handleViewCertDetails(cell.row.original)}
              >
                <i className="las la-eye fs-17 align-middle"></i>
              </Link>
            </li>
          </ul>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                id="searchMemberList"
                placeholder="Search for Result"
                // onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                variant="info"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>All</Dropdown.Item>
                <Dropdown.Item>Last Week</Dropdown.Item>
                <Dropdown.Item>Last Month</Dropdown.Item>
                <Dropdown.Item>Last Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {applicationList && applicationList.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={applicationList || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  PaginationClass="align-items-center mt-4 gy-3"
                  onRowClick={handleRowClick}
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <ViewApplicationDetails
        isShow={showApplicationDetailsModal}
        handleClose={() => setShowApplicationDetailsModal(false)}
        applicationDetails={selectedApplication}
      /> */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default PendingApplicationTable;
