import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    addLicenseApplication as addLicenseApplicationApi,
    getLicenseApplications as getLicenseApplicationsApi,
    approveLicenseApplication as approveLicenseApplicationApi,
    rejectLicenseApplication as rejectLicenseApplicationApi,
    getLicenses as getLicesesApi

} from "../../helpers/fakebackend_helper"
import {toast} from "react-toastify";

export const getLicenseApplications = createAsyncThunk("licenseApplications/getAll", async (params:any) => {
    try {
        const response = getLicenseApplicationsApi(params);
        return response;
    } catch (error){
        toast.error("Failed to Add Application", {autoClose:2000});
        return error;
    }
});

export const addLicenseApplication = createAsyncThunk(
    "licenseApplications/add",
    async (application: any , { rejectWithValue }) => {
      try {
        const response = await addLicenseApplicationApi(application);
        toast.success("Application added Successfully", { autoClose: 2000 });
        return response;
      } catch (error:any) {
        toast.error("Failed to Add Application", { autoClose: 2000 });
        return rejectWithValue(error.message || "An error occurred");
      }
    }
  );

  export const approveLicenseApplication = createAsyncThunk(
    "licenseApplications/approve",
    async (applicationId: string, { rejectWithValue }) => {
      try {
        const response = await approveLicenseApplicationApi(applicationId);
        toast.success("License application approved successfully", { autoClose: 2000 });
        return response;
      } catch (error: any) {
        toast.error("Failed to approve license application", { autoClose: 2000 });
        return rejectWithValue(error.message || "An error occurred");
      }
    }
  );
  
  // Reject License Application
  export const rejectLicenseApplication = createAsyncThunk(
    "licenseApplications/reject",
    async (
      { applicationId}: { applicationId: string },
      { rejectWithValue }
    ) => {
      try {
        const response = await rejectLicenseApplicationApi(applicationId);
        toast.success("License application rejected successfully", { autoClose: 2000 });
        return response;
      } catch (error: any) {
        toast.error("Failed to reject license application", { autoClose: 2000 });
        return rejectWithValue(error.message || "An error occurred");
      }
    }
  );


  
export const getLicenses = createAsyncThunk(
  "licenses/getAll",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await getLicesesApi(params);
      return response;
    } catch (error: any) {
      toast.error("Failed to fetch applications", { autoClose: 2000 });
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
  