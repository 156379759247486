import { createSlice } from "@reduxjs/toolkit";
import {
  addFacility,
  deleteFacility,
  editFacility,
  getFacilities,
} from "./thunk";

export const initialState = {
  facilityList: [],
  error: {},
};

const FacilitySlice: any = createSlice({
  name: "Facilities",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(getFacilities.fulfilled, (state: any, action: any) => {
        // console.log(action, "action");
        state.facilityList = action.payload.flat(1);
      })
      .addCase(getFacilities.rejected, (state: any, action: any) => {
        console.log(action, "action");

        state.error = action.payload?.error || null;
      });

    //add
    builder.addCase(addFacility.fulfilled, (state: any, action: any) => {
      state.facilityList.push(action.payload?.data);
    });
    builder.addCase(addFacility.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    //edit
    builder.addCase(editFacility.fulfilled, (state: any, action: any) => {
      state.facilityList = state.facilityList.map((facility: any) =>
        facility.id === action.payload.data.id
          ? { ...facility, ...action.payload.data }
          : facility
      );
    });
    builder.addCase(editFacility.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    //delete
    builder
      .addCase(deleteFacility.fulfilled, (state: any, action: any) => {
        state.facilityList = state.facilityList.filter(
          (facility: any) => facility.id !== action.payload.id
        );
      })
      .addCase(deleteFacility.rejected, (state: any, action: any) => {
        state.error = action.payload?.error || null;
      });
  },
});

export default FacilitySlice.reducer;
