import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Col, Dropdown, Form, Table } from "react-bootstrap";
import { useTable, usePagination, CellProps } from "react-table";
import Mock_data from "./mock_data.json";
import { useNavigate } from "react-router-dom";
import { UserTypeEnum } from "../../helpers/enums/user.enum";
import ViewCompanyDetails from "./ViewCompanyDetails";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { DisableCompanyModal } from "../../Common/CrudModal/DisableCompanyModal";

const CompanyDetails = () => {
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const navigate = useNavigate();

  const [deactivated, setDeactivated] = useState<boolean>(false);
  const [stopId, setStopId] = useState<any>();
  const [showDisabled, setShowDisabled] = useState<boolean>(false);
  const handleStopModal = useCallback(
    (id: any) => {
      setDeactivated(!deactivated);
      setStopId(id);
      console.log("this is the id for the disable table =>", id);
    },
    [deactivated],
  );
  const handleAgree = useCallback(
    (id: any) => {
      setDeactivated(!deactivated);
    //   setStopId(id);
      setShowDisabled(!showDisabled);
    //   console.log("this is the id for the disable table =>", id);
    },
    [deactivated],
  );

  const navigateUser = (rowId: any) => {
    navigate(`/company-details/${rowId}`);
  };
  const COlUMNS = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "ADDRESS",
      accessor: "address",
    },
    {
      Header: "STATE",
      accessor: "state",
    },
    {
      Header: "COUNTRY",
      accessor: "country",
    },
    {
      Header: "MMSI NUMBER",
      accessor: "mmsiNumber",
    },
    {
      Header: "TYPE",
      accessor: "type",
    },
    {
      Header: "CREATED AT",
      accessor: "createdAt",
    },
    {
      Header: "COMPLIANT",
      accessor: "is_compliant",
      Cell: (cell: any) => (
        <div>
          {cell?.value === true ? (
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "#def1de",
                color: "#5cc0a7",
                padding: "3px 3px",
                borderRadius: "20px",
              }}
            >
              Active
            </div>
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "#fdecec",
                color: "red",
                padding: "3px 3px",
                borderRadius: "20px",
              }}
            >
              Inactive
            </div>
          )}
        </div>
      ),
    },
    {
      Header: "ACTION",
      accessor: "",
      Cell: (cell: any) => (
        <ul className="list-inline hstack gap-2 mb-0">
          <li
            className="list-inline-item"
            onClick={() => {
              const item = cell.row.original;
              handleStopModal(item);
            }}
          >
            <a className="btn btn-soft-danger btn-sm d-inline-block" href="#">
              <i className="ri-delete-bin-2-line fs-17 align-middle"></i>
            </a>
          </li>
          {/* <li className="list-inline-item edit">
            <a className="btn btn-soft-info btn-sm d-inline-block" href="#">
              <i className="las la-pen fs-17 align-middle"></i>
            </a>
          </li> */}
        </ul>
      ),
    },
  ];

  const columns = useMemo(() => COlUMNS, []);
  const data = useMemo(() => Mock_data, []);
  const FacilityTable = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
  } = FacilityTable;
  const {  pageIndex, pageSize} = state;
  const handleRowClick = (rowData: any) => {
    const rowId = rowData.original.id;
    setSelectedRowId(rowId);
    console.log("Clicked row ID:", rowId);
  };
  const selectState = createSelector(
    (state: any) => state.Ship,
    (state: any) => state.Login,
    (ship: any, login: any) => ({
      user: login.user,
      error: ship.error,
      shipList: ship.shipList,
    }),
  );

  const { error, user } = useSelector(selectState);

  const [Show, setShow] = useState(false);
  const onClick = () => {
    setShow(!Show);
    console.log(Show);
  };
  return (
    <Fragment>
      {user.type === UserTypeEnum.NIMASA ? (
        <div
          style={{
            paddingTop: 90,
            backgroundColor: "#f5f5f5",
            height: "100vh",
          }}
        >
          <Col style={{ width: "98%", margin: "auto" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="mb-sm-0">Company Profile</h4>
              </div>
              <div>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="#">Company Profile</a>
                  </li>
                  <li className="breadcrumb-item active">Company Profile</li>
                </ol>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-3">
              {/* <button className="btn btn-primary d-flex align-items-center gap-2 px-4">
            <span className="fs-22 ">+</span>{" "}
            <div className="mt-1">Add New</div>
          </button> */}
              <div className="d-flex gap-4">
                <div>
                  <div className="search-box">
                    <Form.Control
                      type="text"
                      id="searchMemberList"
                      placeholder="Search for Result"
                    />
                    <i className="las la-search search-icon"></i>
                  </div>
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    as="button"
                    variant="info"
                    className="btn btn-soft-info btn-icon fs-14 arrow-none"
                  >
                    <i className="las la-ellipsis-v fs-18"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>All</Dropdown.Item>
                    <Dropdown.Item>Last Week</Dropdown.Item>
                    <Dropdown.Item>Last Month</Dropdown.Item>
                    <Dropdown.Item>Last Year</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="mt-3 p-2" style={{ backgroundColor: "white" }}>
              <Table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup: any) => (
                    <tr
                      key={headerGroup.id}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column: any) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row: any) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() => handleRowClick(row)}
                        // className={`${showDisabled && stopId?.id === row.original.id ? "text-secondary" : ""}`}
                        style={{
                            color:selectedRowId === row.original.id ? "gray" : "inherit",
                          }}
                      >
                        {row.cells.map((cell: any) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  Showing <strong>{pageIndex + 1}</strong> of{" "}
                  <strong>{pageOptions.length}</strong> Results
                </div>
                <div className="d-flex gap-2">
                  <button
                    onClick={() => previousPage()}
                    style={{
                      backgroundColor: "white",
                      padding: "0px 8px",
                      border: "2px solid #f5f5f5",
                    }}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>{" "}
                  <div className="btn btn-primary">{pageOptions.length}</div>
                  <button
                    style={{
                      backgroundColor: "white",
                      padding: "0px 25px",
                      border: "2px solid #f5f5f5",
                    }}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </div>
      ) : (
        <ViewCompanyDetails />
      )}
      <DisableCompanyModal show={deactivated} handleClose={handleStopModal} disableMoidalFuction={handleAgree} />
    </Fragment>
  );
};

export default CompanyDetails;
