import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { PaymentActivityData } from "./PaymentActivitydata";
// import Structure from "./Structure";
import StackedColumn2 from "../Components/Apexcharts/StackedColumn2";
import { CompanyTypeEnum } from "../../helpers/enums/company.enum";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import WasteChart from "./WasteChart";

const PaymentActivity = () => {
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { company } = useSelector(selectAccountAndLogin);

  return (
    <React.Fragment>
      <Row>
        {/* <Col xl={8}> */}
        <Col xl={12}>
          <Card>
            <Card.Header className="border-0 align-items-center d-flex">
              {company?.companyType ===
                CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
              company?.companyType ===
                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ? (
                <h4 className="card-title mb-0 flex-grow-1">
                  Waste Collection
                </h4>
              ) : (
                <h4 className="card-title mb-0 flex-grow-1">
                  Waste Declaration
                </h4>
              )}
              <div className="d-flex gap-1">
                <button type="button" className="btn btn-soft-info btn-sm">
                  ALL
                </button>
                <button type="button" className="btn btn-soft-info btn-sm">
                  1M
                </button>
                <button type="button" className="btn btn-soft-info btn-sm">
                  6M
                </button>
                <button type="button" className="btn btn-info btn-sm">
                  1Y
                </button>
              </div>
            </Card.Header>
            <Card.Body className="py-1">
              <Row className="gy-2">
                <Col md={4}>
                  <h4 className="fs-22 mb-0">
                    ₦
                    {company?.companyType ===
                    CompanyTypeEnum.PORT_RECEPTION_FACILITIES
                      ? "2,403,590.00"
                      : company?.companyType ===
                        CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING
                      ? "1,403,590.00"
                      : company?.companyType ===
                        CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY
                      ? "1,003,590.00"
                      : company?.companyType ===
                        CompanyTypeEnum.NIGERIA_PORT_AUTHORITY
                      ? "2,003,590.00"
                      : company?.companyType ===
                        CompanyTypeEnum.OIL_AND_GAS_PLATFORM
                      ? "4,403,590.00"
                      : "3,403,590.00"}
                  </h4>
                </Col>
                {/* <Col md={8}>
                  <div className="d-flex main-chart justify-content-end">
                    <div className="px-4 border-end">
                      <h4 className="text-primary fs-22 mb-0">
                        ₦584k{" "}
                        <span className="text-muted d-inline-block fs-17 align-middle ms-0 ms-sm-2">
                          Incomes
                        </span>
                      </h4>
                    </div>
                    <div className="ps-4">
                      <h4 className="text-primary fs-22 mb-0">
                        ₦324k{" "}
                        <span className="text-muted d-inline-block fs-17 align-middle ms-0 ms-sm-2">
                          Expenses
                        </span>
                      </h4>
                    </div>
                  </div>
                </Col> */}
              </Row>
              {/* <StackedColumn2 dataColors='["--in-primary", "--in-success", "--in-warning"]' /> */}
              <WasteChart dataColors='["--in-danger", "--in-primary", "--in-success", "--in-warning", "--in-info"]' />

              {/* <PaymentActivityData
                className="apex-charts"
                dataColors='["--in-primary", "--in-light"]'
              /> */}
            </Card.Body>
          </Card>
        </Col>

        {/* <Structure /> */}
      </Row>
    </React.Fragment>
  );
};

export default PaymentActivity;
