import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFacilities as getFacilitiesApi,
  addFacility as addFacilityApi,
  editFacility as editFacilityApi,
  deleteFacility as deleteFacilityApi,
} from "../../helpers/fakebackend_helper";
import { toast } from "react-toastify";

export const getFacilities = createAsyncThunk("facilities/fetchFacilities", async (userType: string) => {
  try {
    const response = getFacilitiesApi(userType);
    return response;
  } catch (error) {
    console.log(error)
    toast.error("Failed to get Facility", { autoClose: 2000 });
    return error;
  }
});

export const addFacility = createAsyncThunk(
  "facilities/addFacilities",
  async (facility: any) => {
    try {
      const response = addFacilityApi(facility);
      const data = await response;

      toast.success("Facility added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Failed to Add Facility", { autoClose: 2000 });
      return error;
    }
  }
);

export const editFacility = createAsyncThunk(
  "facilities/editFacilities",
  async (facility) => {
    try {
      const response = editFacilityApi(facility);
      const data = await response;

      toast.success("Facility edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Failed to Edit Facility", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteFacility = createAsyncThunk(
  "facilities/deleteFacilities",
  async (id: any) => {
    try {
      const response = deleteFacilityApi(id);
      const data = await response;

      toast.success("Facility edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Failed to Edit Facility", { autoClose: 2000 });
      return error;
    }
  }
);
