import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Common/BreadCrumb";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import { Link, useParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getFacilities as onGetFacilities } from "../../slices/thunk";

const FacilityDetails = () => {
  document.title =
    "Facility Details | Maritime Waste Management Admin & Dashboard Platform";

  const dispatch = useDispatch();

  const selectUser = createSelector(
    (state: any) => state.Login,
    (login: any) => ({ user: login.user })
  );

  const { user } = useSelector(selectUser);

  const selectFacilitiesList = createSelector(
    (state: any) => state.Facilities,
    (facilities: any) => ({ facilitiesList: facilities.facilityList })
  );

  const { facilitiesList } = useSelector(selectFacilitiesList);

  const [facility, setFacility] = useState<any>([]);

  useEffect(() => {
    if (!facilitiesList) dispatch(onGetFacilities(user.type));
  }, [dispatch, user.type, facilitiesList]);

  const { id } = useParams();

  useEffect(() => {
    const facil = facilitiesList.filter((f: any) => f.id === id);
    setFacility(facil[0]);
  }, [id, facilitiesList]);

  console.log(facility);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Facility" title={`Facility Details`} />
          <Row className="justify-content-center">
            <Col xxl={9}>
              <Card id="demo">
                <Card.Body>
                  <Row className="p-4">
                    <Col lg={9}>
                      <h3 className="fw-bold mb-4">{facility.name} </h3>
                      <Row className="g-4">
                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Facility Type
                          </p>
                          <h5 className="fs-16 mb-0">
                            <span id="invoice-no">
                              {facility.company?.companyType || "None"}
                            </span>
                          </h5>
                        </Col>

                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Registration Number
                          </p>
                          <h5 className="fs-16 mb-0">
                            <span id="invoice-date">
                              {facility.registration_number
                                ? facility.registration_number
                                : "NIL"}
                            </span>{" "}
                          </h5>
                        </Col>
                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Compliance Status
                          </p>
                          {facility.status === "active" ? (
                            <span
                              className="badge bg-success-subtle text-success fs-11"
                              id="payment-status"
                            >
                              {facility.status}
                            </span>
                          ) : (
                            <span
                              className="badge bg-danger-subtle text-danger fs-11"
                              id="payment-status"
                            >
                              {facility.status}
                            </span>
                          )}
                        </Col>

                        <Col lg={6} className="col-6">
                          {facility.type === "PORT_OF_OPERATION" && (
                            <React.Fragment>
                              <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                                Parent Facility
                              </p>
                              <h5 className="fs-16 mb-0">
                                <span id="total-amount">My parent</span>
                              </h5>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <div className="mt-sm-0 mt-3">
                        <div className="mb-4">
                          <img
                            src={logoDark}
                            className="card-logo card-logo-dark"
                            alt="logo dark"
                            height="17"
                          />
                          <img
                            src={logoLight}
                            className="card-logo card-logo-light"
                            alt="logo light"
                            height="17"
                          />
                        </div>
                        <h6 className="text-muted text-uppercase fw-semibold">
                          Address
                        </h6>
                        <p className="text-muted mb-1" id="address-details">
                          {facility.address}
                        </p>

                        <h6>
                          <span className="text-muted fw-normal">State: </span>
                          <span id="state">{facility.state}</span>
                        </h6>
                        <p className="text-muted mb-1" id="country">
                          <span>Country: </span> {facility.country}
                        </p>
                        <p className="text-muted mb-1" id="longitude">
                          <span>Longitude: </span>
                          {facility.longitude}
                        </p>
                        <p className="text-muted mb-1" id="latitude">
                          <span>Laititude: </span> {facility.latitude}
                        </p>
                        <h6>
                          <span className="text-muted fw-normal">
                            Website:{" "}
                          </span>{" "}
                          <Link
                            to={facility.website ? facility.website : "#"}
                            className="link-primary"
                            target="_blank"
                            rel="noreferrer"
                            id="website"
                          >
                            {facility.website || "NIL"}
                          </Link>
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  {facility.type === "SHIP" && (
                    <Row className="p-4 border-top border-top-dashed">
                      <Col lg={9}>
                        <Row className="g-3">
                          <div className="col-6">
                            <h6 className="text-muted text-uppercase fw-semibold mb-3">
                              Ship Information
                            </h6>
                            <p className="fw-medium mb-2" id="ship-type">
                              {facility.shipType}
                            </p>

                            <p className="text-muted mb-1">
                              <span>IMO Number: </span>
                              <span id="imo-number">{facility.imoNumber}</span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>MMSI Number: </span>
                              <span id="mmsi-number">
                                {facility.mmsiNumber}
                              </span>
                            </p>
                            <p className="text-muted mb-0">
                              <span>Gross Tonnage: </span>
                              <span id="gross-tonnage">
                                {facility.grossTonnage}
                              </span>{" "}
                            </p>
                          </div>

                          <div className="col-6">
                            <h6 className="text-muted text-uppercase fw-semibold mb-3">
                              Other Ship Information
                            </h6>
                            <p className="fw-medium mb-2" id="flag">
                              {facility.flag}
                            </p>
                            <p className="text-muted mb-1">
                              <span>Length: </span>
                              <span id="length">{facility.length}</span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Beam: </span>
                              <span id="beam">{facility.beam}</span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Year Built: </span>
                              <span id="year-built">{facility.yearBuilt}</span>
                            </p>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col lg={12}>
                      <Card.Body className="card-body p-4">
                        <div className="table-responsive">
                          <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                            <thead>
                              <tr className="table-active">
                                <th scope="col" style={{ width: "50px" }}>
                                  #
                                </th>
                                <th scope="col">Title</th>
                                <th scope="col">Issuing Authority</th>
                                <th scope="col">Issued At</th>
                                <th scope="col">Expires At</th>
                                <th scope="col" className="text-end">
                                  Status
                                </th>
                                <th scope="col" className="text-end">
                                  View
                                </th>
                              </tr>
                            </thead>
                            <tbody id="products-list">
                              <tr>
                                <th scope="row">01</th>
                                <td className="text-start">
                                  <span className="fw-medium">
                                    Certification Title
                                  </span>
                                  <p className="text-muted mb-0">
                                    Some Description of the Certificaton.
                                  </p>
                                </td>
                                <td>NPA</td>
                                <td>2024-12-13</td>

                                <td className="text-end">2024-12-13</td>
                                <td className="">
                                  <p className="text-success bg-success-subtle fs-12 badge text-center mx-auto">
                                    PENDING
                                  </p>
                                </td>
                                <td>
                                  <Link to={"edit"}>
                                    <p className="bg-warning-subtle">
                                      <i className="ri-eye-line align-middle text-warning"></i>
                                    </p>
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">02</th>
                                <td className="text-start">
                                  <span className="fw-medium">
                                    Certification Title
                                  </span>
                                  <p className="text-muted mb-0">
                                    Some Description of the Certificaton.
                                  </p>
                                </td>
                                <td>NPA</td>
                                <td>2024-12-13</td>

                                <td className="text-end">2024-12-13</td>
                                <td className="">
                                  <p className="text-success bg-success-subtle fs-12 badge text-center">
                                    PENDING
                                  </p>
                                </td>
                                <td>
                                  <Link to={"edit"}>
                                    <p className="bg-warning-subtle">
                                      <i className="ri-eye-line align-middle text-warning"></i>
                                    </p>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        {facility.compliance_notes && (
                          <div className="mt-4">
                            <div className="alert alert-info">
                              <p className="mb-0">
                                <span className="fw-semibold">
                                  COMPLIANCE NOTES:{" "}
                                </span>
                                <span id="compliance_notes">
                                  {facility.compliance_notes}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                          <Link to={`edit`} className="btn btn-primary">
                            <i className="ri-edit-line align-bottom me-1"></i>
                            Edit
                          </Link>
                        </div>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FacilityDetails;
