import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";

import {
    getUsers as getUsersApi,
    addUsers as addUsersApi,
    editUsers as editUsersApi,
    deleteUsers as deleteUsersApi,
  } from "../../helpers/fakebackend_helper"

export const getUsers = createAsyncThunk("user/getUsers", async () => {
  try {
    const response = getUsersApi();
      return response;
  } catch (error) {
      return error;
  }
});

export const addUsers = createAsyncThunk("user/addUsers", async (user: any) => {
  try {
      const response = addUsersApi(user);
      toast.success("User Added Successfully", { autoClose: 2000 });
      return response;
  } catch (error) {
      toast.error("User Added Failed", { autoClose: 2000 });
      return error;
  }
});

export const editUsers = createAsyncThunk("user/editUsers", async (user: any) => {
  try {
      const response = editUsersApi(user);
      toast.success("User edited Successfully", { autoClose: 2000 });
      return response;
  } catch (error) {
      toast.error("User edited Failed", { autoClose: 2000 });
      return error;
  }
});

export const deleteUsers = createAsyncThunk("user/deleteUsers", async (id: any) => {
  try {
      const response = deleteUsersApi(id);
      toast.success("User deleted Successfully", { autoClose: 2000 });
      return { id, ...response };
  } catch (error) {
      toast.error("User deleted Failed", { autoClose: 2000 });
      return error;
  }
});
