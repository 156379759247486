import React, { useState, useEffect, Fragment } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";

import logoDark from "../../../assets/images/logo-dark.png";
import logoLight from "../../../assets/images/logo-light.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import Cleave from "cleave.js/react";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import {
  getShips as onGetShips,
  getOilPlatforms as onGetOilPlatforms,
  getWasteManagements as onGetWasteManagements,
  getPortReceptions as onGetPortReceptions,
  getPortOfOperations as onGetPortOfOperations,
  // addClientWasteNotifications as onAddClientWasteNotifications,
  editClientWasteNotifications as onEditClientWasteNotifications,
} from "../../../slices/thunk";
import { CompanyTypeEnum } from "../../../helpers/enums/company.enum";
import { FieldArray } from "formik";
import { FormikProvider } from "formik";
import axios from "axios";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../../helpers/url_helper";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import {
  WasteCollectionStatusEnum,
  WasteType,
  WasteTypeKey,
} from "../../../helpers/enums/visitRequisition.enum";

const EditWasteNotification = () => {
  document.title =
    "New Waste Notification | Maritime Waste Management Admin & Dashboard Platform";

  const [paymentStatus, setPaymentStatus] = useState<any>("Unpaid");
  const [timeFormat, setTimeFormat] = useState("");
  let navigate = useNavigate();
  const params = useParams();

  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (state: any) => state.Ship,
    (state: any) => state.OilPlatform,
    (state: any) => state.Facility,
    (account: any, login: any, ships: any, platform: any, facility: any) => ({
      user: account.user,
      tokens: login.tokens,
      loggedInUser: login.user,
      company: login.user?.company || {},
      shipList: ships.shipList,
      platformList: platform.oilPlatformList,
      portReceptionsList: facility.portReceptionsList,
      portOfOperationsList: facility.portOfOperationsList,
      wasteManagementFacilities: facility.wastesList,
    })
  );

  const {
    portOfOperationsList,
    portReceptionsList,
    wasteManagementFacilities,
    company,
    shipList,
    platformList,
    tokens,
  } = useSelector(selectAccountAndLogin);

  console.log("company", company);

  const [state, setState] = useState<any>({});

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(company?.companyType);
  };

  const addLayoutData = (
    requiredTypes: CompanyTypeEnum[],
    child: any,
    alt = null
  ) => {
    return hasPermission(requiredTypes) ? child : alt;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setState(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          // toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [dispatch, navigate, params.id, tokens]);

  useEffect(() => {
    if (company?.companyType === CompanyTypeEnum.OIL_AND_GAS_PLATFORM) {
      dispatch(onGetOilPlatforms());
      dispatch(onGetWasteManagements(`category='all'`));
    } else {
      dispatch(onGetShips());
      dispatch(onGetPortOfOperations());
      // dispatch(onGetPortReceptions());
    }
  }, [company?.companyType, dispatch]);

  const initialWasteDetails = {
    id: "randomid",
    wasteType: "",
    name: "waste",
    rate: "",
    quantity: 1,
    tonnage: 100,
  };
  const [wasteTypes, setWasteTypes] = useState<{ [key: string]: any }>({});
  const [wasteDetails, setWasteDetails] = useState<{ [key: string]: any }>({});

  const [count, setCount] = useState<number>(0);
  const [rate, setRate] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [dis, setDis] = useState<number>(0);
  const [charge, setCharge] = useState<number>(0);

  const disabledField = (
    isAwaitingCollection: boolean = false,
    isAwaitingInspection: boolean = false
  ) => {
    if (isAwaitingCollection) {
      return (
        state?.wasteCollectionStatus !==
        WasteCollectionStatusEnum.AwaitingCollection
      );
    }
    return (
      state?.wasteCollectionStatus ===
        WasteCollectionStatusEnum.AwaitingAdjustmentConfirmation &&
      addLayoutData(
        [CompanyTypeEnum.SHIPMENT, CompanyTypeEnum.OIL_AND_GAS_PLATFORM],
        true,
        null
      )
    );
  };

  const addItemButton = (arrayHelpers: any) => {
    return disabledField() ||
      disabledAddItemButton() ||
      validation.values.wasteDeclarations.length === disabledAddItemButton(true)
      ? null
      : arrayHelpers.push({
          ...initialWasteDetails,
          id: `${validation.values.wasteDeclarations.length + 1}`,
          wasteType: autoAddLastWasteType(),
          rate: autoAddLastWasteType()
            ? wasteDetails[autoAddLastWasteType()].rate
            : 0,
        });
  };

  const autoAddLastWasteType = () => {
    const items = validation.values.wasteDeclarations.map(
      (ww: any) => ww.wasteType
    );

    const excluded = [...(items.length ? new Set(items) : items)];

    const newWasteTypes: { [key: string]: any } = {};

    Object.keys(wasteTypes).map((wd: any) => {
      if (!excluded.includes(wasteTypes[wd])) {
        newWasteTypes[wd] = wasteTypes[wd];
      }
      return wd;
    });

    return Object.keys(newWasteTypes).length === 1
      ? newWasteTypes[Object.keys(newWasteTypes)[0]]
      : null;
  };

  const disabledAddItemButton = (totalLength: any = false) => {
    let items = validation.values.wasteDeclarations.map(
      (ww: any) => ww.wasteType
    );

    items = items.filter((it: any) => it !== "" && it !== null);

    const excluded = [...(items.length ? new Set(items) : items)];

    const newWasteTypes: { [key: string]: any } = {};

    Object.keys(wasteTypes).map((wd: any) => {
      if (!excluded.includes(wasteTypes[wd])) {
        newWasteTypes[wd] = wasteTypes[wd];
      }
      return wd;
    });

    if (totalLength) {
      return excluded.length + Object.keys(newWasteTypes).length;
    }

    return Object.keys(newWasteTypes).length === 0;
  };

  const getWasteTypeSelections = (vwd: any) => {
    const excluded = validation.values.wasteDeclarations
      .map((ww: any) => ww.wasteType)
      .filter((ww: any) => ww !== vwd.wasteType);

    const newWasteTypes: { [key: string]: any } = {};

    Object.keys(wasteTypes).map((wd: any) => {
      if (!excluded.includes(wasteTypes[wd])) {
        newWasteTypes[wd] = wasteTypes[wd];
      }
      return wd;
    });

    return newWasteTypes;
  };

  const getMeasurement = (wasteDetails: any, key: any) => {
    return wasteDetails[key] ? wasteDetails[key].measurement : "";
  };

  const getSubtotal = (values: any) => {
    if (state?.company?.companyType !== CompanyTypeEnum.SHIPMENT) {
      return values.wasteDeclarations && values.wasteDeclarations[0]
        ? values.wasteDeclarations.reduce(
            (total: number, prev: any) => total + prev.rate * prev.quantity,
            0
          )
        : 0;
    } else {
      const shipValue = shipList.find((ship: any) => ship.id === values.shipId);
      return shipValue ? shipValue?.grossTonnage * 2400 : 0;
    }
  };

  const getTaxAmount = (values: any) => {
    const taxValue = 0.075;
    return taxValue * getSubtotal(values);
  };

  const getDiscount = (values: any) => {
    const discountValue = 0;
    return discountValue * getSubtotal(values);
  };

  const getCharges = (values: any) => {
    return values.wasteDeclarations && values.wasteDeclarations[0] ? 65 : 0;
  };

  useEffect(() => {
    let tax = 0.075 * rate * count;
    let dis = 0 * rate * count;

    if (rate && count) {
      setCharge(65);
    } else {
      setCharge(0);
    }
    setTax(tax);
    setDis(dis);
  }, [rate, count]);

  //Time Format
  function onTimeFormatChange(e: any) {
    setTimeFormat(e.target.rawValue);
  }

  const dateformate = () => {
    // const date = e.toString().split(" ");
    // const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    // setDate(joinDate);
  };

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      postalcode: "",
      companyAddress: "16 Amodu Ojikutu Street, Victoria Island, Lagos",
      email: "",
      wasteDeclarations: state?.wasteDeclaration || [
        {
          id: "randomid",
          wasteType: "",
          name: "waste",
          rate: "",
          tonnage: 100,
          quantity: 1,
        },
      ],
      website: "https://mwp.ng",
      // contact: "",
      // invoiceId: "",
      date: "",
      name: "",
      pooId: state?.portOfOperation?.id || "",
      prfId: state?.portReceptionFacility?.id || "",
      wmpId: state?.wasteManagementProcessingFacility?.id || "",
      shipId: state?.ship?.id || "",
      platformId: state?.oilPlatform?.id || "",
      // status: "",
      // country: "",
      // amount: "",
      billing_address: "",
      billing_phone: "",
      billing_taxno: "",
      shipping_name: "",
      shipping_address: "",
      shipping_phone: "",
      shipping_taxno: "",
      product_name: "",
      estimatedArrival: state.estimatedArrival,
      estimatedArrivalTime: "1232",
      ...state,
    },
    validationSchema: Yup.object({
      ...(state?.company?.companyType ===
        CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
      company?.companyType === CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ||
      company?.companyType ===
        CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY
        ? {
            platformId: Yup.string().required("Please Enter a Platform"),
            wmpId: Yup.string().required("Please Enter a Waste facility"),
          }
        : {
            pooId: Yup.string().required("Please Enter a Port of Operation"),
            shipId: Yup.string().required("Please Enter a Ship"),
            prfId: Yup.string().required("Please Enter a Waste facility"),
          }),
      // // country: Yup.string().required("Please Enter a Country"),
      // billing_address: Yup.string().required("Please Enter a Address"),
      // billing_phone: Yup.string().required("Please Enter a Phone Number"),
      // billing_taxno: Yup.string().required("Please Enter a tax Number"),
      // shipping_name: Yup.string().required("Please Enter a Full name"),
      // shipping_address: Yup.string().required("Please Enter a Address"),
      // shipping_phone: Yup.string().required("Please Enter a Phone Number"),
      // shipping_taxno: Yup.string().required("Please enter a tax Number"),
      // product_name: Yup.string().required("Please Enter a product Name"),
    }),
    onSubmit: (values: any) => {
      console.log("onSubmit values", values);
      const {
        name,
        pooId,
        prfId,
        wmpId,
        shipId,
        platformId,
        wasteDetails,
        id,
      } = values;
      const { wasteDeclarations, estimatedArrivalTime, estimatedArrival } =
        values;
      console.log("estimatedArrival[0]", estimatedArrival);
      dispatch(
        onEditClientWasteNotifications({
          id,
          name: name || `Waste notification on ${new Date()}`,
          wasteDetails:
            name || wasteDetails || `Waste notification on ${new Date()}`,
          pooId,
          prfId,
          wmpId,
          shipId,
          platformId,
          wasteDeclarations,
          estimatedArrival: Array.isArray(estimatedArrival)
            ? estimatedArrival[0]
            : state.estimatedArrival,
          estimatedArrivalTime,
          bill: {
            ...state?.bill,
            billNumber: state?.bill?.billNumber || "default-0",
            totalTransactionValue: getSubtotal(values),
            adjustmentAmount:
              getSubtotal(values) - state?.bill?.totalTransactionValue,
            totalTax: getTaxAmount(values),
            amountDue: getSubtotal(values),
            discount: getDiscount(values),
          },
        })
      );
      // validation.resetForm();
    },
  });

  useEffect(() => {
    if (state?.company?.companyType === CompanyTypeEnum.OIL_AND_GAS_PLATFORM) {
    } else {
      const pooId = `pooId=${validation.values.pooId}`;
      dispatch(onGetPortReceptions(pooId));
    }
  }, [state?.company?.companyType, dispatch, validation.values.pooId]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/waste-type`
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        const responseBody = JSON.parse(JSON.stringify(response));
        const newResponse: { [key: string]: any } = {};
        const newResponseBody: { [key: string]: any } = {};

        if (responseBody) {
          Object.keys(responseBody).filter((res) => {
            const eachWasteType = responseBody[res as WasteTypeKey];
            if (
              eachWasteType &&
              eachWasteType.category.includes(company?.companyType)
            ) {
              const name = responseBody[res].name;
              newResponse[res] = name;
              newResponseBody[name] = responseBody[res];
            }
            return res;
          });
        }
        setWasteTypes(JSON.parse(JSON.stringify(newResponse)));
        setWasteDetails(JSON.parse(JSON.stringify(newResponseBody)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [navigate]);

  console.log("validation.errors", validation.errors);
  console.log("validation.values", validation.values);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            pageTitle="Waste Notification"
            title="Edit Waste Notification"
          />
          <Row className="justify-content-center">
            <Col xxl={9}>
              <Card>
                <Form
                  className="needs-validation"
                  id="invoice_form"
                  onSubmit={validation.handleSubmit}
                >
                  <Card.Body className="border-bottom border-bottom-dashed p-4">
                    <Row>
                      <Col lg={6}>
                        <Row className="g-3">
                          <Col lg={8} sm={6}>
                            <label htmlFor="invoicenoInput">
                              Waste Notification Ref No
                            </label>
                            <input
                              type="text"
                              className="form-control bg-light border-0"
                              id="invoicenoInput"
                              placeholder="WasteNotification No"
                              value="#VL25000355"
                              readOnly
                            />
                          </Col>
                          {state?.company?.companyType ===
                          CompanyTypeEnum.SHIPMENT ? (
                            <Fragment>
                              <Col lg={8} sm={6}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="shipId"
                                    className="form-label"
                                  >
                                    Ship
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="shipId"
                                    placeholder="Enter Ship"
                                    value={validation.values.shipId || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={!!validation.errors.shipId}
                                    disabled={disabledField(true)}
                                  >
                                    {/* <option disabled>Select Ship</option> */}
                                    <option value="">Select Ship</option>
                                    {shipList &&
                                      shipList.map((el: any) => (
                                        <option key={el.id} value={el.id}>
                                          {el.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {validation.errors.shipId &&
                                  validation.touched.shipId ? (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {validation.errors.shipId}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg={8} sm={6}>
                                <div className="mb-3">
                                  <label htmlFor="pooId" className="form-label">
                                    Port
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="pooId"
                                    placeholder="Enter Ship"
                                    value={validation.values.pooId || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={!!validation.errors.pooId}
                                    disabled={disabledField(true)}
                                  >
                                    {/* <option disabled>Select Ship</option> */}
                                    <option value="">Select Port</option>
                                    {portOfOperationsList &&
                                      portOfOperationsList.map((el: any) => (
                                        <option key={el.id} value={el.id}>
                                          {el.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {validation.errors.pooId &&
                                  validation.touched.pooId ? (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {validation.errors.pooId}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                              {validation.values.pooId !== "" ? (
                                <Col lg={8} sm={6}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="prfId"
                                      className="form-label"
                                    >
                                      Port Reception
                                    </label>
                                    <Form.Select
                                      id="paymentType"
                                      name="prfId"
                                      placeholder="Enter Port Reception Facility"
                                      value={validation.values.prfId || ""}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      isInvalid={!!validation.errors.prfId}
                                      disabled={disabledField(true)}
                                    >
                                      {/* <option disabled>Select Port Reception Facility</option> */}
                                      <option value="">
                                        Select Port Reception Facility
                                      </option>
                                      {portReceptionsList &&
                                        portReceptionsList.map((el: any) => (
                                          <option key={el.id} value={el.id}>
                                            {el.name}
                                          </option>
                                        ))}
                                    </Form.Select>
                                    {validation.errors.prfId &&
                                    validation.touched.prfId ? (
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        {validation.errors.prfId}
                                      </Form.Control.Feedback>
                                    ) : null}
                                  </div>
                                </Col>
                              ) : null}
                            </Fragment>
                          ) : null}
                          {state?.company?.companyType ===
                          CompanyTypeEnum.OIL_AND_GAS_PLATFORM ? (
                            <Fragment>
                              <Col lg={8} sm={6}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="platformId"
                                    className="form-label"
                                  >
                                    Platform
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="platformId"
                                    placeholder="Enter Platform"
                                    value={validation.values.platformId || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={!!validation.errors.platformId}
                                    disabled={disabledField(true)}
                                  >
                                    {/* <option disabled>Select Platform</option> */}
                                    <option value="">Select Platform</option>
                                    {platformList &&
                                      platformList.map((el: any) => (
                                        <option key={el.id} value={el.id}>
                                          {el.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {validation.errors.platformId &&
                                  validation.touched.platformId ? (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {validation.errors.platformId}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg={8} sm={6}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="platformId"
                                    className="form-label"
                                  >
                                    Waste Management Processing facility
                                  </label>
                                  <Form.Select
                                    id="paymentType"
                                    name="wmpId"
                                    placeholder="Enter Platform"
                                    value={validation.values.wmpId || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    isInvalid={!!validation.errors.wmpId}
                                    disabled={disabledField(true)}
                                  >
                                    {/* <option disabled>Select Waste Facility</option> */}
                                    <option value="">
                                      Select Waste Facility
                                    </option>
                                    {wasteManagementFacilities &&
                                      wasteManagementFacilities.map(
                                        (el: any) => (
                                          <option key={el.id} value={el.id}>
                                            {el.name}
                                          </option>
                                        )
                                      )}
                                  </Form.Select>
                                  {validation.errors.wmpId &&
                                  validation.touched.wmpId ? (
                                    <Form.Control.Feedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {validation.errors.wmpId}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Fragment>
                          ) : null}

                          <Col lg={8} sm={6}>
                            <div>
                              <label htmlFor="date-field">
                                Proposed Collection Date
                              </label>
                              <Flatpickr
                                name="estimatedArrival"
                                id="date-field"
                                className="form-control bg-light border-0"
                                placeholder="Proposed Collection Date"
                                disabled={disabledField(true)}
                                //   addLayoutData(
                                //   [
                                //     CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                                //     CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                                //   ],
                                //   true
                                // )}
                                options={{
                                  altInput: true,
                                  altFormat: "d M, Y",
                                  dateFormat: "d M, Y",
                                }}
                                // onChange={dateformate}
                                onChange={(e: any) =>
                                  validation.handleChange({
                                    target: {
                                      value: e,
                                      name: "estimatedArrival",
                                    },
                                  })
                                }
                                value={validation.values.estimatedArrival || ""}
                              />
                              {validation.touched.estimatedArrival &&
                              validation.errors.estimatedArrival ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.estimatedArrival}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={8} sm={6}>
                            <label htmlFor="choices-payment-status">
                              Payment Status
                            </label>
                            <div className="input-light">
                              <input
                                className="form-control bg-light border-0"
                                type="text"
                                id="paymentStatus"
                                placeholder="Payment Status"
                                value={state.paymentStatus}
                                readOnly
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={4} className="ms-auto">
                        <div className="profile-user mx-auto  mb-3">
                          <input
                            id="profile-img-file-input"
                            type="file"
                            className="profile-img-file-input"
                          />
                          <label
                            htmlFor="profile-img-file-input"
                            className="d-block"
                            tabIndex={0}
                          >
                            <span
                              className="overflow-hidden border border-dashed d-flex align-items-center justify-content-center rounded"
                              style={{ height: "60px", width: "256px" }}
                            >
                              <img
                                src={logoDark}
                                className="card-logo card-logo-dark user-profile-image img-fluid"
                                alt="logo dark"
                              />
                              <img
                                src={logoLight}
                                className="card-logo card-logo-light user-profile-image img-fluid"
                                alt="logo light"
                              />
                            </span>
                          </label>
                        </div>

                        <div>
                          <label htmlFor="companyAddress">Address</label>
                        </div>
                        <div className="mb-2">
                          <Form.Control
                            id="companyAddress"
                            as="textarea"
                            // type="textarea"
                            className="bg-light border-0"
                            name="companyAddress"
                            value={validation.values.companyAddress || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="CompanyAddress"
                            readOnly={disabledField(true)}
                            //   addLayoutData(
                            //   [
                            //     CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                            //     CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                            //   ],
                            //   true
                            // )}
                            isInvalid={
                              validation.errors.companyAddress &&
                              validation.touched.companyAddress
                                ? true
                                : false
                            }
                          />
                          {validation.errors.companyAddress &&
                          validation.touched.companyAddress ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.companyAddress}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                        <div className="mb-2">
                          <Form.Control
                            type="text"
                            className="bg-light border-0"
                            id="companyWebsite"
                            name="website"
                            readOnly={disabledField(true)}
                            value={validation.values.website || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Website"
                            isInvalid={
                              validation.errors.website &&
                              validation.touched.website
                                ? true
                                : false
                            }
                          />
                          {validation.errors.website &&
                          validation.touched.website ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.website}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                        <div className="mb-2">
                          <Form.Control
                            type="text"
                            data-plugin="cleave-phone"
                            className="bg-light border-0"
                            id="compnayContactno"
                            name="contact"
                            value={validation.values.contact || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Contact No"
                            readOnly={disabledField(true)}
                            isInvalid={
                              validation.errors.contact &&
                              validation.touched.contact
                                ? true
                                : false
                            }
                          />
                          {validation.errors.contact &&
                          validation.touched.contact ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.contact}
                            </Form.Control.Feedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>

                  <Card.Body className="p-4">
                    <div className="table-responsive">
                      <table className="invoice-table table table-borderless table-nowrap mb-0">
                        <thead className="align-middle">
                          <tr className="table-active">
                            <th scope="col" style={{ width: "50px" }}>
                              #
                            </th>
                            <th scope="col">Waste Details</th>
                            <th scope="col" style={{ width: "130px" }}>
                              {state?.company?.companyType !==
                              CompanyTypeEnum.SHIPMENT ? (
                                <div className="d-flex currency-select input-light align-items-center">
                                  Rate / Unit
                                </div>
                              ) : (
                                <div className="d-flex currency-select input-light align-items-center">
                                  Unit
                                </div>
                              )}
                            </th>
                            <th scope="col" style={{ width: "120px" }}>
                              Quantity
                            </th>

                            <th
                              scope="col"
                              className="text-end"
                              style={{ width: "150px" }}
                            >
                              {state?.company?.companyType !==
                              CompanyTypeEnum.SHIPMENT
                                ? "Amount"
                                : null}
                            </th>
                            <th
                              scope="col"
                              className="text-end"
                              style={{ width: "105px" }}
                            ></th>
                          </tr>
                        </thead>
                        <FormikProvider value={validation}>
                          <FieldArray
                            name="wasteDeclarations"
                            render={(arrayHelpers: any) => (
                              <Fragment>
                                <tbody id="newlink">
                                  {console.log(
                                    "validation.values.wasteDeclarations",
                                    validation.values.wasteDeclarations
                                  )}
                                  {validation.values.wasteDeclarations.map(
                                    (wd: any, index: number) => (
                                      <Fragment>
                                        {validation.values.wasteDeclarations &&
                                        validation.values.wasteDeclarations
                                          .length > 0 ? (
                                          <tr id={wd.id} className="product">
                                            <th
                                              scope="row"
                                              className="product-id"
                                            >
                                              {index + 1}
                                            </th>
                                            <td className="text-start">
                                              <div className="mb-2">
                                                <Form.Select
                                                  // type="text"
                                                  id="productName-1"
                                                  className="bg-light border-0"
                                                  placeholder="Waste Name"
                                                  name={`wasteDeclarations.${index}.wasteType`}
                                                  // value={validation.values[index].wasteType || ""}
                                                  value={wd.wasteType || ""}
                                                  // value={validation.values.product_name || ""}
                                                  onBlur={validation.handleBlur}
                                                  onChange={({
                                                    target: { name, value },
                                                  }) => {
                                                    validation.handleChange({
                                                      target: { name, value },
                                                    });
                                                    validation.handleChange({
                                                      target: {
                                                        name: `wasteDeclarations.${index}.rate`,
                                                        value:
                                                          wasteDetails[value]
                                                            .rate,
                                                      },
                                                    });
                                                  }}
                                                  disabled={disabledField()}
                                                >
                                                  <option defaultValue="">
                                                    Select Waste Type
                                                  </option>
                                                  {Object.keys(
                                                    getWasteTypeSelections(wd)
                                                  )?.map((vwd) => (
                                                    <option
                                                      value={
                                                        getWasteTypeSelections(
                                                          wd
                                                        )[vwd]
                                                      }
                                                    >
                                                      {
                                                        getWasteTypeSelections(
                                                          wd
                                                        )[vwd]
                                                      }
                                                    </option>
                                                  ))}
                                                </Form.Select>
                                                {validation.errors
                                                  .wasteDeclarations &&
                                                validation.errors
                                                  .wasteDeclarations[index] &&
                                                validation.touched
                                                  .wasteDeclarations[index]
                                                  .wasteType ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .wasteDeclarations[
                                                        index
                                                      ].wasteType
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              {/* <textarea
                                                className="form-control bg-light border-0"
                                                id="productDetails-1"
                                                rows={2}
                                                placeholder="Waste Details"
                                              ></textarea> */}
                                            </td>
                                            <td>
                                              <Fragment>
                                                <Form.Control
                                                  // type="number"
                                                  type="text"
                                                  // name={`wasteDeclarations.${index}.rate`}
                                                  className="product-price bg-light border-0"
                                                  placeholder="0.00"
                                                  id="productRate-1"
                                                  step="0.01"
                                                  // value={wd.rate || ""}
                                                  value={
                                                    company?.companyType !==
                                                    CompanyTypeEnum.SHIPMENT
                                                      ? wd.rate
                                                        ? wd.rate +
                                                          " / " +
                                                          getMeasurement(
                                                            wasteDetails,
                                                            wd["wasteType"]
                                                          )
                                                        : ""
                                                      : wd.rate
                                                      ? getMeasurement(
                                                          wasteDetails,
                                                          wd["wasteType"]
                                                        )
                                                      : ""
                                                  }
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  readOnly
                                                />
                                                {validation.errors
                                                  .wasteDeclarations &&
                                                validation.errors
                                                  .wasteDeclarations[index] &&
                                                validation.touched
                                                  .wasteDeclarations[index]
                                                  .rate ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .wasteDeclarations[
                                                        index
                                                      ].rate
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </Fragment>
                                            </td>
                                            <td>
                                              <div className="input-step">
                                                <Button
                                                  type="button"
                                                  className="minus-btn"
                                                  onClick={() =>
                                                    arrayHelpers.replace(
                                                      index,
                                                      {
                                                        ...wd,
                                                        quantity:
                                                          wd.quantity > 0
                                                            ? +wd.quantity - 1
                                                            : wd.quantity,
                                                      }
                                                    )
                                                  }
                                                  disabled={disabledField()}
                                                >
                                                  –
                                                </Button>
                                                <Form.Control
                                                  type="number"
                                                  name={`wasteDeclarations.${index}.quantity`}
                                                  className="product-quantity"
                                                  id="product-qty-1"
                                                  value={wd.quantity}
                                                  step="0.01"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  readOnly={disabledField()}
                                                />
                                                <Button
                                                  type="button"
                                                  className="plus-btn"
                                                  onClick={
                                                    () =>
                                                      arrayHelpers.replace(
                                                        index,
                                                        {
                                                          ...wd,
                                                          quantity:
                                                            +wd.quantity + 1,
                                                        }
                                                      )
                                                    // setCount(count + 1)
                                                  }
                                                  disabled={disabledField()}
                                                >
                                                  +
                                                </Button>
                                              </div>
                                            </td>
                                            <td className="text-end">
                                              {state?.company?.companyType !==
                                              CompanyTypeEnum.SHIPMENT ? (
                                                <div>
                                                  <Form.Control
                                                    type="text"
                                                    className="product-line-price"
                                                    id="productPrice-1"
                                                    placeholder="₦0.00"
                                                    value={
                                                      "₦" +
                                                      wd.rate * wd.quantity
                                                    }
                                                    readOnly
                                                  />
                                                </div>
                                              ) : null}
                                            </td>
                                            <td className="product-removal">
                                              <Link
                                                onClick={() =>
                                                  disabledField()
                                                    ? null
                                                    : arrayHelpers.remove(index)
                                                }
                                                to="#"
                                                className="btn btn-success"
                                              >
                                                Delete
                                              </Link>
                                            </td>
                                          </tr>
                                        ) : null}
                                      </Fragment>
                                    )
                                  )}
                                </tbody>
                                <tbody>
                                  <tr id="newForm" style={{ display: "none" }}>
                                    <td className="d-none" colSpan={5}>
                                      <p>Add New Form</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={5}>
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          addItemButton(arrayHelpers)
                                        }
                                        id="add-item"
                                        className="btn btn-soft-secondary fw-medium"
                                      >
                                        <i className="ri-add-fill me-1 align-bottom"></i>{" "}
                                        Add Item
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr className="border-top border-top-dashed mt-2">
                                    <td
                                      colSpan={
                                        state?.company?.companyType !==
                                        CompanyTypeEnum.SHIPMENT
                                          ? 3
                                          : 2
                                      }
                                    ></td>
                                    <td
                                      colSpan={
                                        state?.company?.companyType !==
                                        CompanyTypeEnum.SHIPMENT
                                          ? 2
                                          : 3
                                      }
                                      className="p-0"
                                    >
                                      <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                                        <tbody>
                                          <tr>
                                            <th scope="row">
                                              {state?.company?.companyType ===
                                              CompanyTypeEnum.SHIPMENT
                                                ? "Environmental Protection Levy (EPL)"
                                                : "Sub Total"}
                                            </th>
                                            <td style={{ width: "150px" }}>
                                              <Form.Control
                                                type="text"
                                                id="cart-subtotal"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  getSubtotal(validation.values)
                                                  // rate * count
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">
                                              Estimated Tax (7.5%)
                                            </th>
                                            <td>
                                              <Form.Control
                                                type="text"
                                                id="cart-tax"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  getTaxAmount(
                                                    validation.values
                                                  )
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">
                                              Discount{" "}
                                              <small className="text-muted">
                                                (Mwp.ng24)
                                              </small>
                                            </th>
                                            <td>
                                              <Form.Control
                                                type="text"
                                                id="cart-discount"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  getDiscount(validation.values)
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">Shipping Charge</th>
                                            <td>
                                              <Form.Control
                                                type="text"
                                                id="cart-shipping"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  getCharges(validation.values)
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr className="border-top border-top-dashed">
                                            <th scope="row">Total Amount</th>
                                            <td>
                                              <Form.Control
                                                type="text"
                                                id="cart-total"
                                                placeholder="₦0.00"
                                                readOnly
                                                value={
                                                  "₦" +
                                                  Math.round(
                                                    getSubtotal(
                                                      validation.values
                                                    ) +
                                                      getTaxAmount(
                                                        validation.values
                                                      ) +
                                                      getCharges(
                                                        validation.values
                                                      ) -
                                                      getDiscount(
                                                        validation.values
                                                      )
                                                  )
                                                }
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </td>
                                  </tr>
                                </tbody>
                              </Fragment>
                            )}
                          />
                        </FormikProvider>
                      </table>
                    </div>
                    {addLayoutData(
                      [
                        CompanyTypeEnum.SHIPMENT,
                        CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                      ],
                      <div className="mt-4">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label text-muted text-uppercase fw-semibold"
                        >
                          NOTES
                        </label>
                        <Alert
                          className="form-control alert-info"
                          id="exampleFormControlTextarea1"
                          placeholder="Notes"
                        >
                          All bills are to be paid within 7 days from receipt of
                          invoice. To be paid by cheque or credit card or direct
                          payment online. If account is not paid within 7 days
                          the credits details supplied as confirmation of work
                          undertaken will be charged the agreed quoted fee noted
                          above.
                        </Alert>
                      </div>
                    )}
                    {/* <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                      <button type="submit" className="btn btn-info">
                        <i className="ri-printer-line align-bottom me-1"></i>{" "}
                        Save
                      </button>
                      <Link to="#" className="btn btn-primary">
                        <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                        Download Waste Notification
                      </Link>
                      <Link to="#" className="btn btn-danger">
                        <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                         Submit Waste notification
                      </Link>
                    </div> */}
                  </Card.Body>

                  <Card.Body className="border-bottom border-bottom-dashed p-4">
                    <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                      {/* <button type="submit" className="btn btn-info">
                        <i className="ri-printer-line align-bottom me-1"></i>{" "}
                        Save
                      </button> */}
                      {/* <Link to="#" className="btn btn-primary">
                        <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                        Download Waste Notification
                      </Link> */}
                      {(state?.wasteCollectionStatus ===
                        WasteCollectionStatusEnum.CollectionScheduled ||
                        state?.wasteCollectionStatus ===
                          WasteCollectionStatusEnum.AwaitingAdjustmentConfirmation) &&
                        addLayoutData(
                          [
                            CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                            CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                          ],
                          <button type="submit" className="btn btn-primary">
                            <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                            Request Adjustment Approval
                          </button>
                        )}

                      {state?.wasteCollectionStatus ===
                        WasteCollectionStatusEnum.AwaitingAdjustmentConfirmation &&
                        addLayoutData(
                          [
                            CompanyTypeEnum.SHIPMENT,
                            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                          ],
                          <button type="submit" className="btn btn-primary">
                            <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                            Approve Adjustment
                          </button>
                        )}

                      {state?.wasteCollectionStatus ===
                        WasteCollectionStatusEnum.AwaitingCollection &&
                        addLayoutData(
                          [
                            CompanyTypeEnum.SHIPMENT,
                            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                          ],
                          <button type="submit" className="btn btn-primary">
                            <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                            Edit Waste notification
                          </button>
                        )}
                    </div>
                  </Card.Body>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default EditWasteNotification;
