import { createSlice } from "@reduxjs/toolkit";
import {
    getLicenseApplications,
    addLicenseApplication,
    approveLicenseApplication,
    rejectLicenseApplication,
    getLicenses
    
} from "./thunk";


export const initialState = {
    liscenseApplicationList:[],
    error: {},
    licenseList:[]
}


const LicenseSlice:any = createSlice({
    name:"License",
    initialState,
    reducers:{},

    extraReducers: (builder:any) => {
        builder.addCase(getLicenseApplications.fulfilled, (state:any, action:any) => {
            state.liscenseApplicationList = action.payload;
        });
        builder.addCase(getLicenseApplications.rejected, (state:any, action:any) => {
            state.error = action.payload?.error || null;
        });

        //add
        builder.addCase(addLicenseApplication.fulfilled, (state: any, action: any) => {
            if(action.payload?.data){
              state.liscenseApplicationList = action.payload.data;
              state.error = null;
            }
          });
        builder.addCase(addLicenseApplication.rejected, (state: any, action: any) => {
            state.error = action.payload?.error || null;
          });

          builder.addCase(approveLicenseApplication.fulfilled, (state:any, action:any) => {
            const updatedLicenseList = state.liscenseApplicationList.map((app:any) =>
              app.id === action.payload.id ? action.payload : app
            );
            state.licenseApplicationList = updatedLicenseList;
          });
          builder.addCase(approveLicenseApplication.rejected, (state:any, action:any) => {
            state.error = action.payload || null;
          });
      
          builder.addCase(rejectLicenseApplication.fulfilled, (state:any, action:any) => {
            const updatedLicenseList = state.liscenseApplicationList.map((app:any) =>
              app.id === action.payload.id ? action.payload : app
            );
            state.licenseApplicationList = updatedLicenseList;
          });
          builder.addCase(rejectLicenseApplication.rejected, (state:any, action:any) => {
            state.error = action.payload || null;
          });

          builder.addCase(getLicenses.fulfilled, (state: any, action: any) => {
            state.licenseList = action.payload;
          });
          builder.addCase(getLicenses.rejected, (state: any, action: any) => {
            state.error = action.payload?.error || null;
          });
        }
      
    
})


export default LicenseSlice.reducer;