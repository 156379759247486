import { createSlice } from "@reduxjs/toolkit";

import { getOilPlatforms, addOilPlatform, editOilPlatform, deleteOilPlatform,
  getRigs, addRig, editRig, deleteRig } from "./thunk";

export const initialState = {
  oilPlatformList: [],
  rigList: [],
  error: null,
};

const OilPlatformSlice: any = createSlice({
  name: "OilPlatform",
  initialState,
  reducers: {},

  extraReducers: (builder: any) => {
    builder.addCase(getOilPlatforms.fulfilled, (state: any, action: any) => {
      console.log('action', action)
      state.oilPlatformList = action.payload;
    });

    builder.addCase(getOilPlatforms.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || action.payload || null;
    });

    // add
    builder.addCase(addOilPlatform.fulfilled, (state: any, action: any) => {
      state.oilPlatformList.push(action.payload?.data);
      state.error = null
    });
    builder.addCase(addOilPlatform.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || action.payload || null;
    });

    builder.addCase(editOilPlatform.fulfilled, (state: any, action: any) => {
      console.log('editOilPlatform action.payload', action.payload)
      state.oilPlatformList = state.oilPlatformList.map((platform: any) =>
        platform.id === action.payload?.data?.id
          ? { ...platform, ...action.payload?.data }
          : platform
      );
    });

    builder.addCase(editOilPlatform.rejected, (state: any, action: any) => {
      console.log('rejected action.payload',action.payload)
      state.error = action.payload?.error || action.payload || null;
    });

    builder.addCase(deleteOilPlatform.fulfilled, (state: any, action: any) => {
      state.oilPlatformList = (state.oilPlatformList || []).filter(
        (ship: any) => ship.id !== action.payload.id
      );
    });
    builder.addCase(deleteOilPlatform.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || action.payload || null;
    });

    // Add Rigs
    builder.addCase(getRigs.fulfilled, (state: any, action: any) => {
      state.rigList = action.payload;
    });

    builder.addCase(getRigs.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || action.payload || null;
    });

    // add
    builder.addCase(addRig.fulfilled, (state: any, action: any) => {
      state.rigList.push(action.payload);
    });
    builder.addCase(addRig.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || action.payload || null;
    });

    builder.addCase(editRig.fulfilled, (state: any, action: any) => {
      state.rigList = state.rigList.map((ship: any) =>
        ship.id === action.payload.id
          ? { ...ship, ...action.payload }
          : ship
      );
    });

    builder.addCase(editRig.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || action.payload || null;
    });

    builder.addCase(deleteRig.fulfilled, (state: any, action: any) => {
      state.rigList = (state.rigList || []).filter(
        (ship: any) => ship.id !== action.payload.id
      );
    });
    builder.addCase(deleteRig.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || action.payload || null;
    });
  },
});

export default OilPlatformSlice.reducer;
