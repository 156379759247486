import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import {
  getPortReceptions as onGetPortReceptions,
  deletePortReceptions as onDeletePortReceptions,
} from "../../../slices/thunk";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { DeleteModal } from "../../../Common/DeleteModal";
import { handleSearchData } from "../../../Common/Tabledata/SorttingData";
import EditPortReceptions from "../../../Common/CrudModal/EditPortReceptions";
import AddPortReceptions from "../../../Common/CrudModal/AddPortReceptions";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import { hasPermission } from "../../../helpers/utils";
import { UserTypeEnum } from "../../../helpers/enums/user.enum";

interface portReceptionProps {
  isShow: any;
  hidePortReceptionModal: any;
}

const PortReceptionTable = ({
  isShow,
  hidePortReceptionModal,
}: portReceptionProps) => {
  const dispatch = useDispatch();

  const selectPortReceptionsList = createSelector(
    (state: any) => state.Facility,
    (portReceptions: any) => ({
      portReceptionsList: portReceptions.portReceptionsList,
    })
  );

  const selectAccount = createSelector(
    (state: any) => state.Login,
    (user: any) => ({ user: user.user })
  );

  const { portReceptionsList } = useSelector(selectPortReceptionsList);
  const { user } = useSelector(selectAccount);

  const [portReceptions, setPortReceptions] = useState<any>([]);

  useEffect(() => {
    dispatch(onGetPortReceptions(null));
  }, [dispatch]);

  useEffect(() => {
    setPortReceptions(portReceptionsList);
  }, [portReceptionsList]);

  // Delete modal

  const [delet, setDelet] = useState<boolean>(false);
  const [deletid, setDeletid] = useState<any>();

  const handleDeleteModal = useCallback(
    (id: any) => {
      setDelet(!delet);
      setDeletid(id);
    },
    [delet]
  );

  const handleDeleteId = () => {
    dispatch(onDeletePortReceptions(deletid.id));
    setDelet(false);
  };

  // search
  const handleSearch = (ele: any) => {
    let item = ele.value;

    if (item === "All Tasks") {
      setPortReceptions([...portReceptionsList]);
    } else {
      handleSearchData({
        data: portReceptionsList,
        item: item,
        setState: setPortReceptions,
      });
    }
  };

  const [editPortReception, setEditPortReception] = useState<boolean>(false);
  const [edit, setEdit] = useState<any>();

  const handleCloseEdit = () => setEditPortReception(false);
  const handleEditPortReception = (item: any) => {
    setEditPortReception(true);
    setEdit({
      id: item.id,
      name: item.name,
      logo: item.logo,
      phone: item.phone,
      longitude: item.longitude,
      latitude: item.latitude,
      address: item.address,
      state: item.state,
      country: item.country,
      status: item.status,
    });
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ADDRESS",
        accessor: "address",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "STATE",
        accessor: "state",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "COUNTRY",
        accessor: "country",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "REGISTERED ON",
        accessor: "createDateTime",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        Filter: false,
        isSortable: true,
      },
      // {
      //   Header: "REGISTERED ON",
      //   accessor: "registeredOn",
      //   Filter: false,
      //   isSortable: true,
      // },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case "Active":
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
            case "Disabled":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },
      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        style: { width: "12%" },

        isSortable: false,
        Cell: (cell: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            <li
              className="list-inline-item"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-placement="top"
              aria-label="View"
              data-bs-original-title="View"
            >
              <Link
                to={{
                  pathname: `/facility-management/port-receptions/${cell.row.original.id}`,
                }}
                state={{ ...cell.row.original }}
                className="btn btn-soft-info btn-sm d-inline-block"
              >
                <i className="las la-eye fs-17 align-middle"></i>
              </Link>
            </li>
            <li
              className="list-inline-item edit"
              onClick={() => {
                const item = cell.row.original;
                handleEditPortReception(item);
              }}
            >
              <Link to="#" className="btn btn-soft-info btn-sm d-inline-block">
                <i className="las la-pen fs-17 align-middle"></i>
              </Link>
            </li>
            {hasPermission(
              [UserTypeEnum.NPA, UserTypeEnum.TechnicalAdmin],
              user.company.companyType
            ) && (
              <li
                className="list-inline-item"
                onClick={() => {
                  const item = cell.row.original;
                  handleDeleteModal(item);
                }}
              >
                <Link
                  to="#"
                  className="btn btn-soft-danger btn-sm d-inline-block"
                >
                  <i className="las la-file-download fs-17 align-middle"></i>
                </Link>
              </li>
            )}
          </ul>
        ),
      },
    ],
    [handleDeleteModal, user.company.companyType]
  );

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <Col sm={4}>
          <button
            className="btn btn-primary addPayment-modal"
            onClick={hidePortReceptionModal}
          >
            <i className="las la-plus me-1"></i> Add New
          </button>
        </Col>

        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                id="searchMemberList"
                placeholder="Search for Result"
                onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                variant="info"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>All</Dropdown.Item>
                <Dropdown.Item>Last Week</Dropdown.Item>
                <Dropdown.Item>Last Month</Dropdown.Item>
                <Dropdown.Item>Last Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {portReceptions && portReceptions.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={portReceptions || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  PaginationClass="align-items-center mt-4 gy-3"
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <AddPortReceptions
        isShow={isShow}
        handleClose={hidePortReceptionModal}
        handleShow={isShow}
      />

      <EditPortReceptions
        isShow={editPortReception}
        handleClose={handleCloseEdit}
        edit={edit}
      />

      <DeleteModal
        show={delet}
        handleClose={handleDeleteModal}
        deleteModalFunction={handleDeleteId}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default PortReceptionTable;
