import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { addWasteCollections as onMakePayments } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { PatternFormat } from "react-number-format";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
  handlePayment?: any;
  amountDue: any;
}

const MakePayments = ({
  isShow,
  handleClose,
  handleShow,
  handlePayment,
  amountDue,
}: userProps) => {
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Wallet,
    (state: any) => state.Login,
    (wallet: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      wallet: wallet.wallet,
    })
  );
  const { tokens, wallet } = useSelector(selectAccountAndLogin);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      title: `Collected waste on ${new Date()}`,
      findings: "",
      comments: "",
    },
    validationSchema: Yup.object({
      // title: Yup.string().required("Please Enter Inspection title"),
    }),

    onSubmit: (values: any) => {
      if (+amountDue > (+wallet.amount || 0)) {
        toast.error('Insufficient wallet balance', { autoClose: 2000 });
      }
      // dispatch(onMakePayments(NewWasteCollection));
      handlePayment(() => formik.resetForm());
    },
  });

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Waste Collections Form</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              {/* <div className="mb-3">
                <Form.Label htmlFor="users">Card Holder's Name</Form.Label>
                <input
                  className="form-control bg-light border-0"
                  type="text"
                  id="cardholderName"
                  placeholder="Card Holder Name"
                />
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="users">Number</Form.Label>
                <PatternFormat
                  className="form-control bg-light border-0"
                  id="cardNumber"
                  name="cardNumber"
                  placeholder="xxxx xxxx xxxx xxxx"
                  format="####-####-#####-####"
                />
              </div>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="users">Expiry Date</Form.Label>
                    <PatternFormat
                      className="form-control bg-light border-0"
                      id="expDate"
                      name="expDate"
                      placeholder="xx/xx"
                      format="##/##"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="users">Cvv</Form.Label>
                    <PatternFormat
                      className="form-control bg-light border-0"
                      id="cvv"
                      name="cvv"
                      placeholder="xxx"
                      format="###"
                    />
                  </div>
                </Col>
              </Row> */}

              <div className="mb-3">
                <Form.Label htmlFor="users">Wallet Balance</Form.Label>
                <input
                  className="form-control  bg-light border-0"
                  type="text"
                  id="amountTotalPay"
                  placeholder="₦0.00"
                  value={`₦${(+wallet?.amount || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="users">Amount Due</Form.Label>
                <input
                  className="form-control  bg-light border-0"
                  type="text"
                  id="amountTotalPay"
                  placeholder="₦0.00"
                  value={`₦${(amountDue || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                  readOnly
                />
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-success">
                  Pay Now
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MakePayments;
