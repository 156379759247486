import React, { useState } from "react";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import ViewCertificateTable from "./components/ViewCertTable";


const ViewCertificates = () => {
    document.title = "User |  Electronic Registration, Management and Certification System";

    const [isShow, setIsShow] = useState(false);

    const hideUserModal = () => {
      setIsShow(!isShow);
    };
  
    return(
        <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb pageTitle="Certificate Lists" title="Certificate Lists" />
            <ViewCertificateTable isShow={isShow} hideUserModal={hideUserModal}/>
          </Container>
        </div>
      </React.Fragment>

    )
}


export default ViewCertificates