import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import ViewApplicationDetails from "../../../Common/CrudModal/ViewApplication";
import { getLicenseApplications as onGetLicenseApplications } from "../../../slices/license/thunk";

interface userProps {
  isShow: any;
  hideUserModal: any;
}

// Updated sample data to match new structure

const PendingLicensesTable = ({ isShow, hideUserModal }: userProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [applicationList, setApplicationList] = useState<any[]>([]);

  const state = useSelector((state: any) => state); // Logs the entire state

  console.log("Redux State:", state);

  const selectApplicationList = createSelector(
    (state: any) => state.License, // Access the Certification slice
    (license: any) => license.liscenseApplicationList // Directly return applicationList from the state
  );

  const applicationListFromRedux = useSelector(selectApplicationList);
  useEffect(() => {
    const params = {
      pageNo: 0,
      pageSize: 30,
      status: "PENDING",
      type: "LICENSE", // Empty string or null
    };
    dispatch(onGetLicenseApplications(params));
  }, [dispatch]);

  const handleRowClick = (row: any) => {

    const application = row.row.original;
    // Create a plain object with only the necessary, serializable data
    const serializableApplicationDetails = {
      id: application.id,
      applicationTitle: application.applicationTitle,
      description: application.description,
      status: application.status,
      target_name: application.target_name,
      companyName: application.companyName,
      type:application.type,
  
    };
  
    navigate(`/pending-lcs-applications/${application.id}`, {
      state: { applicationDetails: serializableApplicationDetails }
    });
  };

  useEffect(() => {
    if (
      applicationListFromRedux &&
      applicationListFromRedux.data &&
      applicationListFromRedux.data.applications
    ) {
      const mappedApplications =
        applicationListFromRedux?.data?.applications?.map((app: any) => ({
          id: app.id,
          applicationTitle: app.title,
          description: app.description,
          companyName: app.companyName,
          target_id: app.target_id,
          status: app.status.toLowerCase(),
          type:app.type,
          target_name: app.target_name,
        })) || [];

      setApplicationList(mappedApplications);
    }
  }, [applicationListFromRedux]);

  const [showApplicationDetailsModal, setShowApplicationDetailsModal] =
    useState(false);
  const [selectedApplication, setSelectedApplication] = useState<any>(null);

  // const handleViewCertDetails = (application: any) => {
  //   setSelectedApplication(application);
  //   setShowApplicationDetailsModal(true);
  // };

  interface columnsType {
    Header: any;
    accessor: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

    const columns: columnsType[] = useMemo(
        () => [
            {
                Header: "License Type",
                accessor: "applicationTitle",
                Filter: false,
                isSortable: true,
            },
            {
                Header: "Description",
                accessor: "description",
                Filter: false,
                isSortable: true,
            },
            {
                Header: "Facility Name",
                accessor: "target_name",
                Filter: false,
                isSortable: true,
            },
            {
                Header: "Company Name",
                accessor: "companyName",
                Filter: false,
                isSortable: true,
            },
            {
                Header: "ACTION",
                accessor: "action",
                Filter: false,
                isSortable: false,
                Cell: (cell: any) => (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            aria-label="View"
                            data-bs-original-title="View"
                        >
                            <Link
                                to="#"
                                className="btn btn-soft-info btn-sm d-inline-block"
                                // onClick={() => handleViewCertDetails(cell.row.original)}
                            >
                                <i className="las la-eye fs-17 align-middle"></i>
                            </Link>
                        </li>
                    </ul>
                ),
            },
        ],
        []
    );

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                id="searchLicenseList"
                placeholder="Search for Licenses"
                // onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                variant="info"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>All</Dropdown.Item>
                <Dropdown.Item>Pending</Dropdown.Item>
                <Dropdown.Item>Approved</Dropdown.Item>
                <Dropdown.Item>Revoked</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {applicationList && applicationList.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={applicationList || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  PaginationClass="align-items-center mt-4 gy-3"
                  onRowClick={handleRowClick}
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ViewApplicationDetails
        isShow={showApplicationDetailsModal}
        handleClose={() => setShowApplicationDetailsModal(false)}
        applicationDetails={selectedApplication}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default PendingLicensesTable;
